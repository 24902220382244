import React, {useState, useEffect} from 'react';
import { useCreateSubscriberMutation } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateSub = ({setShowAmount}) => {
    const [inputFields, setInputFields] = useState({title: '',referral_code: '', surname: '', firstname: '', othernames: '', phone: '', residential_address: '', email: '', type: 'subscribers'})

    const [ createSubscriber, {isLoading, isSuccess, error: subError, data: medData } ] = useCreateSubscriberMutation();

    const handleChange = event => {
        setInputFields({
        ...inputFields,
        [event.target.name]: event.target.value
        });
    };

    const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`referral_code`, inputFields.referral_code);
        formData.append(`surname`, inputFields.surname);
        formData.append(`firstname`, inputFields.firstname);
        formData.append(`othernames`, inputFields.othernames);
        formData.append(`phone`, inputFields.phone);
        formData.append(`residential_address`, inputFields.residential_address);
        formData.append(`email`, inputFields.email);
        formData.append(`type`, inputFields.type);
      
        try {
            await createSubscriber({title: inputFields.title, referral_code: inputFields.referral_code, referrer: inputFields.referrer, surname: inputFields.surname, firstname: inputFields.firstname, othernames: inputFields.othernames, phone: inputFields.phone, residential_address: inputFields.residential_address, email: inputFields.email, type: 'subscribers'}).unwrap();
            setInputFields({title: '',referral_code: '', referrer: 'rrr-ttt', surname: '', firstname: '', othernames: '', phone: '', residential_address: '', email: '', type: 'subscribers'});
        } catch (error) {
          toast.error(error.error);
          console.log("the error", error)
        }
      };

      console.log("sub error", subError)

      useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setShowAmount(false)   
        }
      }, [isSuccess])

      useEffect(() => {
        if(subError) {
            toast.error(subError?.data.messages)    
        }
      }, [subError])

  return (
    <>
        <div className="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl">
                <div
                className="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowAmount(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            
                
                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Subscribers</p>
                </div>
                <form>
                    <div className='grid grid-cols-2 gap-x-3'>
                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Title</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                            name="title" value={inputFields.title} onChange={handleChange}>
                                <option selected>Choose Title</option>
                                <option value="Sir">Sir</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Chief">Chief</option>
                                <option value="Madam">Madam</option>
                                <option value="Engr">Engr</option>
                                <option value="Barr">Barr</option>
                                <option value="Alhaji">Alhaji</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Referral Code</label>
                            <input type="name" id="email" placeholder="Referral Code" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="referral_code" value={inputFields.referral_code} onChange={handleChange} />
                        </div>

                        {/* <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Referrer</label>
                            <input type="name" id="email" placeholder="Referrer" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="referrer" value={inputFields.referrer} onChange={handleChange} />
                        </div> */}

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">First Name</label>
                            <input type="name" id="email" placeholder="First Name" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="firstname" value={inputFields.firstname} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Last Name</label>
                            <input type="name" id="email" placeholder="Last Name" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="surname" value={inputFields.surname} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Other Names</label>
                            <input type="name" id="email" placeholder="Other Name" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="othernames" value={inputFields.othernames} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                            <input type="email" id="email" placeholder="Email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="email" value={inputFields.email} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Residential Address</label>
                            <input type="text" id="email" placeholder="20 Way Road" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="residential_address" value={inputFields.residential_address} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                            <input type="number" id="email" placeholder="080 00000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="phone" value={inputFields.phone} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </>
  )
}

export default CreateSub
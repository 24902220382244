import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCreateContractorMutation } from '../../../reactWrapper/Redux/apiSlice';

const ContractPage = () => {

    const [inputField, setInputField] = useState(
        {company_name: '', principal_name: '',  address: '',  phone: '', service_line: '', email: '', type: 'contractors' }
    )

    const [ createContractor, {isLoading, isSuccess, error, data: medData } ] = useCreateContractorMutation();

    const handleChange = event => {
        setInputField({
            ...inputField,
            [event.target.name]: event.target.value
          });
      };

      const submit = async (e) => {
        e.preventDefault();
        try {
            let dataToSubmit = {...inputField};
            
            await createContractor(dataToSubmit).unwrap()
            setInputField({company_name: '', principal_name: '',  address: '',  phone: '', service_line: '', email: '', type: 'contractors' })
        } catch (error) {
            toast.error(error.error)
        }       
    }

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
           
        }
    }, [isSuccess])

  return (
    <>
    <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-2/3 mx-auto">
          
        
        <div className="flex flex-col px-3 py-3">
        
        <form  onSubmit={submit}>
            <div className='grid grid-cols-2 gap-x-3'>
                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                    <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name of Company</label>
                    <input type="name" name="company_name" id="email" placeholder="company" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                    value={inputField.company_name} onChange={handleChange} />
                </div>

                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                    <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name of Principal</label>
                    <input type="name" name="principal_name" id="email" placeholder="Principal" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                    value={inputField.principal_name} onChange={handleChange} />
                </div>

                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                    <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Contractor Address</label>
                    <input type="text" name="address" id="email" placeholder="Contractor" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                    value={inputField.address} onChange={handleChange} />
                </div>

                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                    <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                    <input type="email" name="email" id="email" placeholder="email@mail.com" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                    value={inputField.email} onChange={handleChange} />
                </div>

                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                    <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                    <input type="number" name="phone" id="email" placeholder="08023453564" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                    value={inputField.phone} onChange={handleChange} />
                </div>

                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                    <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Contractor ServiceLine</label>
                    <input type="text" name="service_line" id="email" placeholder="Service Line" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                    value={inputField.service_line} onChange={handleChange} />
                </div>
            </div>

            <div className="mt-6 w-full flex justify-end">
                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50"  disabled={isLoading}  onClick={submit}>
                    {isLoading ? 'Creating...' : 'Create'}
                </button>
            </div>
        </form>

        </div>
    </div>

    <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </>
  )
}

export default ContractPage
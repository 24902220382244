import React from 'react'

const EditMat = ({setEdit}) => {
  return (
    <div className="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
        <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/3 mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div
            className="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
            >                 
                <svg
                    onClick={() => setEdit(false)}
                    className="w-6 h-6 cursor-pointer"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </div>            
            
            <div className="flex flex-col px-6 py-5 bg-gray-50">
            <div className='text-center text-2xl font-medium mb-4'>
                <p>Edit Department</p>
            </div>
            <form>
                <div className='grid grid-cols-1 gap-x-3'>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                        <input type="name" name="text" id="email" placeholder="Role" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>
                </div>

                <div className="mt-6">
                    <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                        Edit
                    </button>
                </div>
            </form>

            </div>
        </div>
    </div>
  )
}

export default EditMat
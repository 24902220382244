import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux'


const HeaderBar = ({setShowModal, setShowRequest}) => {

  const [show, setShow]=useState(false)
  
    const {users} = useSelector((state) => state.user)
    const userProfile = users
    console.log("the users profile here", users)

  // let menuRef = useRef();

  // useEffect(() => {
  //   let handler = (e) => {
  //     if(menuRef.current.contains(e.target)){
  //       setShow(false);
  //       console.log(menuRef.current);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler)

  //   return() => {
  //     document.removeEventListener("mousedown", handler)
  //   }
  // }, [])

  const showDropdown = () =>{
    setShow(!show)
  }

  const modalShow = () =>{
    console.log("you have been clicked")
    setShowModal(true)
    setShow(false)
  }

  const logOut = () => {
    const itUrl = "https://gmhluxury.app/";
    localStorage.clear(); // clear local storage
    window.location.href = itUrl; // redirect to new URL
  };

  return (
    <>     
      <nav className="bg-[#FFFFFF] border-gray-200 px-2 sm:px-10 py-2.5 dark:[#FFFFFF] lg:ml-[13rem]">
        <div className="container flex flex-wrap justify-end items-center">

          <div className='flex gap-x-2'>

            <div className='my-auto'>
                <button className='bg-[#E2B682] px-2 py-1 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50'  onClick={() => ( setShowRequest(true))}>
                    Internal Request
                </button>
            </div>

            <div className='grid grid-flow-col grid-rows-2 gap-x-4'>
              <div className="row-span-2 self-center rounded-full">
                <img src={userProfile?.user[0].employee.id_card_photo ? userProfile?.user[0].employee.id_card_photo : "images/defaultImage.png"} alt="user image" className="w-[40px] h-[40px] rounded-full border-2 border-solid border-white" />
              </div>


              <div className="row-span-2 font-semibold">
                <div className='ml-4'>
                  <p>{userProfile?.user[0].employee.last_name}</p>
                </div>

                <div className="relative">
                  <div className="flex gap-x-2 text-sm">
                    <p>{userProfile?.user[0].employee.employee_id}</p> 
                    <span onClick={showDropdown} className="cursor-pointer">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </span>
                  </div>
                  
                  <div className={`absolute right-0 z-20 w-36 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl dark:bg-white  ${show ? "block" : "hidden"}`}>                
                  
                    <p onClick={modalShow} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer">
                        Change password
                    </p>

                    <hr className="border-gray-200 dark:border-gray-200 " /> 

                    <p className="block cursor-pointer px-4 py-3 text-sm text-red-500 capitalize transition-colors duration-200 transform dark:text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-red-600" onClick={logOut}>
                        logout
                    </p>
    
                  </div>

                </div>
              </div>
            </div>
          </div>

          
        
        </div>
      </nav>
    </>
  )
}

export default HeaderBar
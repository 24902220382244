import React from 'react'
import CreateSide from '../components/create/CreateSide'

const CreatePage = () => {
  return (
    <div className='lg:ml-[12rem] pb-10'>
        <CreateSide />
    </div>
  )
}

export default CreatePage
import React from 'react'
import GallarySection from '../components/gallery/GallarySection'
import RecentSection from '../components/gallery/RecentSection'

const GalleryPage = () => {
  return (
    <div className='lg:ml-[12.5rem] pb-10 overflow-x-hidden'>
        <div className='mt-6 rounded-xl mx-2 bg-[#FFFFFF] flex flex-col gap-y-12'>
            <RecentSection />
            <GallarySection />
        </div>
        
    </div>
  )
}

export default GalleryPage
import React, {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faNairaSign, faChartBar, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { useGetStatisticsQuery } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OverSide = () => {

    const { data: employee, isLoading: empLoading, isSuccess: empSuccess, error: allEmpErr } = useGetStatisticsQuery()
    console.log("employee data", employee)

    useEffect(() => {
        if(allEmpErr) {
            toast.error(allEmpErr.error)
        }
    }, [allEmpErr])

    useEffect(() => {
        if(empSuccess) {
            toast.success(employee.message)
        }
    }, [empSuccess, employee])

    const data = {
        labels: ['Employees', 'Contractors', 'Subscribers', 'Suppliers'],
        datasets: [
          {
            label: 'Data',
            data: [employee?.data?.allSuppliers, employee?.data?.allEmployees, employee?.data?.allContractors, employee?.data?.allSubscribers],
            backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(153, 102, 255, 0.6)'],
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      };
  return (
    <>
        <div className='mt-6 rounded-xl mx-4 bg-[#FFFFFF]'>
            <div className='flex flex-col gap-y-12 p-2 md:p-6'>
                <div className="flex flex-col gap-y-6">
                    <div>
                        <p className="text-lg font-bold">Overview</p>
                    </div> 
                </div>

                <div>
                    <div className="flex flex-col gap-y-6">                                       
                        <div className='flex flex-wrap justify-around'>
                            <div className='shadow-lg shadow-black p-4 rounded-lg'>
                                <div className='flex justify-between'>
                                    <i className='text-blue-600'><FontAwesomeIcon icon={faChartPie} /></i>

                                    <p className="py-[0.15rem] px-2 rounded-2xl bg-green-600 text-white">30%</p>
                                </div>
                                <div className='font-semibold text-xl'>{employee?.data?.allSuppliers}</div>
                                <div>No of Suppliers</div>
                            </div>

                            <div className='shadow-lg shadow-black p-4 rounded-lg'>
                                <div className='flex justify-between'>
                                    <i className='text-yellow-400'><FontAwesomeIcon icon={faNairaSign} /></i>

                                    <p className="py-[0.15rem] px-2 rounded-2xl bg-red-600 text-white">30%</p>
                                </div>
                                <div className='font-semibold text-xl'>{employee?.data?.allEmployees}</div>
                                <div>No of Employees</div>
                            </div>

                            <div className='shadow-lg shadow-black p-4 rounded-lg'>
                                <div className='flex justify-between'>
                                    <i className='text-orange-600'><FontAwesomeIcon icon={faMap} /></i>

                                    <p className="py-[0.15rem] px-2 rounded-2xl bg-amber-500 text-white">30%</p>
                                </div>
                                <div className='font-semibold text-xl'>{employee?.data?.allContractors}</div>
                                <div>No of Contractors</div>
                            </div>

                            <div className='shadow-lg shadow-black p-4 rounded-lg'>
                                <div className='flex justify-between'>
                                    <i className='text-green-600'><FontAwesomeIcon icon={faChartBar} /></i>

                                    <p className="py-[0.15rem] px-2 rounded-2xl bg-blue-600 text-white">30%</p>
                                </div>
                                <div className='font-semibold text-xl'>{employee?.data?.allSubscribers}</div>
                                <div>No of Subscribers</div>
                            </div>
                        </div>

                        <Bar data={data} />
                    </div>
                </div>

            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default OverSide
import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useGetDepartmentsQuery } from '../../reactWrapper/Redux/apiSlice';
import { useGetRolesQuery } from '../../reactWrapper/Redux/apiSlice';
import { v4 as uuid } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCreateEmployeeMutation } from '../../reactWrapper/Redux/apiSlice';
import CreateDept from './CreateDept';
import CreateRole from './CreateRole';


const CreateEmployee = ({setShowAmount}) => {
    const [showDept, setShowDept] = useState(false)
    const [showRole, setShowRole] = useState(false)

    const[firstName, setFirstName]=useState("")
    const[lastName, setLastName]=useState("")
    const[email, setEmail]=useState("")
    const[dept, setDept] = useState("")
    const type = "employees"
    const[access, setAccess] = useState(null)
    const[role, setRole] =useState("")

    const { data: departments } = useGetDepartmentsQuery()
    const { data: roles } = useGetRolesQuery()
    const [ createEmployee, {isLoading: empLoad, isSuccess: empAllSuccess, error: empError, data: empData } ] = useCreateEmployeeMutation()

    const unique_id = uuid();
    const uid = unique_id.slice(0,4);
    const userId = uid

    const submitEmployee = async (e) => {
        e.preventDefault();
        try {
            await createEmployee({email: email, type: type, first_name: firstName, last_name: lastName, department: dept, access_level: access,role: role, user: userId }).unwrap()
            setEmail('')
            setFirstName('')
            setLastName('')
            setDept('')
            setAccess('')
            setRole('')
        } catch (error) {
            toast.error(error?.data?.message)
            console.error("error", error?.data?.message)
        }
    }

    useEffect(() => {
        if(empAllSuccess) {
            toast.success(empData?.message)
            setShowAmount(false)
        }
    }, [empAllSuccess, empData])

    useEffect(() => {
        if(empError) {
            toast.error(empError?.data?.message)
            console.log("This is the error", empError?.data?.message)
        }
    }, [empError])

  return (
    <>
            <div className="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                className="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowAmount(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            
                
                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Employee</p>
                </div>
                <form onSubmit={submitEmployee}>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">First Name</label>
                        <input type="name" name="text" id="email" placeholder="Employee FirstName" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}/>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Last Name</label>
                        <input type="name" name="text" id="email" placeholder="Employee LastName" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                        value={lastName}
                        onChange={(e)=> setLastName(e.target.value)}/>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                        <input type="email" name="text" id="email" placeholder="Employee Email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                        value={email}
                        onChange={(e)=> setEmail(e.target.value)}/>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="countries" className="block px-4 pt-1 text-sm text-gray-600 ">Department</label>
                        <div className='flex gap-x-2'>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                            value={dept}
                            onChange={(e) => setDept(e.target.value)}>
                            <option selected>Choose a Department</option>
                            {departments && departments.map((item) => (
                                <option key={item.id}>{item.name}</option>
                                )                                      
                            )}
                            </select>

                            <i 
                            onClick={() => setShowDept(true)}
                            id="my-element"
                            className='text-lime-600 cursor-pointer text-lg'><FontAwesomeIcon icon={faCirclePlus} />
                            </i>
                        </div>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="countries" className="block px-4 pt-1 text-sm text-gray-600 ">Roles</label>
                        <div className='flex gap-x-2'>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold grow"
                            value={role}
                            onChange={(e)=> setRole(e.target.value)}>
                            <option selected>Choose a Role</option>
                            {roles && roles.map(item => (
                                <option key={item.id}>{item.name}</option>
                                )                                      
                            )}
                            </select>

                            <i 
                            onClick={() => setShowRole(true)}
                            id="my-element"
                            className='text-green-600 cursor-pointer text-lg'><FontAwesomeIcon icon={faCirclePlus} />
                            </i>
                        </div>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Access Level</label>
                        
                        <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold grow"
                            value={access}
                            onChange={(e) => setAccess(e.target.value)}>
                            <option selected>Choose a Level</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </select>
                    </div>

                    <div className="mt-6">
                        {empLoad ? 
                            <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled>
                                Creating
                            </button>
                        :
                            <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                Create
                            </button>
                        }
                        
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />

        {showDept ? <CreateDept setShowDept={setShowDept} /> : null}
        {showRole ? <CreateRole setShowRole={setShowRole} /> : null}
    </> 
  )
}

export default CreateEmployee
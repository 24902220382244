import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { useAddEmployeeMedicalsMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddEmployeeSanctionsMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddEmployeeCareersMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddEmployeeSalaryAdvanceMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddEmployeeEmploymentHistoryMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddEmployeeKinsMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddEmployeeAcademicsMutation } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateDetails = ({setIndividualDetails, id}) => {
    const [accordion, setAccordion] = useState("medical")

    const [inputFields, setInputFields] = useState([
        {date_of_last_medical_checkup: '', hospital_conducted: '', genotype: '', bloodgroup: '', reason_for_checkup: '', document: null, document_name: ''}
    ])

    const [careerFields, setCareerFields] = useState([
        {venue: '', training_name: '', date: '', duration: '', facilitator: '', documentc: null, document_namec: ''}
    ])

    const [salaryFields, setSalaryFields] = useState([
        {salary_advance: '', salary_balance: ''}
    ])

    const [inputDesc, setInputDesc] = useState([
        {descriptions: ''}
    ])

    const [empHistory, setEmpHistory] = useState([
        {name_of_company: '', role: '',  start_date: '',  end_date: ''}
    ])

    const [inputKin, setInputKin] = useState([
        {next_of_kin_fullname: '', relationship: '',  kin_address: '',  phone: '', email: '', occupation: '',  office_address: ''}
    ])

    const [inputAcademics, setInputAcademics] = useState([
        {school_name: '', start_date: '',  end_date: '',  qualification_title: '', document_namea: '', documenta: null}
    ])

    const [ addEmployeeMedicals, {isLoading, isSuccess, error, data } ] = useAddEmployeeMedicalsMutation();
    const [ addEmployeeSanctions, {isLoading: sancLoad, isSuccess: sancSuccess, error: sancError, data: sancData } ] = useAddEmployeeSanctionsMutation();
    const [ addEmployeeCareers, {isLoading: carLoad, isSuccess: carSuccess, error: carError, data: carData } ] = useAddEmployeeCareersMutation();
    const [ addEmployeeSalaryAdvance, {isLoading: salLoad, isSuccess: salSuccess, error: salError, data: salData } ] = useAddEmployeeSalaryAdvanceMutation();
    const [ addEmployeeEmploymentHistory, {isLoading: employLoad, isSuccess: employSuccess, error: employError, data: employData } ] = useAddEmployeeEmploymentHistoryMutation();
    const [ addEmployeeKins, {isLoading: kinLoad, isSuccess: kinSuccess, error: kinError, data: kinData } ] = useAddEmployeeKinsMutation();
    const [ addEmployeeAcademics, {isLoading: acaLoad, isSuccess: acaSuccess, error: acaError, data: acaData } ] = useAddEmployeeAcademicsMutation();

    const handleFormChange = (index, event) => {
        let data = [...inputFields]
        if (event.target.name === 'document') {
            data[index]['document'] = event.target.files[0];
            data[index]['document_name'] = event.target.files[0].name;
        } else if (event.target.name === 'date_of_last_medical_checkup') {
            data[index]['date_of_last_medical_checkup'] = event.target.value;
        } else {
            data[index][event.target.name] = event.target.value;
        }
        setInputFields(data);
    }

    const addFields = () => {
        let newfield = {date_of_last_medical_checkup: '', hospital_conducted: '', genotype: '', bloodgroup: '', reason_for_checkup: '', document: null, document_name: ''}
        setInputFields([...inputFields, newfield])
    }

    const handleReset = (index) => {
        let data = [...inputFields];
        data[index]['document_name'] = '';
        data[index]['document'] = null;
        setInputFields(data);
    };

    const acaReset = (index) => {
        let data = [...inputAcademics];
        data[index]['document_namea'] = '';
        data[index]['documenta'] = null;
        setInputAcademics(data);
    };

    const carReset = (index) => {
        let data = [...careerFields];
        data[index]['document_namec'] = '';
        data[index]['documentc'] = null;
        setCareerFields(data);
    };

    const unique_id = uuid();
    const uid = unique_id.slice(0,4);
    const userId = uid;

    const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        inputFields.forEach((inputField) => {
            formData.append(`date_of_last_medical_checkup`, moment(inputField.date_of_last_medical_checkup).format("YYYY-MM-DD HH:mm:ss"));
            formData.append(`hospital_conducted`, inputField.hospital_conducted);
            formData.append(`genotype`, inputField.genotype);
            formData.append(`bloodgroup`, inputField.bloodgroup);
            formData.append(`reason_for_checkup`, inputField.reason_for_checkup);
            formData.append(`file`, inputField.document);
            formData.append(`document_name`, inputField.document_name);
          });
      
        try {
            await addEmployeeMedicals({ id, dataToSubmit: formData }).unwrap();
          setInputFields([
            {
              date_of_last_medical_checkup: "",
              hospital_conducted: "",
              genotype: "",
              bloodgroup: "",
              reason_for_checkup: "",
              document: null,
              document_name: ""
            }
          ]);
        } catch (error) {
          toast.error(error.error);
        }
      };

    useEffect(() => {
        if(isSuccess) {
            toast.success(data.message)     
        }
    }, [isSuccess])

    const handleDescChange = (index, event) => {
        let data = [...inputDesc]
        data[index][event.target.name] = event.target.value;
        setInputDesc(data);
    }

    const addDesc = () => {
        let newfield = {descriptions: ''}
        setInputDesc([...inputFields, newfield])
    }

    const submitDesc = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = Object.assign({}, ...inputDesc);
            await addEmployeeSanctions({id, data: dataToSubmit}).unwrap()
            setInputDesc([{descriptions: ''}])
        } catch (error) {
            toast.error(error.error)
        }       
    }

    useEffect(() => {
        if(sancSuccess) {
            toast.success(sancData.message)     
        }
    }, [sancSuccess])

    const careerFormChange = (index, event) => {
        let data = [...careerFields]
        if (event.target.name === 'documentc') {
            data[index]['documentc'] = event.target.files[0];
            data[index]['document_namec'] = event.target.files[0].name;
        } else if (event.target.name === 'date_of_last_medical_checkup') {
            data[index]['date_of_last_medical_checkup'] = event.target.value;
        } else {
            data[index][event.target.name] = event.target.value;
        }
        setCareerFields(data);
    }

    const submitCareer = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        careerFields.forEach((inputField) => {
            formData.append(`venue`, inputField.venue);
            formData.append(`training_name`, inputField.training_name);
            formData.append(`duration`, inputField.duration);
            formData.append(`facilitator`, inputField.facilitator);
            formData.append(`date`, moment(inputField.date).format("YYYY-MM-DD HH:mm:ss"));
            formData.append(`file`, inputField.documentc);
            formData.append(`document_namec`, inputField.document_namec);
          });
      
        try {
            await addEmployeeCareers({ id, dataToSubmit: formData }).unwrap();
            setCareerFields([{venue: '', training_name: '', date: '', duration: '', facilitator: '', documentc: null, document_namec: ''}])
        } catch (error) {
          toast.error(error.error);
        }
      };

    useEffect(() => {
        if(carSuccess) {
            toast.success(carData.message)     
        }
    }, [carSuccess])

    const salaryFormChange = (index, event) => {
        let data = [...salaryFields]
        data[index][event.target.name] = event.target.value;
        setSalaryFields(data);
    }

    const submitSalary = async (e) => {
        e.preventDefault();
        try {
            let dataToSubmit = [...salaryFields];
            dataToSubmit = Object.assign({}, ...dataToSubmit);
            await addEmployeeSalaryAdvance({id, dataToSubmit}).unwrap()
            setSalaryFields([{salary_advance: '', salary_balance: ''}])
        } catch (error) {
            toast.error(error.error)
        }       
    }

    useEffect(() => {
        if(salSuccess) {
            toast.success(salData.message)     
        }
    }, [salSuccess])

    const historyFormChange = (index, event) => {
        let data = [...empHistory]
        data[index][event.target.name] = event.target.value;
        setEmpHistory(data);
    }

    const submitHistory = async (e) => {
        e.preventDefault();
        try {
            let dataToSubmit = [...empHistory];
            dataToSubmit = dataToSubmit.map(data => {
            return { ...data, 
                start_date: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"), 
                end_date: data.end_date ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss") : null 
            }
            });
            dataToSubmit = Object.assign({}, ...dataToSubmit);
            await addEmployeeEmploymentHistory({id, dataToSubmit}).unwrap()
            setEmpHistory([{name_of_company: '', role: '',  start_date: '',  end_date: ''}])
        } catch (error) {
            toast.error(error.error)
        }        
    }

    useEffect(() => {
        if(employSuccess) {
            toast.success(employData.message)     
        }
    }, [employSuccess])

    const kinFormChange = (index, event) => {
        let data = [...inputKin]
        data[index][event.target.name] = event.target.value;
        setInputKin(data);
    }

    const submitKin = async (e) => {
        e.preventDefault();
        try {
            let dataToSubmit = [...inputKin];
            dataToSubmit = Object.assign({}, ...dataToSubmit);
            await addEmployeeKins({id, dataToSubmit}).unwrap()
            setInputKin([{next_of_kin_fullname: '', relationship: '',  kin_address: '',  phone: '', email: '', occupation: '',  office_address: ''}])
        } catch (error) {
            toast.error(error.error)
        }  
    }

    useEffect(() => {
        if(kinSuccess) {
            toast.success(kinData.message)     
        }
    }, [kinSuccess])

    const academicFormChange = (index, event) => {
        let data = [...inputAcademics]
        if (event.target.name === 'documenta') {
            data[index]['documenta'] = event.target.files[0];
            data[index]['document_namea'] = event.target.files[0].name;
        } else {
            data[index][event.target.name] = event.target.value;
        }
        setInputAcademics(data);
    }


    const submitAcademic = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        inputAcademics.forEach((inputField) => {
            formData.append(`school_name`, inputField.school_name);
            formData.append(`qualification_title`, inputField.qualification_title);
            formData.append(`end_date`, moment(inputField.end_date).format("YYYY-MM-DD HH:mm:ss"));
            formData.append(`start_date`, moment(inputField.start_date).format("YYYY-MM-DD HH:mm:ss"));
            formData.append(`file`, inputField.documenta);
            formData.append(`document_name`, inputField.document_namea);
          });
      
        try {
            await addEmployeeAcademics({ id, dataToSubmit: formData }).unwrap();
            setInputAcademics([{school_name: '', start_date: '',  end_date: '',  qualification_title: '', document_namea: '', documenta: null}])
        } catch (error) {
          toast.error(error.error);
        }
      };

    useEffect(() => {
        if(acaSuccess) {
            toast.success(acaData.message)     
        }
    }, [acaSuccess])

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-10/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Employees' Details</p>
                    </div> 

                    <svg
                        onClick={() => setIndividualDetails(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                
                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "medical" ? "" : "shadow-lg shadow-black p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Employee Medical</p>
                            {accordion === "medical" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("medical")}}
                            ><FontAwesomeIcon icon={faArrowDown}  className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "medical" ? "block" : "hidden"}`}>
                            {inputFields?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                                <div className="grid grid-cols-2 gap-x-4">
                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Last Medical Checkup</label>
                                        <input type="date" name="date_of_last_medical_checkup" id="email" placeholder="eg Plywood"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.date_of_last_medical_checkup}
                                        onChange={event => handleFormChange(index, event)}/>
                                    </div> 

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Hospital Conducted</label>
                                        <input type="name" name="hospital_conducted" id="email" placeholder="eg Clinic Hospital"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.hospital_conducted}
                                        onChange={event => handleFormChange(index, event)}/>
                                    </div>                  

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Genotype</label>
                                        <input type="name" name="genotype" id="email" placeholder="AA"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.genotype}
                                        onChange={event => handleFormChange(index, event)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Blood Group</label>
                                        <input type="text" name="bloodgroup" id="email" placeholder="0+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.bloodgroup}
                                        onChange={event => handleFormChange(index, event)}/>
                                    </div>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-4">                          
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Reason for Medical Checkup</label>
                                    <textarea id="message" name="reason_for_checkup" rows="4" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" placeholder="Write your thoughts here..."
                                    value={input.reason_for_checkup}
                                    onChange={event => handleFormChange(index, event)}></textarea>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    {input.document_name === "" ? 
                                        <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className=" text-center">
                                            
                                            <div className="flex text-sm text-gray-600">
                                                <label
                                                htmlFor="hospital file"
                                                className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                                >
                                                <span>Upload Hospital File</span>
                                                <input id="hospital file" 
                                                accept=".pdf,.doc" name="document" data-name="document_name" type="file" onChange={event => handleFormChange(index, event)}className="sr-only" />
                                                </label>
                                                
                                            </div>
                                            
                                            </div>
                                        </div>
                                    : 
                                        <div>
                                            <p>
                                                <span>Attached file</span> <span className="font-bold">{input.document_name}</span> <span className="text-red-700 ml-4"
                                                onClick={() => handleReset(index)}>X</span>
                                            </p>
                                        </div>
                                    }
                                </div> 
                            </React.Fragment>
                            )
                            })}

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" onClick={addFields}>
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                                    {isLoading ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitKin}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "kin" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Employee Next of Kin</p>
                            {accordion === "kin" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("kin")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "kin" ? "block" : "hidden"}`}>
                            {inputKin.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Kin Full Name</label>
                                    <input type="name" name="next_of_kin_fullname" id="email" placeholder="eg Awolowo Obafemi"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.next_of_kin_fullname}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>                  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Relationship</label>
                                    <input type="text" name="relationship" id="email" placeholder="Siblings"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.relationship}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Next of Kin Address</label>
                                    <input type="text" name="kin_address" id="email" placeholder="20 Awolowo Way"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.kin_address}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone Number</label>
                                    <input type="number" name="phone" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.phone}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                                    <input type="email" name="email" id="email" placeholder="eg mymail@gmail.com"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.email}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Occupation</label>
                                    <input type="text" name="occupation" id="email" placeholder="Banking"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.occupation}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Office Address</label>
                                    <input type="text" name="office_address" id="email" placeholder="49 Army Awolowo"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.office_address}
                                    onChange={event => kinFormChange(index, event)}/>
                                </div>
                            </div>
                            </React.Fragment>
                            )
                            })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={kinLoad}>
                                    {kinLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "sanction" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Employee Sanction</p>
                            {accordion === "sanction" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("sanction")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "sanction" ? "block" : "hidden"}`}>
                        {inputDesc.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-1 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Description</label>
                                    <textarea id="message" name="descriptions" rows="4" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" placeholder="Write your thoughts here..."
                                    value={input.descriptions}
                                    onChange={event => handleDescChange(index, event)}></textarea>
                                </div>                  

                            </div>
                            </React.Fragment>
                            )
                        })}

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" onClick={addDesc}>
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" onClick={submitDesc} disabled={sancLoad}>
                                    {sancLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitSalary}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "salary" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Employee Salary</p>
                            {accordion === "salary" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("salary")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "salary" ? "block" : "hidden"}`}>
                        {salaryFields.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary Advancement</label>
                                    <input type="number" name="salary_advance" id="email" placeholder="eg 20000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.salary_advance}
                                    onChange={event => salaryFormChange(index, event)}/>
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary Balance</label>
                                    <input type="number" name="salary_balance" id="email" placeholder="eg 20000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.salary_balance}
                                    onChange={event => salaryFormChange(index, event)}/>
                                </div>                   

                            </div>
                            </React.Fragment>
                        )
                        })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50"  disabled={salLoad}>
                                    {salLoad ? 'Adding...' : 'Add'}                                 
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitHistory}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "history" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Employment History</p>
                            {accordion === "history" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("history")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "history" ? "block" : "hidden"}`}>
                        {empHistory.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Company Name</label>
                                    <input type="name" name="name_of_company" id="email" placeholder="God Made Homes"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.name_of_company}
                                    onChange={event => historyFormChange(index, event)}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Role</label>
                                    <input type="text" name="role" id="email" placeholder="Sales Manager"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.role}
                                    onChange={event => historyFormChange(index, event)}/>
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Start Date</label>
                                    <input type="date" name="start_date" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.start_date}
                                    onChange={event => historyFormChange(index, event)}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">End Date</label>
                                    <input type="date" name="end_date" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.end_date}
                                    onChange={event => historyFormChange(index, event)}/>
                                </div>                   

                            </div>
                            </React.Fragment>
                        )
                        })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={employLoad}>
                                    {employLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitCareer}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "dev" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Employment Career Development</p>
                            {accordion === "dev" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("dev")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "dev" ? "block" : "hidden"}`}>
                        {careerFields.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                                <div className="grid grid-cols-2 gap-x-4">
                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Training Name</label>
                                        <input type="name" name="training_name" id="email" placeholder="God Made Homes"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.training_name}
                                        onChange={event => careerFormChange(index, event)}/>
                                    </div> 

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date</label>
                                        <input type="date" name="date" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.date}
                                        onChange={event => careerFormChange(index, event)}/>
                                    </div>  

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Duration</label>
                                        <input type="text" name="duration" id="email" placeholder="3 Years" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.duration}
                                        onChange={event => careerFormChange(index, event)}/>
                                    </div> 

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Facilator</label>
                                        <input type="text" name="facilitator" id="email" placeholder="Mr. Yemi Aristotle" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.facilitator}
                                        onChange={event => careerFormChange(index, event)}/>
                                    </div> 

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 "> Venue</label>
                                        <input type="text" name="venue" id="email" placeholder="23B Ebisine way" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.venue}
                                        onChange={event => careerFormChange(index, event)}/>
                                    </div>   

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        {input.document_namec === "" ? 
                                            <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                <div className=" text-center">
                                                
                                                <div className="flex text-sm text-gray-600">
                                                    <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                                    >
                                                    <span>Certification</span>
                                                    <input id="file-upload" name="documentc" accept=".pdf,.doc" data-name="document_namec" type="file" onChange={event => careerFormChange(index, event)}className="sr-only" />
                                                    </label>
                                                    
                                                </div>
                                                
                                                </div>
                                            </div>
                                        : 
                                            <div>
                                                <p>
                                                    <span>Attached file</span> <span className="font-bold">{input.document_namec}</span> <span className="text-red-700 ml-4"
                                                onClick={() => carReset(index)}>X</span>
                                                </p>
                                            </div>
                                        }
                                    </div>                
                                </div>
                            </React.Fragment>
                            )
                            })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={carLoad}>
                                    {carLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitAcademic}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "academic" ? "" : "shadow-lg shadow-black p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Academic</p>
                            {accordion === "academic" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("academic")}}
                            ><FontAwesomeIcon icon={faArrowDown}  className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "academic" ? "block" : "hidden"}`}>
                        {inputAcademics.map((inputs, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">School Name</label>
                                    <input type="text" name="school_name" id="email" placeholder="eg Droomwork University"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={inputs.school_name}
                                    onChange={event => academicFormChange(index, event)}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Start Date</label>
                                    <input type="date" name="start_date" id="email" placeholder=""  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={inputs.start_date}
                                    onChange={event => academicFormChange(index, event)}/>
                                </div>                  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">End Date</label>
                                    <input type="date" name="end_date" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={inputs.end_date}
                                    onChange={event => academicFormChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Qualification Title</label>
                                    <input type="text" name="qualification_title" id="email" placeholder="B.Sc"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={inputs.qualification_title}
                                    onChange={event => academicFormChange(index, event)}/>
                                </div>
                            </div>

                            <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {inputs.document_namea === "" ? 
                                    <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className=" text-center">
                                        
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                            htmlFor="file academic"
                                            className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                            >
                                            <span>Upload Certificate</span>
                                            <input id="file academic" name="documenta" accept=".pdf,.doc" data-name="document_namea" type="file" onChange={event => academicFormChange(index, event)}className="sr-only" />
                                            </label>
                                            
                                        </div>
                                        
                                        </div>
                                    </div>
                                : 
                                    <div>
                                        <p>
                                            <span>Attached file</span> <span className="font-bold">{inputs.document_namea}</span> <span className="text-red-700 ml-4"
                                                onClick={() => acaReset(index)}>X</span>
                                        </p>
                                    </div>
                                }
                            </div> 
                            </React.Fragment>
                        )
                        })}

                            <div className="mt-6 flex justify-end">
                                
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={acaLoad}>
                                    {acaLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default CreateDetails
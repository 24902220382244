import React, {useState, useEffect} from 'react'
import { Route, Routes } from "react-router-dom";
import SideBar from "./components/layouts/SideBar";
import HeaderBar from "./components/layouts/HeaderBar";
import PasswordModal from './components/layouts/PasswordModal';
import ProfilePage from "./pages/ProfilePage";
import InternalRequest from './components/layouts/InternalRequest';
import EmployeePage from './pages/EmployeePage';
import SubscriberPage from './pages/SubscriberPage';
import ProjectPage from './pages/ProjectPage';
import CreatePage from './pages/CreatePage';
import GalleryPage from './pages/GalleryPage';
import GalleryDetail from './pages/gallery/GalleryDetail';
import GalleryView from './pages/gallery/GalleryView';
import GalleryViewDetail from './pages/gallery/GalleryViewDetail';
import { useSelector } from 'react-redux'
import OverView from './pages/OverView';


function App() {
  const {token} = useSelector((state) => state.user)
  const [showModal, setShowModal] = useState(false)
  const [showRequest, setShowRequest] = useState(false)
  useEffect(() => {
    if (!token) {
      window.location.href = "https://gmhluxury.app/";
    }
  }, [token]);

  return (
    <>
      <SideBar />
      <HeaderBar setShowModal={setShowModal} setShowRequest={setShowRequest} />
      { showModal ? <PasswordModal setShowModal={setShowModal} /> : null }
      { showRequest ? <InternalRequest setShowModal={setShowRequest} /> : null }
      <Routes>
        <Route path="/" element={ <ProfilePage /> } />
        <Route path="/employee" element={ <EmployeePage /> } />
        <Route path="/subscriber" element={ <SubscriberPage /> } />
        <Route path="/project" element={ <ProjectPage /> } />
        <Route path="/create" element={ <CreatePage />} />
        <Route path="/overview" element={ <OverView />} />

        <Route path="/gallery" element={<GalleryPage /> } />
        <Route path= "/gallery/details" element={<GalleryDetail /> }/>
        <Route path= "/gallery/view" element={<GalleryView /> }/>
        <Route path= "/gallery/view/detail" element={<GalleryViewDetail /> }/>
      </Routes>
    </>
  );
}

export default App;
import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faAward, faMoneyBills, faCalendar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import EditMedicals from './EditMedicals';
import EditKins from './EditKins';
import EditSanction from './EditSanction';
import EditSalary from './EditSalary';
import Modal from './modal'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination, Navigation } from "swiper";
import { useGetEmployeeeQuery } from '../../reactWrapper/Redux/apiSlice';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import EditCarrer from './EditCarrer';
import EditAcademics from './EditAcademics';
import EditHistory from './EditHistory';
import EditEmployees from './EditEmployee';

const FullDetails = ({showModal, data}) => {

    const [showFModal, setShowFModal] = useState(false);
    const [showMedModal, setShowMedModal] = useState(false);
    const [showKin, setShowKin] = useState(false);
    const [showSanction, setShowSanction] = useState(false);
    const [showMedRecord, setShowMedRecord] = useState(false);
    const [showSalModal, setShowSalModal] = useState(false);
    const [showEmpHistory, setShowEmpHistory] = useState(false);
    const [showEmpDev, setShowEmpDev] = useState(false);
    const [showAcademic, setShowAcademic] = useState(false);
    const [editData, setEditData] = useState(null)
    const [id, setId] = useState(null)
    console.log("employee here", editData)

    // const { data: employee, isLoading: empLoading, } = useGetEmployeeeQuery(id)

    const ModalKin = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowKin(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Next of Kin Field</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Next of Kin</label>
                            <input type="text" name="text" id="email" placeholder="eg Julian Silva" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Relationship</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold">
                            <option selected>Choose a status</option>
                            <option value="US">Single</option>
                            <option value="CA">Married</option>
                            </select>
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Address</label>
                            <input type="text" name="text" id="email" placeholder="AA"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                            <input type="email" name="text" id="email" placeholder="O+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>
                    

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Phone</label>
                        <input type="number" name="text" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Occupation</label>
                        <input type="text" name="text" id="email" placeholder="Banking" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Office Address</label>
                        <input type="text" name="text" id="email" placeholder="20 Thesis Road" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalSanction = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowSanction(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee's Sanction</p>
                </div>
                <form>
                    <div className="grid grid-cols-1 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">ID</label>
                            <input type="text" name="text" id="email" placeholder="eg 0002EDU432" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Description</label>
                        <textarea id="message" rows="4" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" placeholder="Write your thoughts here..."></textarea>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalMedRecord = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowMedRecord(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Medical Record</p>
                </div>
                <form>
                    <div className="grid grid-cols-1 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                            <input type="text" name="text" id="email" placeholder="eg Taiwo Edith" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Link</label>
                            <input type="text" name="text" id="email" placeholder="eg www.google.com" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalEmpSal = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowSalModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee Salary</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Employee ID</label>
                            <input type="text" name="text" id="email" placeholder="eg 009TER213" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary Advance</label>
                            <input type="number" name="text" id="email" placeholder="eg 20,000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary Balance</label>
                            <input type="number" name="text" id="email" placeholder="eg 20,000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalEmpHistory = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowEmpHistory(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee History</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Company Name</label>
                            <input type="text" name="text" id="email" placeholder="eg Company ABC Housing" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Role</label>
                            <input type="text" name="text" id="email" placeholder="eg Head of Sales" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Start Date</label>
                            <input type="date" name="text" id="email" placeholder="eg 20,000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalEmpCarDev = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowEmpDev(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee Carrer Development</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Company Name</label>
                            <input type="text" name="text" id="email" placeholder="eg Company ABC Housing" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date</label>
                            <input type="date" name="text" id="email" placeholder="eg Head of Sales" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Duration</label>
                            <input type="text" name="text" id="email" placeholder="eg 1 month" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Facilitator</label>
                            <input type="text" name="text" id="email" placeholder="eg My Facilitator" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Venue</label>
                            <input type="text" name="text" id="email" placeholder="eg 45 Road 8 Cresent" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Certificate</label>
                            <input type="text" name="text" id="email" placeholder="eg Road Survey Analyst" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalAcademic = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowAcademic(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee Academic Section</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">School Name</label>
                            <input type="text" name="text" id="email" placeholder="eg University of ABC" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Start Date</label>
                            <input type="date" name="text" id="email" placeholder="eg Head of Sales" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">End Date</label>
                            <input type="date" name="text" id="email" placeholder="eg 1 month" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Qualification</label>
                            <input type="text" name="text" id="email" placeholder="eg My Qualification" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Title</label>
                            <input type="text" name="text" id="email" placeholder="eg My Title" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Certificate</label>
                            <input type="text" name="text" id="email" placeholder="eg My ertificate" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

  return (
    <>
    <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Employees' Details</p>
                    </div> 

                    <svg
                        onClick={() => showModal(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                
                    <div className='relative'>
                        <div className='w-full h-40'>
                            <div className="min-h-full rounded-t-xl" style={{
                                backgroundImage: "url('images/background.png')",
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                // height: '100%',
                                // filter: 'brightness(70%)',
                                backgroundRepeat: 'no-repeat'
                            }}>   
                            </div>
                        </div>                      
                        
                        <div className='absolute bottom-[-3rem] ml-6'>
                            <img src={data?.id_card_photo ? data?.id_card_photo : "images/defaultImage.png"} alt='profile' className="w-[100px] h-[100px] rounded-full border-2 border-solid border-white" />
                        </div>                      
                    </div>
                    <div className='bg-white px-4'>
                    
                        <div className='flex justify-end mt-6'>
                            <i  onClick={() => {
                                setShowFModal(true);
                                setEditData(data);
                                setId(data.id)}
                            } ><FontAwesomeIcon icon={faEdit}  className="cursor-pointer"/></i>
                        </div>

                        <div className="mt-4 flex">
                            <div className="flex-1">
                                <p>
                                    <span className='text-blue-700 mr-2'>
                                        {data?.employee_id}
                                    </span>
                                    <span className="text-xl font-bold">
                                    {data?.first_name} {data?.last_name}
                                    </span>
                                    <span className="ml-2 text-lg font-medium">
                                        - {data?.gender}
                                    </span> 
                                </p>
                                <p className='font-lg text-base text-[#80597E]'>
                                    {data?.user.email} 
                                </p>
                                <div className='mt-2'>
                                    <p className='text-[#523a51] font-normal text-base'>{data?.street}, {data?.state}</p>

                                    <p className='font-normal text-lg text-[#80597E] mt-1'>
                                        Status: <span className='text-blue-700 font-medium'>{data?.marital_status}</span>
                                    </p>
                                </div>
                            </div>

                            <div className='flex-initial mt-4'>
                                <p>
                                    <i><FontAwesomeIcon icon={faUserGroup} className="font-normal text-sm text-[#80597E]"/></i>
                                    <span className="font-normal text-base ml-1">
                                        {data?.department}
                                    </span> 
                                </p>

                                <p>
                                    <i><FontAwesomeIcon icon={faAward}  className="font-normal text-sm text-[#80597E]"/></i>
                                    <span className="font-normal text-base ml-1">
                                    {data?.role}
                                    </span>
                                    
                                </p>

                                <p>
                                    <i><FontAwesomeIcon icon={faMoneyBills}  className="font-normal text-sm text-[#80597E]"/></i>
                                    <span className="font-normal text-base ml-1">
                                        Salary: {data?.salary}
                                    </span>
                                </p>

                                <p>
                                    <i><FontAwesomeIcon icon={faCalendar}  className="font-normal text-sm text-[#80597E]"/></i> <span className="font-normal text-base ml-1">
                                        Resumption Date: {data?.resumption_date ? moment(data?.resumption_date).format("YYYY-MM-DD") : null}
                                    </span>
                                </p>

                                {/* <p>
                                    <i><FontAwesomeIcon icon={faMoneyBills}  className="font-normal text-sm text-[#80597E]"/></i>
                                    <span className="font-normal text-base ml-1">
                                        Referral Code: 
                                    </span>
                                </p> */}
                            </div>
                        </div>   

                        <div>
                       Ref Code: {data?.referral_code}
                        </div>              

                        <div className="mt-4">
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            freeMode={true}
                            pagination={{
                            clickable: true,
                            }}
                            navigation={true}
                            modules={[FreeMode, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                            <div className="border-2 border-solid border-black p-2 rounded">
                                <p><span>Date of Birth</span> <span>{data?.date_of_birth ? moment(data?.date_of_birth).format("YYYY-MM-DD") : null}</span></p>
                                <p><span>Phone</span> <span>{data?.phone}</span></p>
                            </div>
                            </SwiperSlide>
                            
                            <SwiperSlide>
                            <div className="border-2 border-solid border-black p-2 rounded">
                                <p><span>No of Children</span> <span>{data?.number_of_children}</span></p>
                                <p><span>No of Dependent</span> <span>{data?.number_of_dependent}</span></p>
                            </div>
                            </SwiperSlide>

                            <SwiperSlide>
                            <div className="border-2 border-solid border-black p-2 rounded">
                                <p><span>Nationality</span> <span>{data?.nationality}</span></p>
                                <p><span>State of Origin</span> <span>{data?.state_of_origin}</span></p>
                                
                            </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="border-2 border-solid border-black p-2 rounded">
                                    <p><span>LGA of Origin</span> <span>{data?.lga_of_origin}</span></p>
                                    <p><span>A/C Name</span> <span>{data?.account_name}</span></p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="border-2 border-solid border-black p-2 rounded">
                                    <p><span>A/C Number</span> <span>{data?.account_number}</span></p>
                                    {/* <p><span>A/C Bank</span> <span>Fedelity</span></p> */}
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>

                    {data?.employee_medicals.map(checkup => (
                        <div className='bg-white px-4 mt-4'>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Medical</h2>
                                <div className='flex my-1'>
                                    <div className="mr-2">
                                        <i><FontAwesomeIcon icon={faEdit} 
                                        onClick={() => { 
                                            setShowMedModal(true);
                                            setEditData(checkup);
                                            setId(checkup.id)
                                        }}
                                        className="cursor-pointer"/></i>
                                    </div>

                                    <div>
                                        <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                    </div>
                                </div>
                            </div>
                            
                            <>
                                <div className='grid grid-cols-5 justify-center py-2'>
                                    <div className="col-span-2">
                                        <p className="font-semibold">Date of Last Medical Checkup</p>
                                        <p>{checkup?.date_of_last_medical_checkup ? moment(checkup?.date_of_last_medical_checkup).format("YYYY-MM-DD") : "null"}</p>
                                    </div>

                                    <div className="col-span-2">
                                        <p className="font-semibold">Hospital</p>
                                        <p>{checkup?.hospital_conducted ? checkup?.hospital_conducted : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Genotype</p>
                                        <p>{checkup?.genotype ? checkup?.genotype : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Blood Group</p>
                                        <p>{checkup?.bloodgroup ? checkup?.bloodgroup : "null"}</p>
                                    </div>

                                    <div className="col-span-2">
                                        <p className="font-semibold">Document Name</p>
                                        <p>{checkup?.document_name ? checkup?.document_name : "null"}</p>
                                    </div>

                                    <div className="col-span-2">
                                        <p className="font-semibold">Link</p>
                                        <p><a href={checkup?.file ? checkup?.file : "null"} target="_blank" download>{checkup?.file ? checkup?.file.split('/').pop() : "null"}</a></p>
                                    </div>
                                </div>
                                <div className='pb-2'>
                                    <p className="font-semibold">Reason</p>
                                    <p>{checkup?.reason_for_checkup ? checkup?.reason_for_checkup : "null"}</p>
                                </div>
                            </>
                                            
                        </div>
                    ))} 

                    {data?.employee_kins && data?.employee_kins.map(kin => (
                        <div className='bg-white px-4 mt-4'>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Next of Kin</h2>
                                <div className='flex my-1'>
                                    <div className="mr-2">
                                        <i><FontAwesomeIcon icon={faEdit}
                                        onClick={() => { 
                                            setShowKin(true);
                                            setEditData(kin);
                                            setId(kin.id)
                                        }}
                                        className="cursor-pointer"/></i>
                                    </div>

                                    <div>
                                        <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                    </div>
                                </div>
                            </div>
                            
                            <>
                                <div className='grid grid-cols-3 justify-center py-2 gap-y-3'>
                                    <div className="">
                                        <p className="font-semibold">Next of Kin</p>
                                        <p>{kin?.next_of_kin_fullname ? kin?.next_of_kin_fullname : "null"}</p>
                                    </div>

                                    <div className="">
                                        <p className="font-semibold">Relationship</p>
                                        <p>{kin?.relationship ? kin?.relationship : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Address</p>
                                        <p>{kin?.kin_address ? kin?.kin_address : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Email</p>
                                        <p>{kin?.email ? kin?.email : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Phone</p>
                                        <p>{kin?.phone ? kin?.phone : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Occupation</p>
                                        <p>{kin?.occupation ? kin?.occupation : "null"}</p>
                                    </div>

                                    <div>
                                        <p className="font-semibold">Office Address</p>
                                        <p>{kin?.office_address ? kin?.office_address : "null"}</p>
                                    </div>
                                </div>
                            </>
                                                
                        </div>
                    ))}
                    
                    {data?.employee_sanctions && data?.employee_sanctions?.map(sanction => (
                        <div className='bg-white px-4 mt-4'>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Employee Sanction</h2>
                                <div className='flex my-1'>
                                    <div className="mr-2">
                                        <i><FontAwesomeIcon icon={faEdit} 
                                        onClick={() => {
                                            setShowSanction(true);
                                            setEditData(sanction);
                                            setId(sanction.id)
                                        }}
                                        className="cursor-pointer"/></i>
                                    </div>

                                    <div>
                                        <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='grid grid-cols-1 justify-center py-2 gap-y-2'>
                        
                                <div className="">
                                    <p className="font-semibold">Description</p>
                                    <p>{sanction?.descriptions ? sanction?.descriptions : "null"}</p>
                                </div>                       
                            </div>
                                                
                        </div>
                    ))}
                    
                    {data?.employee_salary_advances && data?.employee_salary_advances.map(salary => (
                        <div className='bg-white px-4 mt-4'>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Employee Salary</h2>
                                <div className='flex my-1'>
                                    <div className="mr-2">
                                        <i><FontAwesomeIcon icon={faEdit}
                                        onClick={() => {
                                            setShowSalModal(true);
                                            setEditData(salary);
                                            setId(salary.id)
                                        }}
                                        className="cursor-pointer"/></i>
                                    </div>

                                    <div>
                                        <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='grid grid-cols-2 justify-center py-2 gap-y-3'>
                            
                                <div className="">
                                    <p className="font-semibold">Salary Advance</p>
                                    <p>{salary?.salary_advance ? salary?.salary_advance : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Salary Balance</p>
                                    <p>{salary?.salary_balance ? salary?.salary_balance : "null"}</p>
                                </div>

                            </div>                           
                        </div>
                    ))}

                    {data?.employee_employment_histories && data?.employee_employment_histories.map(history => (
                        <div className='bg-white px-4 mt-4'>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Employee History</h2>
                                <div className='flex my-1'>
                                    <div className="mr-2">
                                        <i><FontAwesomeIcon icon={faEdit} 
                                        onClick={() => {
                                            setShowEmpHistory(true);
                                            setEditData(history);
                                            setId(history.id)
                                        }}
                                        className="cursor-pointer"/></i>
                                    </div>

                                    <div>
                                        <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='grid grid-cols-2 justify-center py-2 gap-y-3'>
                                <div className="">
                                    <p className="font-semibold">Company Name</p>
                                    <p>{history?.name_of_company ? history?.name_of_company : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Role</p>
                                    <p>{history?.role ? history?.role : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Start Date</p>
                                    <p>{history?.start_date ? moment(history?.start_date).format("YYYY-MM-DD") : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">End Date</p>
                                    <p>{history?.end_date ? moment(history?.end_date).format("YYYY-MM-DD") : "null"}</p>
                                </div>
                            </div>    
                        </div>
                    ))}

                    {data?.employee_careers && data?.employee_careers.map(career => (
                        <div className='bg-white px-4 mt-4'>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Employee Carrer Development</h2>
                                <div className='flex my-1'>
                                    <div className="mr-2">
                                        <i><FontAwesomeIcon icon={faEdit} 
                                        onClick={() => { 
                                            setShowEmpDev(true);
                                            setEditData(career);
                                            setId(career.id)
                                        }}
                                        className="cursor-pointer"/></i>
                                    </div>

                                    <div>
                                        <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='grid grid-cols-3 justify-center py-2 gap-y-3'>
                                <div className="">
                                    <p className="font-semibold">Company Name</p>
                                    <p>{career?.training_name ? career?.training_name : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Date</p>
                                    <p>{career?.date ? moment(career?.date).format("YYYY-MM-DD") : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Duration</p>
                                    <p>{career?.duration ? career?.duration : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Facilitator</p>
                                    <p>{career?.facilitator ? career?.facilitator : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Venue</p>
                                    <p>{career?.venue ? career?.venue : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Certificate Name</p>
                                    <p>{career?.document_name ? career?.document_name : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Certificate Link</p>
                                    <p><a href={career?.file ? career?.file : "null"} target="_blank" download>{career?.file ? career?.file.split('/').pop() : "null"}</a></p>
                                </div>

                            </div>
        
                        </div>
                    ))}

                    {data?.employee_academics && data?.employee_academics?.map(academics => (
                        <div className='bg-white px-4 mt-4' key={academics.id}>
                            <div className="flex justify-between">
                                <h2 className="text-gray-800 font-bold my-1 text-lg">Academic</h2>
                                
                            </div>
                            
                            <div className='grid grid-cols-3 justify-center py-2 gap-y-3'>
                                <div className="">
                                    <p className="font-semibold">School Name</p>
                                    <p>{academics?.school_name ? academics?.school_name : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Start Date</p>
                                    <p>{academics?.start_date ? moment(academics?.start_date).format("YYYY-MM-DD") : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">End Date</p>
                                    <p>{academics?.end_date ? moment(academics?.end_date).format("YYYY-MM-DD") : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Qualification</p>
                                    <p>{academics?.qualification_title ? academics?.qualification_title : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Certificate Name</p>
                                    <p>{academics?.document_name ? academics?.document_name : "null"}</p>
                                </div>

                                <div className="">
                                    <p className="font-semibold">Certificate Link</p>
                                    <p><a href={academics?.file ? academics?.file : "null"} target="_blank" download>{academics?.file ? academics?.file.split('/').pop() : "null"}</a></p>
                                </div>
                            </div>
                                                      
                        </div>
                    ))}
                </div>
            </div>
        </div>

    <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />

    {/* {showFModal ? <Modal setShowFModal={setShowFModal} id={id} editData={editData} /> : null} */}
    {showFModal ? <EditEmployees editData={editData} id={id} setShowFModal={setShowFModal} /> : null}
    {showMedModal ? <EditMedicals showModal={showModal} editData={editData} id={id} setShowMedModal={setShowMedModal}/> : null}
    {showKin ? <EditKins showModal={showModal} editData={editData} id={id} setShowKin={setShowKin} /> : null}
    {showSanction ? <EditSanction showModal={showModal} editData={editData} id={id} setShowSanction={setShowSanction}/> : null}
    {showSalModal ? <EditSalary showModal={showModal} editData={editData} id={id} setShowSalModal={setShowSalModal}/> : null}
    {showEmpDev ? <EditCarrer editData={editData} id={id} setShowEmpDev = {setShowEmpDev} /> : null}
    {showEmpHistory ? <EditHistory editData={editData} id={id} setShowEmpHistory = {setShowEmpHistory} /> : null}
    {showAcademic ? <EditAcademics editData={editData} id={id} setShowAcademic = {setShowAcademic} /> : null}
    </>
  )
}

export default FullDetails
import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEditEmployeeEmployementHistoryMutation } from '../../reactWrapper/Redux/apiSlice';

const EditHistory = ({editData, id, setShowEmpHistory}) => {
    const [inputFields, setInputFields] = useState({name_of_company: '', role: '',  start_date: '',  end_date: ''})

    const [ editEmployeeEmployementHistory, {isLoading, isSuccess, error, data: medData } ] = useEditEmployeeEmployementHistoryMutation();

    useEffect(() => {
        setInputFields({
          name_of_company: editData?.name_of_company,
          role: editData?.role,
          start_date: editData?.start_date,
          end_date: editData?.end_date
        });
      }, [editData]);

      const handleChange = event => {
        setInputFields({
        ...inputFields,
        [event.target.name]: event.target.value
        });
      };

      const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`start_date`, moment(inputFields.start_date).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`end_date`, moment(inputFields.end_date).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`name_of_company`, inputFields.name_of_company);
        formData.append(`role`, inputFields.role);
      
        try {
            await editEmployeeEmployementHistory({ id, dataToSubmit: formData }).unwrap();
            setInputFields({name_of_company: '', role: '',  start_date: '',  end_date: ''});
        } catch (error) {
          toast.error(error.error);
        }
      };

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setShowEmpHistory(false)   
            window.location.reload();
        }
    }, [isSuccess])
  return (
    <>
    <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowEmpHistory(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee History</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Company Name</label>
                            <input type="text" id="email" placeholder="eg Company ABC Housing" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="name_of_company" value={inputFields.name_of_company} onChange={handleChange}/>
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Role</label>
                            <input type="text"  id="email" placeholder="eg Head of Sales" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="role" value={inputFields.role} onChange={handleChange}/>
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Start Date</label>
                            <input type="date" id="email" placeholder="eg 20,000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="start_date" value={inputFields.start_date && moment(inputFields.start_date).format("YYYY-MM-DD")} onChange={handleChange}/>
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">End Date</label>
                            <input type="date" id="email" placeholder="eg 20,000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="end_date" value={inputFields.end_date && moment(inputFields.end_date).format("YYYY-MM-DD")} onChange={handleChange}/>
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Editing...' : 'Edit'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    
    <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </>
  )
}

export default EditHistory
import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


// import required modules
import { EffectCoverflow, Pagination } from "swiper";

const DetailView = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const { photo } = useSelector((store) => store.user);
    const [showUpload, setShowUpload] = useState(false)
    const [properties, setProperties] = useState(null)
    const [images, setImages] = useState([
        {
            id: 1,
            time: "Just Now",
            info: ([
                {
                    id: 1,
                    image: "/images/view/RectangleA.png"
                },
                {
                    id: 2,
                    image: "/images/view/RectangleB.png"
                },
                {
                    id: 3,
                    image: "/images/view/RectangleC.png"
                },
                {
                    id: 4,
                    image: "/images/view/RectangleD.png"
                },
            ])
        },
        {
            id: 2,
            time: "A week ago",
            info: ([
                {
                    id: 1,
                    image: "/images/view/RectangleE.png"
                },
                {
                    id: 2,
                    image: "/images/view/RectangleF.png"
                },
                {
                    id: 3,
                    image: "/images/view/RectangleG.png"
                },
                {
                    id: 4,
                    image: "/images/view/RectangleH.png"
                },
            ])
        },
        {
            id: 3,
            time: "Other Images",
            info: ([
                {
                    id: 1,
                    image: "/images/view/RectangleI.png"
                },
                {
                    id: 2,
                    image: "/images/view/RectangleJ.png"
                },
                {
                    id: 3,
                    image: "/images/view/RectangleK.png"
                },
                {
                    id: 4,
                    image: "/images/view/RectangleD.png"
                },
            ])
        }
    ])
    console.log("the images", properties);

    const ModalBox = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].time)}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-10/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >   
                    <div>
                        <p className="text-xl font-bold"> {object[0].time} </p>    
                    </div>              
                    <svg
                        onClick={() => {setShowUpload(false); setThumbsSwiper(null)
                        }}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-9 bg-gray-50">
                                
                <div>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={3}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {object.map((items) => (
                        items.info.map((item) => (
                            <SwiperSlide key={item.id}>
                                <div className='h-[25rem] w-full'  key={items.id}>
                                    <div style={{
                                        backgroundImage: ` url(${item.image})`, 
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '100%',
                                    }}></div>
                                </div>
                            </SwiperSlide>
                        ))            
                    ))}
                </Swiper>
                
                </div>

                </div>
            </div> */}
        </div>
    )

  return (
    <>
        {/* <div>
            <div className='px-6 py-2 flex flex-col'>
                {photo && photo?.project_unit_photos.map((item) => (
                    <div className='mb-10'>
                        <p className='text-lg font-bold' key={item.id}>{item.time}</p>
                        
                        <div className='flex justify-between mt-2 gap-2 flex-wrap'>
                            
                                <img src={item.image} alt="GMH" />
                            
                        </div>   
                    </div>
                ))}               
            </div>
        </div> */}

        <div>
            <div className='px-6 py-2 flex flex-col justify-center justify-items-center flex-wrap'>
                {photo && photo?.project_unit_photos.map((item) => (
                    <div className='mb-10'>
                        <p className='text-2xl font-bold' key={item.id}>{item.name}</p>
                        
                        <div className="flex justify-center gap-x-4 mt-2 gap-y-6 flex-wrap after:content-[''] after:grow-[3]"
                        onClick={() => {
                            setProperties(item)
                            setShowUpload(true)
                        }}>
                            
                            <div className="h-[300px] cursor-pointer relative grow">
                                <img src={item.file} alt="GMH" className="object-cover w-full h-full align-middle rounded-xl" />
                            </div>
                        </div>   
                    </div>
                ))}               
            </div>
        </div>

        {showUpload ? <ModalBox object={[properties]}  /> : null}
    </>
  )
}

export default DetailView
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useGetNotificationQuery, useClearNotificationMutation, useReadNotificationMutation, useGetProfileQuery } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NotificationBar = () => {
    const { data: notice } = useGetProfileQuery("Notification", {
        pollingInterval: 3000,
    })
    const { data } = useGetNotificationQuery("Notification", {
        pollingInterval: 3000,
    })
    const [ clearNotification, { data: medData, isSuccess } ] = useClearNotificationMutation();
    const [ readNotification ] = useReadNotificationMutation();
    const [display, setDisplay] = useState(false)
    const [noteModal, setNoteModal] = useState(null)


    const showNotify = () => {
        setDisplay(!display)
        console.log('ive been clicked', display)
    }

    const submit = async (e) => {
        e.preventDefault();
        try {
                        
            await clearNotification("working").unwrap()           
        } catch (error) {
            toast.error(error.error)
        }       
    }

    const read = async (id) => {
        try {
                        
            await readNotification({id, dataToSubmit:'working'}).unwrap()           
        } catch (error) {
            toast.error(error.error)
        }       
    }

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            
        }
    }, [isSuccess])

  return (
    <>
        <div className={`mt-6 rounded-xl cursor-pointer ${display ? "w-full bg-[#FFFFFF]" : "lg:w-full flex lg:block justify-end lg:bg-[#FFFFFF]"}`}>
            <div onClick={showNotify} className={`bg-[#0A3347] rounded-t-xl flex items-center p-2 gap-x-3 text-white 
            ${display ? "w-full" : "w-fit lg:w-full"}`}>
                <div className='relative'>
                    <div className='px-2 py-1 rounded' style={{background: "rgba(255, 255, 255, 0.15)"}}>
                        <FontAwesomeIcon icon={faBell} />
                    </div>
                    <span className='absolute top-0 right-0 -mt-1 -mr-1 px-1 rounded-full bg-red-500 text-white text-xs'>
                        {notice?.data?.user[0]?.unread_notifications}
                    </span>
                </div>

                <div className={`${display ? "block" : "hidden lg:block"}`}>
                    <p>notifications</p>
                </div>           
                
            </div>

            <div className={`${display ? "block" : "hidden lg:block"}`}>
                <div className='text-end mr-6 mt-4 text-red-500 font-medium cursor-pointer' onClick={submit}>
                {data && data.data.length > 0 ? (
                        <p>Clear all</p>
                    ) : (
                        null
                    )
                }
                    
                </div>

                <div className='px-6 pt-2 pb-4 space-y-8 max-h-52 overflow-y-auto'>
                    {data && data.data.length > 0 ? (
                        data.data.map((notify) => (
                            <div className='bg-[#F4F0EB] hover:bg-[#0A3347] hover:text-[#F4F0EB] hover:rounded-lg hover:underline' 
                              onClick={(e) => { 
                                e.preventDefault(); 
                                read(notify.id); 
                 
                              }}
                            >
                              {noteModal === notify.id ?
                              <p className={`p-2 text-sm cursor-pointer hover:font-medium ${notify.is_read === 1 ? '' : 'font-bold underline'}`}>
                                {notify.data} <span className="ml-3">{notify.attach_link !== ("" || null) && <a href={notify.attach_link} download> <FontAwesomeIcon icon={faDownload} /> </a>}</span>
                              </p> : 
                              <p className={`p-2 text-sm cursor-pointer hover:font-medium ${notify.is_read === 1 ? '' : 'font-bold underline'}`}>
                                {notify.data.split(' ').slice(0, 4).join(' ')}... <span className="text-red-600 ml-3" onClick={() => setNoteModal(notify.id)}>Read More</span>
                              </p>
                              }
                            </div>
                        ))
                    ) : (
                        <div className='flex justify-center items-center h-full'>
                            <svg viewBox='0 0 24 24' className='w-12 h-12'>
                                <path fill='#4B5563' d='M12 2C8.14 2 5 5.14 5 9v6c0 3.86 3.14 7 7 7s7-3.14 7-7V9c0-3.86-3.14-7-7-7zM7.5 9c0-2.76 2.24-5 5-5s5 2.24 5 5v6c0 2.76-2.24 5-5 5s-5-2.24-5-5V9zm4.5 3c.83 0 1.5.67 1.5 1.5S12.83 15 12 15s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z'></path>
                            </svg>
                            <p className='text-gray-500 text-sm ml-2'>No notifications yet</p>
                        </div>
                    )}
                </div>
            </div>
            
        </div>

        
        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />

        
    </>
  )
}

export default NotificationBar
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


var api_origin = 'https://api.gmhluxury.app/';
const urlParams = new URLSearchParams(window.location.search)
const TokenAuthless = urlParams.get('enter')
if(TokenAuthless){localStorage.setItem('authless', JSON.stringify(TokenAuthless))}
var Token = JSON.parse(localStorage.getItem("authless"))

export const apiSlice = createApi({

    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'https://testing.gmhluxury.app/',https://api.droomwork.io/
        baseUrl: "https://gmh.droomwork.io/",
        mode: "cors",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            headers.set(
                // 'Authorization', `Bearer ${Token}`,
                'Accept', '*/*',
            );
            headers.set('Access-Control-Allow-Origin', "*");
            headers.set('Accept', 'application/json');
            headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
            // headers.set('Cache-Control', 'no-cache');
            // headers.set('Pragma', 'no-cache');
            // headers.set('Expires', '0');
            return headers
        }
    }),
    tagTypes: ['Department','Role','Employee', 'Subscriber', 'Project', 'Material', "Contractor", 'Supplier'],

    endpoints: builder => ({
        getDepartments: builder.query({
            query: () => 'api/v1/ict/department/',
            transformResponse: (response) => response.data,
            providesTags: ['Department']
        }),

        addDepartment: builder.mutation({
            query: initialPost  => ({
              url: 'api/v1/ict/department',
              body: initialPost,
              method: 'POST',      
              // Include the entire post object as the body of the request
            }),
            invalidatesTags: ['Department']
        }),

        deleteDepartment: builder.mutation({
            query: (id) => ({
              url: `api/v1/ict/department/${id}`,
              method: 'DELETE',
              responseHandler: (response) => response.text()
            }),
            invalidatesTags: ['Department']
        }),

        editDepartment: builder.mutation({
            query: post => ({
              url: `api/v1/ict/department/${post.id}`,
              method: 'PUT',
              body: post
            }),
            invalidatesTags: ['Department']
        }),

        addRole: builder.mutation({
            query: initialPost  => ({
              url: 'api/v1/ict/createRole',
              body: initialPost,
              method: 'POST',      
              // Include the entire post object as the body of the request
            }),
            invalidatesTags: ['Role']
        }),

        addMaterial: builder.mutation({
            query: initialPost  => ({
              url: 'api/v1/ict/createMaterial',
              body: initialPost,
              method: 'POST',      
              // Include the entire post object as the body of the request
            }),
            invalidatesTags: ['Material']
        }),

        getMaterial: builder.query({
          query: () => 'api/v1/procurement/getAllMaterials',
            providesTags: ['Material']
      }),

        getRoles: builder.query({
            query: () => 'api/v1/ict/getRoles/',
            transformResponse: (response) => response.data,
            providesTags: ['Role']
        }),

        editRoles: builder.mutation({
            query: post => ({
              url: `api/v1/ict/editRole/${post.id}`,
              method: 'PUT',
              body: post
            }),
            invalidatesTags: ['Role']
        }),

        deleteRole: builder.mutation({
            query: (id) => ({
              url: `api/v1/ict/deleteRole/${id}`,
              method: 'DELETE',
              responseHandler: (response) => response.json()
            }),
            invalidatesTags: ['Role']
        }),

        createEmployee: builder.mutation({
            query: initialPost  => ({
              url: 'api/v1/auth/registerEmployee',
              body: initialPost,
              method: 'POST',      
              // Include the entire post object as the body of the request
            }),
            invalidatesTags: ['Employee']
        }),

        getEmployeee: builder.query({
            query: (postId) => `api/v1/ict/getAllEmployeeDetails/${postId}`,
            transformResponse: (response) => response,
            providesTags: ['Employee']
        }),

        getEmployees: builder.query({
            query: (page) => `api/v1/ict/getAllEmployees/?current_page=${page}`,
            providesTags: (result = [], error, arg) => [
              'Employee',
              ...result?.data?.data?.map(({ id }) => ({ type: 'Employee', id }))
            ]
        }),

        employeeOtherDetais: builder.mutation({
            query: post => ({
              url: `api/v1/ict/updateEmployeeOtherBasicDetails/${post.id}`,
              method: 'PUT',
              body: post.formData
            }),
            invalidatesTags: ['Employee'], 
        }),

        addEmployeeMedicals: builder.mutation({
            query: post => ({
              url: `api/v1/ict/addEmployeeMedicals/${post.id}`,
              method: 'PUT',
              body: post.dataToSubmit
            }),
            invalidatesTags: ['Employee'], 
        }),

        addEmployeeSanctions: builder.mutation({
            query: post => ({
              url: `api/v1/ict/addEmployeeSanctions/${post.id}`,
              method: 'PUT',
              body: post.data
            }),
            invalidatesTags: ['Employee'], 
        }),

        addEmployeeCareers: builder.mutation({
          query: post => ({
            url: `api/v1/ict/addEmployeeCareers/${post.id}`,
            method: 'PUT',
            body: post.dataToSubmit
          }),
          invalidatesTags: ['Employee'], 
      }),

      addEmployeeSalaryAdvance: builder.mutation({
        query: post => ({
          url: `api/v1/ict/addEmployeeSalaryAdvance/${post.id}`,
          method: 'PUT',
          body: post.dataToSubmit
        }),
        invalidatesTags: ['Employee'], 
      }),

    addEmployeeEmploymentHistory: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addEmployeeEmploymentHistory/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Employee'], 
    }),

    addEmployeeKins: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addEmployeeKins/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Employee'], 
    }),

    addEmployeeAcademics: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addEmployeeAcademics/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Employee'], 
    }),

    editEmployeeMedicals: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editEmployeeMedicals/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }], 
    }),

    editEmployeeKins: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addEmployeeKins/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }], 
    }),

    editEmployeeSanctions: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editEmployeeSanctions/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Employee'],
    }),

    editEmployeeSalaryAdvance: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editEmployeeSalaryAdvance/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }], 
    }),

    editEmployeeCareers: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editEmployeeCareers/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }], 
    }),

    editEmployeeEmployementHistory: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editEmployeeEmployementHistory/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }],
    }),

    editEmployeeAcademics: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editEmployeeAcademics/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }], 
    }),

    createSubscriber: builder.mutation({
      query: initialPost  => ({
        url: 'api/v1/auth/registerSubscribers',
        body: initialPost,
        method: 'POST',      
        // Include the entire post object as the body of the request
      }),
      invalidatesTags: ['Subscriber']
    }),

    getSubscribers: builder.query({
      query: (page) => `api/v1/ict/getAllSubscribers/?current_page=${page}`,
      // transformResponse: (response) => response.data,
      providesTags: ['Subscriber']
    }),

    subscriberOtherDetails: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addSubscribersDetails/${post.id}`,
        method: 'PUT',
        body: post.formData
      }),
      invalidatesTags: ['Subscriber'], 
    }),

    addSubscriberDocument: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addSubscriberAttachment/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Subscriber'], 
    }),

    addSubscribeDocument: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addSubscriberDocument/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Subscriber'], 
    }),

    addSubscribersUnits: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addSubscribersUnits/${post.id}/${post.subId}/${post.unitId}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Subscriber'], 
    }),

    addSubscribersUnitPrices: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addSubscribersUnitPrices/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Subscriber'], 
    }),

    addSubscriberProject: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addSubscriberProject/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Subscriber'], 
    }),

    createProject: builder.mutation({
      query: initialPost  => ({
        url: 'api/v1/ict/createProject',
        body: initialPost,
        method: 'POST',      
        // Include the entire post object as the body of the request
      }),
      invalidatesTags: ['Project']
  }),

    getAllProjectDetails: builder.query({
      query: (page) => `api/v1/general/viewAllProjectDetails/?current_page=${page}`,
      providesTags: ['Project']
    }),

    addProjectAmenities: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectAmenities/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

    addProjectLandmarkPhotos: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectLandmarkPhotos/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

    addProjectPhotos: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectPhotos/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

    addProjectVideos: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectVideos/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

    addProjectUnits: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectUnits/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

    addProjectUnitPhotos: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectUnitPhotos/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

    addProjectUnitPricing: builder.mutation({
      query: post => ({
        url: `api/v1/ict/addProjectUnitPricing/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Project'], 
    }),

      createContractor: builder.mutation({
        query: initialPost  => ({
          url: 'api/v1/auth/registerContractors',
          body: initialPost,
          method: 'POST',      
          // Include the entire post object as the body of the request
        }),
        invalidatesTags: ['Contractors']
      }),

      createSupplier: builder.mutation({
        query: initialPost  => ({
          url: 'api/v1/auth/registerSuppliers',
          body: initialPost,
          method: 'POST',      
          // Include the entire post object as the body of the request
        }),
        invalidatesTags: ['Supplier']
      }),

      editEmployee: builder.mutation({
        query: post => ({
          url: `api/v1/ict/updateEmployeeOtherBasicDetails/${post.id}`,
          method: 'PUT',
          body: post.dataToSubmit
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg.id }], 
      }),

      changePassword: builder.mutation({
        query: initialPost  => ({
          url: 'api/v1/general/changePassword',
          body: initialPost,
          method: 'PUT',      
          // Include the entire post object as the body of the request
        }),
        invalidatesTags: ['Password']
    }),

    getNotification: builder.query({
        query: (page) => 'api/v1/general/getAllNotifications',
        providesTags: ['Notification']
    }),

    clearNotification: builder.mutation({
        query: post  => ({
          url: 'api/v1/general/clearAllNotifications',
          body: post,
          method: 'DELETE',      
          // Include the entire post object as the body of the request
        }),
        invalidatesTags: ['Notification']
    }),

    readNotification: builder.mutation({
        query: post => ({
            url: `api/v1/general/readNotification/${post.id}`,
            method: 'PUT',
            body: post.dataToSubmit
        }),
        invalidatesTags: ['Notification'], 
    }),
    
    getProfile: builder.query({
        query: () => 'api/v1/auth/getUserProfiles',
        providesTags: ['Notification']
    }),

    getRecentPhotos: builder.query({
      query: () => 'api/v1/general/getRecentProjectPhotos',
      providesTags: ['Project']
  }), //fen

  // getAllProjectDetails: builder.query({
  //     query: (page) => `api/v1/general/viewAllProjectDetails/?current_page=${page}`,
  //     providesTags: ['Project']
  // }),//fen

    getAllProjectUnitsByProjectId: builder.query({
      query: (id) => `api/v1/general/getAllProjectUnitsByProjectId/${id}`,
      providesTags: ['Project']
    }),

    getStatistics: builder.query({
      query: () => 'api/v1/general/getAllDashboardStatistics',
      providesTags: ['Statistics']
    }),

    editSubsciber: builder.mutation({
      query: post => ({
        url: `api/v1/ict/editSubscribers/${post.id}`,
        method: 'PUT',
        body: post.dataToSubmit
      }),
      invalidatesTags: ['Subscribers'], 
    }),
    })
})

export const { useGetDepartmentsQuery, useAddDepartmentMutation, useDeleteDepartmentMutation,useEditDepartmentMutation, useAddRoleMutation, useAddMaterialMutation, useGetRolesQuery, useEditRolesMutation, useDeleteRoleMutation, useCreateEmployeeMutation, useGetEmployeeeQuery, useGetEmployeesQuery, useEmployeeOtherDetaisMutation, useAddEmployeeMedicalsMutation, useAddEmployeeSanctionsMutation, useAddEmployeeCareersMutation, useAddEmployeeSalaryAdvanceMutation, useAddEmployeeEmploymentHistoryMutation, useAddEmployeeKinsMutation, useAddEmployeeAcademicsMutation, useEditEmployeeMedicalsMutation, useEditEmployeeKinsMutation, useEditEmployeeSanctionsMutation, useEditEmployeeSalaryAdvanceMutation, useEditEmployeeCareersMutation, useEditEmployeeEmployementHistoryMutation, useEditEmployeeAcademicsMutation, useCreateSubscriberMutation, useGetSubscribersQuery, useSubscriberOtherDetailsMutation, useAddSubscriberDocumentMutation, useAddSubscribeDocumentMutation, useAddSubscribersUnitsMutation, useAddSubscribersUnitPricesMutation, useAddSubscriberProjectMutation, useCreateProjectMutation, useGetMaterialQuery, useGetAllProjectDetailsQuery, useAddProjectAmenitiesMutation, useAddProjectLandmarkPhotosMutation, useAddProjectPhotosMutation, useAddProjectVideosMutation, useAddProjectUnitsMutation, useCreateContractorMutation, useCreateSupplierMutation, useEditEmployeeMutation, useAddProjectUnitPhotosMutation, useAddProjectUnitPricingMutation,useChangePasswordMutation,useGetNotificationQuery,useClearNotificationMutation,useReadNotificationMutation,useGetProfileQuery,useGetRecentPhotosQuery, useGetAllProjectUnitsByProjectIdQuery, useGetStatisticsQuery, useEditSubsciberMutation} = apiSlice
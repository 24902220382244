import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useChangePasswordMutation } from '../../reactWrapper/Redux/apiSlice';

const PasswordModal = ({setShowModal} ) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [ changePassword, {isLoading, isSuccess, error, data: medData } ] = useChangePasswordMutation();

    const handleChangePassword = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
          // Show error toast if passwords do not match
          toast.error('Passwords do not match');
          return;
        }
    
        const formData = new FormData();          
        formData.append(`password`, confirmPassword);
      
        try {
            await changePassword( formData ).unwrap();
            setPassword('')
        } catch (error) {
        //   toast.error(error.error);
             console.log("report err", error.data.data.hint.messages.errors.map(err => err.message + " (minimum length 6)"))
        }
      } 

      useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message) 
            console.log("report ", medData) 
            setShowModal(false)
        }
    }, [isSuccess])

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
        <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-2/6 mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div
            class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
            >      
              <svg
                  onClick={() => setShowModal(false)}
                  class="w-6 h-6 cursor-pointer"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                  ></path>
              </svg>
            </div>            

            <div class="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center font-medium text-xl'><h2>Change Password</h2></div>
                <div className="mt-4">
                    <form onSubmit={handleChangePassword}>
                        <div className="block w-full bg-[#F4F0EB] rounded-md">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 dark:text-gray-600">New Password</label>
                            <input type="password" name="email" id="email" placeholder="password" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                        </div>

                        <div className="mt-6 block w-full bg-[#F4F0EB] rounded-md">
                            {/* <div className="flex justify-between mb-2"> */}
                                <label for="password" class="block px-4 pt-1 text-sm text-gray-600 dark:text-gray-600">Confirm Password</label>
                                {/* <a href="www.google.com" className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Forgot password?</a> */}
                            {/* </div> */}

                            <div className="relative">
                                <input type="password"
                                name="password" id="password" placeholder="Password" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <button  className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={handleChangePassword}>
                            {isLoading ? 'Processing...' : 'update'}
                        
                            </button>
                        </div>

                    </form>

                    {/* <p className="mt-6 text-sm text-center text-gray-400">Don&#x27;t have an account yet? <a href="www.google.com" class="text-blue-500 focus:outline-none focus:underline hover:underline">Sign up</a>.</p> */}
                </div>
            </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default PasswordModal
import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSubscriberOtherDetailsMutation } from '../../reactWrapper/Redux/apiSlice';

const SubDetails = ({setEmployeeInfo, id}) => {

    const [inputFields, setInputFields] = useState({date_of_birth: '', nationality: '', marital_status: '', occupation: '', place_of_work: '', office_address: '', number_of_unit_applied: '', how_you_heard: '', next_of_kin: '', relationship: '', next_of_kin_phone: '',next_of_kin_address: '', next_of_kin_email: '', preferred_payment_option: '', status: ''})

    const [ subscriberOtherDetails, {isLoading, isSuccess, error: subError, data: medData } ] = useSubscriberOtherDetailsMutation();

    const handleChange = event => {
        setInputFields({
        ...inputFields,
        [event.target.name]: event.target.value
        });
    };

    const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`date_of_birth`, moment(inputFields.date_of_birth).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`nationality`, inputFields.nationality);
        formData.append(`marital_status`, inputFields.marital_status);
        formData.append(`occupation`, inputFields.occupation);
        formData.append(`place_of_work`, inputFields.place_of_work);
        formData.append(`office_address`, inputFields.office_address);
        formData.append(`number_of_unit_applied`, inputFields.number_of_unit_applied);
        formData.append(`how_you_heard`, inputFields.how_you_heard);
        formData.append(`next_of_kin`, inputFields.next_of_kin);
        formData.append(`relationship`, inputFields.relationship);
        formData.append(`next_of_kin_phone`, inputFields.next_of_kin_phone);
        formData.append(`next_of_kin_address`, inputFields.next_of_kin_address);
        formData.append(`next_of_kin_email`, inputFields.next_of_kin_email);
        formData.append(`preferred_payment_option`, inputFields.preferred_payment_option);
        formData.append(`status`, inputFields.status);
      
        try {
            await subscriberOtherDetails({id, formData}).unwrap();
            setInputFields({date_of_birth: '', nationality: '', marital_status: '', occupation: '', place_of_work: '', office_address: '', number_of_unit_applied: '', how_you_heard: '', next_of_kin: '', relationship: '', next_of_kin_phone: '',next_of_kin_address: '', next_of_kin_email: '', preferred_payment_option: '', status: ''});
        } catch (error) {
          toast.error(error.error);
          console.log("the error", error)
        }
      };

      useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setEmployeeInfo(false)   
        }
      }, [isSuccess])

      useEffect(() => {
        if(subError) {
            toast.error(subError?.data.messages)    
        }
      }, [subError])
  return (
    <>
    <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>More Info</p>
                    </div> 

                    <svg
                        onClick={() => setEmployeeInfo(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                
                  <form>
                      <div>
                          <div className="flex justify-between mb-1">
                              <p className='text-lg font-medium'>Complete Subscribers Details</p>
                          </div>

                          <div className="">
                              <div className="grid grid-cols-2 gap-x-4">
                                  <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Birth</label>
                                      <input type="date" id="email" placeholder="eg Mr/Mrs"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="date_of_birth" value={inputFields.date_of_birth} onChange={handleChange} />
                                  </div>     

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Nationality</label>
                                      <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"  name="nationality" value={inputFields.nationality} onChange={handleChange} >
                                        <option selected>select country</option>
                                        <option value="AF">Afghanistan</option>
                                        <option value="AX">Aland Islands</option>
                                        <option value="AL">Albania</option>
                                        <option value="DZ">Algeria</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AQ">Antarctica</option>
                                        <option value="AG">Antigua and Barbuda</option>
                                        <option value="AR">Argentina</option>
                                        <option value="AM">Armenia</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AU">Australia</option>
                                        <option value="AT">Austria</option>
                                        <option value="AZ">Azerbaijan</option>
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesh</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus</option>
                                        <option value="BE">Belgium</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivia</option>
                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                        <option value="BA">Bosnia and Herzegovina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BV">Bouvet Island</option>
                                        <option value="BR">Brazil</option>
                                        <option value="IO">British Indian Ocean Territory</option>
                                        <option value="BN">Brunei Darussalam</option>
                                        <option value="BG">Bulgaria</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                        <option value="KH">Cambodia</option>
                                        <option value="CM">Cameroon</option>
                                        <option value="CA">Canada</option>
                                        <option value="CV">Cape Verde</option>
                                        <option value="KY">Cayman Islands</option>
                                        <option value="CF">Central African Republic</option>
                                        <option value="TD">Chad</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">China</option>
                                        <option value="CX">Christmas Island</option>
                                        <option value="CC">Cocos (Keeling) Islands</option>
                                        <option value="CO">Colombia</option>
                                        <option value="KM">Comoros</option>
                                        <option value="CG">Congo</option>
                                        <option value="CD">Congo, Democratic Republic of the Congo</option>
                                        <option value="CK">Cook Islands</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Cote D'Ivoire</option>
                                        <option value="HR">Croatia</option>
                                        <option value="CU">Cuba</option>
                                        <option value="CW">Curacao</option>
                                        <option value="CY">Cyprus</option>
                                        <option value="CZ">Czech Republic</option>
                                        <option value="DK">Denmark</option>
                                        <option value="DJ">Djibouti</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominican Republic</option>
                                        <option value="EC">Ecuador</option>
                                        <option value="EG">Egypt</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="GQ">Equatorial Guinea</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estonia</option>
                                        <option value="ET">Ethiopia</option>
                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                        <option value="FO">Faroe Islands</option>
                                        <option value="FJ">Fiji</option>
                                        <option value="FI">Finland</option>
                                        <option value="FR">France</option>
                                        <option value="GF">French Guiana</option>
                                        <option value="PF">French Polynesia</option>
                                        <option value="TF">French Southern Territories</option>
                                        <option value="GA">Gabon</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgia</option>
                                        <option value="DE">Germany</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GI">Gibraltar</option>
                                        <option value="GR">Greece</option>
                                        <option value="GL">Greenland</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GP">Guadeloupe</option>
                                        <option value="GU">Guam</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GG">Guernsey</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                        <option value="HT">Haiti</option>
                                        <option value="HM">Heard Island and Mcdonald Islands</option>
                                        <option value="VA">Holy See (Vatican City State)</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HK">Hong Kong</option>
                                        <option value="HU">Hungary</option>
                                        <option value="IS">Iceland</option>
                                        <option value="IN">India</option>
                                        <option value="ID">Indonesia</option>
                                        <option value="IR">Iran, Islamic Republic of</option>
                                        <option value="IQ">Iraq</option>
                                        <option value="IE">Ireland</option>
                                        <option value="IM">Isle of Man</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italy</option>
                                        <option value="JM">Jamaica</option>
                                        <option value="JP">Japan</option>
                                        <option value="JE">Jersey</option>
                                        <option value="JO">Jordan</option>
                                        <option value="KZ">Kazakhstan</option>
                                        <option value="KE">Kenya</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="KP">Korea, Democratic People's Republic of</option>
                                        <option value="KR">Korea, Republic of</option>
                                        <option value="XK">Kosovo</option>
                                        <option value="KW">Kuwait</option>
                                        <option value="KG">Kyrgyzstan</option>
                                        <option value="LA">Lao People's Democratic Republic</option>
                                        <option value="LV">Latvia</option>
                                        <option value="LB">Lebanon</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Lithuania</option>
                                        <option value="LU">Luxembourg</option>
                                        <option value="MO">Macao</option>
                                        <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                        <option value="MG">Madagascar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Maldives</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MH">Marshall Islands</option>
                                        <option value="MQ">Martinique</option>
                                        <option value="MR">Mauritania</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="YT">Mayotte</option>
                                        <option value="MX">Mexico</option>
                                        <option value="FM">Micronesia, Federated States of</option>
                                        <option value="MD">Moldova, Republic of</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolia</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MS">Montserrat</option>
                                        <option value="MA">Morocco</option>
                                        <option value="MZ">Mozambique</option>
                                        <option value="MM">Myanmar</option>
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NL">Netherlands</option>
                                        <option value="AN">Netherlands Antilles</option>
                                        <option value="NC">New Caledonia</option>
                                        <option value="NZ">New Zealand</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NE">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="NU">Niue</option>
                                        <option value="NF">Norfolk Island</option>
                                        <option value="MP">Northern Mariana Islands</option>
                                        <option value="NO">Norway</option>
                                        <option value="OM">Oman</option>
                                        <option value="PK">Pakistan</option>
                                        <option value="PW">Palau</option>
                                        <option value="PS">Palestinian Territory, Occupied</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua New Guinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippines</option>
                                        <option value="PN">Pitcairn</option>
                                        <option value="PL">Poland</option>
                                        <option value="PT">Portugal</option>
                                        <option value="PR">Puerto Rico</option>
                                        <option value="QA">Qatar</option>
                                        <option value="RE">Reunion</option>
                                        <option value="RO">Romania</option>
                                        <option value="RU">Russian Federation</option>
                                        <option value="RW">Rwanda</option>
                                        <option value="BL">Saint Barthelemy</option>
                                        <option value="SH">Saint Helena</option>
                                        <option value="KN">Saint Kitts and Nevis</option>
                                        <option value="LC">Saint Lucia</option>
                                        <option value="MF">Saint Martin</option>
                                        <option value="PM">Saint Pierre and Miquelon</option>
                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">Sao Tome and Principe</option>
                                        <option value="SA">Saudi Arabia</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbia</option>
                                        <option value="CS">Serbia and Montenegro</option>
                                        <option value="SC">Seychelles</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="SG">Singapore</option>
                                        <option value="SX">Sint Maarten</option>
                                        <option value="SK">Slovakia</option>
                                        <option value="SI">Slovenia</option>
                                        <option value="SB">Solomon Islands</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ZA">South Africa</option>
                                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                                        <option value="SS">South Sudan</option>
                                        <option value="ES">Spain</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SD">Sudan</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SJ">Svalbard and Jan Mayen</option>
                                        <option value="SZ">Swaziland</option>
                                        <option value="SE">Sweden</option>
                                        <option value="CH">Switzerland</option>
                                        <option value="SY">Syrian Arab Republic</option>
                                        <option value="TW">Taiwan, Province of China</option>
                                        <option value="TJ">Tajikistan</option>
                                        <option value="TZ">Tanzania, United Republic of</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TL">Timor-Leste</option>
                                        <option value="TG">Togo</option>
                                        <option value="TK">Tokelau</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad and Tobago</option>
                                        <option value="TN">Tunisia</option>
                                        <option value="TR">Turkey</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TC">Turks and Caicos Islands</option>
                                        <option value="TV">Tuvalu</option>
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="AE">United Arab Emirates</option>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="UM">United States Minor Outlying Islands</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Uzbekistan</option>
                                        <option value="VU">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="VN">Viet Nam</option>
                                        <option value="VG">Virgin Islands, British</option>
                                        <option value="VI">Virgin Islands, U.s.</option>
                                        <option value="WF">Wallis and Futuna</option>
                                        <option value="EH">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                      </select>
                                  </div>       

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Marital Status</label>
                                      <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="marital_status" value={inputFields.marital_status} onChange={handleChange}>
                                        <option selected>Choose Status</option>
                                        <option value="Married">Married</option>
                                        <option value="Single">Single</option>
                                        <option value="Engaged">Engaged</option>
                                        <option value="Not say">Not to Say</option>
                                      </select>
                                  </div>     

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Occupation</label>
                                      <input type="text" id="email" placeholder="eg no 7 Abe ...."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="occupation" value={inputFields.occupation} onChange={handleChange} />
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Place of Work</label>
                                      <input type="text" id="email" placeholder="eh GMH"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="place_of_work" value={inputFields.place_of_work} onChange={handleChange} />
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Office Address</label>
                                      <input type="text" id="email" placeholder="eh 4 Road .."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="office_address" value={inputFields.office_address} onChange={handleChange} />
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Number of Unit Applied</label>
                                      <input type="number" id="email" placeholder="eg 50"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="number_of_unit_applied" value={inputFields.number_of_unit_applied} onChange={handleChange} />
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">How you Heard about us</label>
                                      <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="how_you_heard" value={inputFields.how_you_heard} onChange={handleChange} >
                                        <option selected>Choose an Option</option>
                                        <option value="salesPerson">Sales Person</option>
                                        <option value="newspaper">Newspaper</option>
                                        <option value="billboard">Billboard</option>
                                        <option value="referral">Referral</option>
                                        <option value="online">Online</option>
                                        <option value="Others">Others</option>
                                      </select>
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Name of Next Kin</label>
                                      <input type="text"  id="email" placeholder="eg Kings"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="next_of_kin" value={inputFields.next_of_kin} onChange={handleChange} />
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Relationship</label>
                                      <input type="text" id="email" placeholder="Relationship Type"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="relationship" value={inputFields.relationship} onChange={handleChange}/>
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Kin Phone</label>
                                      <input type="text" id="email" placeholder="080 -00000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="next_of_kin_phone" value={inputFields.next_of_kin_phone} onChange={handleChange}/>
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Kin Address</label>
                                      <input type="text" id="email" placeholder="Relationship Type"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="next_of_kin_address" value={inputFields.next_of_kin_address} onChange={handleChange}/>
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Kin Email</label>
                                      <input type="email" id="email" placeholder="Relationship Type"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="next_of_kin_email" value={inputFields.next_of_kin_email} onChange={handleChange}/>
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Status</label>
                                      <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="status" value={inputFields.status} onChange={handleChange}>
                                        <option selected>Choose an Option</option>
                                        <option value="prospects">Prospect</option>
                                        <option value="subscriber">Subscriber</option>
                                      </select>
                                  </div>

                                  <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                      <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">preferred_payment_option</label>
                                      <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="preferred_payment_option" value={inputFields.preferred_payment_option} onChange={handleChange}>
                                        <option selected>Choose an Option</option>
                                        <option value="Outright">Outright</option>
                                        <option value="Installment">Installment</option>
                                      </select>
                                  </div>
                              </div>
                            
                              <div className="mt-6">
                                <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                                    {isLoading ? 'Adding...' : 'Add'}
                                </button>
                              </div>
                          </div>
                          
                      </div>
                  </form>

                </div>
            </div>
        </div>

    <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </>
        
  )
}

export default SubDetails
import React from 'react'
import OverSide from '../components/overview/OverSide'

const OverView = () => {
  return (
    <div className='lg:ml-[12rem] pb-10'>
        <OverSide />
    </div>
  )
}

export default OverView
import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faInbox } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import UnitDetails from './UnitDetails';
import AddUnit from './AddUnit';

const FullDetails = ({showModal, data}) => {
    

    const [showFModal, setShowFModal] = useState(false);
    const [showMedModal, setShowMedModal] = useState(false);
    const [showKin, setShowKin] = useState(false);
    
    const [showMedRecord, setShowMedRecord] = useState(false);
    
    const [amenities, setAmenities] = useState(false);
    const [unitShow, setUnitShow] = useState(false);
    const [addUnit, setAddUnit] = useState(false);
    const [id, setId] = useState(false);
    const [unitData, setUnitData] = useState(false);

    const Modal = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowFModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Contract</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">First Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Oladapo"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Lat Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Mayowa"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                            <input type="email" name="text" id="email" placeholder="mayowa@gmail.com"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Referral Code</label>
                            <input type="text" name="text" id="email" placeholder="123OlA234"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary</label>
                            <input type="number" name="text" id="email" placeholder="#300,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a Role</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a role</option>
                                <option value="US">Head of Sales</option>
                                <option value="CA">Head of  Marketing</option>
                                <option value="FR">Head of Project</option>
                                <option value="DE">Head of Media</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a Department</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a deparment</option>
                                <option value="US">Sales</option>
                                <option value="CA">Marketing</option>
                                <option value="FR">Project</option>
                                <option value="DE">Media</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Resumption Date</label>
                            <input type="date" name="text" id="email" placeholder="0+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Sex</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a deparment</option>
                                <option value="US">Female</option>
                                <option value="CA">Male</option>                        
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Marital Sttus</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a status</option>
                                <option value="US">Married</option>
                                <option value="CA">Single</option>                        
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Street</label>
                            <input type="text" name="text" id="email" placeholder="50 Sangotedo"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">City</label>
                            <input type="text" name="text" id="email" placeholder="Eti-Osa"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="text" className="block px-4 pt-1 text-sm text-gray-600 ">City</label>
                            <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State</label>
                            <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                            <input type="number" name="text" id="email" placeholder="0809765467" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Birth</label>
                            <input type="date" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Number of Children</label>
                            <input type="number" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Number of Dependent</label>
                            <input type="number" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Nationality</label>
                            <input type="text" name="text" id="email" placeholder="Nigeria"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State of Origin</label>
                            <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">LGA of Origin</label>
                            <input type="text" name="text" id="email" placeholder="Eti-Osa 3"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Account Name</label>
                            <input type="text" name="text" id="email" placeholder="Mayowa Musa"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Account Number</label>
                            <input type="number" name="text" id="email" placeholder="987654588"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Account Bank</label>
                            <input type="text" name="text" id="email" placeholder="Keystone"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Create
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalMedical = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowMedModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Basic Field</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Name..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Location</label>
                            <input type="text" name="text" id="email" placeholder="eg 20 Location..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Project Size</label>
                            <input type="number" name="text" id="email" placeholder="eg 23"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Title</label>
                            <input type="text" name="text" id="email" placeholder="eg GCC...."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Delivery Date</label>
                            <input type="date" name="text" id="email" placeholder="O+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Status</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold">
                            <option selected>Choose a Status</option>
                            <option value="US">Completed</option>
                            <option value="CA">Ongoing</option>
                            </select>
                        </div>

                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalKin = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowKin(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Project Photo</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="">
                            <img src="/images/images.jpg" alt="projects" className='rounded'/>
                            <p>Image Name here</p>
                        </div>

                        <div className="">
                            <img src="/images/images.jpg" alt="projects" className='rounded'/>
                            <p>Image Name here</p>
                        </div>

                        <div className="">
                            <img src="/images/images.jpg" alt="projects" className='rounded'/>
                            <p>Image Name here</p>
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    

    const ModalMedRecord = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowMedRecord(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Project Unit </p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                            <input type="text" name="text" id="email" placeholder="eg GCC III" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Additional Fee Component</label>
                            <input type="number" name="text" id="email" placeholder="eg 200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price</label>
                            <input type="number" name="text" id="email" placeholder="eg 200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Initial Deposit</label>
                            <input type="number" name="text" id="email" placeholder="eg 200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>     

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Total Price</label>
                            <input type="number" name="text" id="email" placeholder="eg 200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>             

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Additional Fee</label>
                            <input type="number" name="text" id="email" placeholder="eg 200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    

    const AmenitiesPlan = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setAmenities(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Project Amenities</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Lightning..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

  return (
    <>
    <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>View Project Details</p>
                    </div> 

                    <svg
                        onClick={() => showModal(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                    <div className='bg-white px-4 '>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} 
                                    onClick={() => setShowMedModal(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 gap-y-3 justify-center py-2'>
                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{data?.name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Location</p>
                                <p>{data?.location}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Project Size</p>
                                <p>{data?.project_size}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Title</p>
                                <p>{data?.title}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Delivery Date</p>
                                <p>{data?.delivery_date ? moment(data?.delivery_date).format("YYYY-MM-DD") : null}</p>
                                
                            </div>

                            <div>
                                <p className="font-semibold">Status</p>
                                <p>{data?.status}</p>
                            </div>
                        </div>                       
                    </div>

                    
                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Photos</h2>
                            <div className='flex my-1'>
                                <div className="hidden mr-2">
                                    <i><FontAwesomeIcon icon={faEdit}
                                    onClick={() => setShowKin(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3 gap-x-3'>
                        {data?.project_photos.map(photo => (
                            <div className="">
                                <img src={photo?.file} alt={photo?.name} className='rounded'/>
                                <p>{photo?.name}</p>
                            </div>
                        ))}
                            
                        </div>
                    </div>                   

                    {data?.project_units.map(unit => (
                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Unit</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faInbox} onClick={() => {setUnitShow(true);
                                    setUnitData(unit)}}  className="cursor-pointer"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faPlus} onClick={() => {
                                        setAddUnit(true);
                                        setId(unit.id)
                                        }}  className="cursor-pointer"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => setShowMedRecord(true)}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3'>
                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{unit?.name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Additional Fee Component</p>
                                <p>{unit?.additional_fee_component}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Price</p>
                                <p>{unit?.price}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Initial Deposit</p>
                                <p>{unit?.initial_deposit}</p>
                            </div>

                            
                        </div>
                    </div>
                    ))}

                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Videos</h2>
                            <div className='flex my-1'>
                                <div className="hidden mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => setShowMedRecord(true)}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        {data?.project_videos.map(video => (
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-3'>
                            <div className="">
                                <p className="font-semibold">{video?.name}</p>
                                <p>{video?.video_link}</p>
                            </div>                           
                        </div>
                        ))}
                    </div>

                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Landmark Photos</h2>
                            <div className='flex my-1'>
                                <div className="hidden mr-2">
                                    <i><FontAwesomeIcon icon={faEdit}
                                    onClick={() => setShowKin(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        {data?.project_landmark_photos.map(land => (
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3 gap-x-3'>
                            <div className="">
                                <img src={land?.file} alt="projects" className='rounded'/>
                                <p>{land?.name}</p>
                            </div>
                           
                        </div>
                        ))}
                    </div>


                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Amenities</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => setAmenities(true)}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        {data?.project_amenities.map(land => (
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3 gap-x-3'>
                            <div className="">
                                <img src={land?.file} alt="projects" className='rounded'/>
                                <p>{land?.name}</p>
                            </div>
                           
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    {showFModal ? <Modal showModal={showModal}/> : null}
    {showMedModal ? <ModalMedical showModal={showModal}/> : null}
    {showKin ? <ModalKin/> : null}
    
    
    {showMedRecord ? <ModalMedRecord showModal={showModal}/> : null}
    {unitShow ? <UnitDetails setUnitShow={setUnitShow} unitData={unitData} /> : null}
    {amenities ? <AmenitiesPlan showModal={showModal}/> : null}
    {addUnit ? <AddUnit id={id} setAddUnit={setAddUnit} /> : null}
    </>
  )
}

export default FullDetails
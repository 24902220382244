import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEditEmployeeAcademicsMutation } from '../../reactWrapper/Redux/apiSlice';

const EditAcademics = ({editData, id, setShowAcademic}) => {
    const [inputFields, setInputFields] = useState({school_name: '', start_date: '',  end_date: '',  qualification_title: '', document_nameA: '', documentA: null})

    const [ editEmployeeAcademics, {isLoading, isSuccess, error, data: medData } ] = useEditEmployeeAcademicsMutation();

    useEffect(() => {
        const fileName = editData?.file.substring(editData?.file.lastIndexOf('/') + 1);
        const updatedFileName = fileName.replace('.pdf.pdf', '.pdf');

        setInputFields({
          school_name: editData?.school_name,
          start_date: editData?.start_date,
          end_date: editData?.end_date,
          qualification_title: editData?.qualification_title,
          documentA: editData?.file,
          document_nameA: updatedFileName
        });
      }, [editData]);

      const handleChange = event => {
        if (event.target.type === "file") {
          setInputFields({
            ...inputFields,
            documentA: event.target.files[0],
            document_nameA: event.target.files[0].name
          });
        } else {
          setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value
          });
        }
      };

      const handleReset = () => {
        setInputFields({
            ...inputFields,
            document_nameA: '',
            documentA: null
          });
      };

      const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`start_date`, moment(inputFields.start_date).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`end_date`, moment(inputFields.end_date).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`school_name`, inputFields.school_name);
        formData.append(`qualification_title`, inputFields.qualification_title);
        formData.append(`file`, inputFields.documentA);
        formData.append(`document_name`, inputFields.document_nameA);
      
        try {
            await editEmployeeAcademics({ id, dataToSubmit: formData }).unwrap();
            setInputFields({school_name: '', start_date: '',  end_date: '',  qualification_title: '', document_nameA: '', documentA: null});
        } catch (error) {
          toast.error(error.error);
        }
      };

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setShowAcademic(false)
            window.location.reload();
        }
    }, [isSuccess])
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowAcademic(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee Academic Section</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">School Name</label>
                            <input type="text" id="email" placeholder="eg University of ABC" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="school_name" value={inputFields.school_name} onChange={handleChange} />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Start Date</label>
                            <input type="date" id="email" placeholder="eg Head of Sales" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="start_date" value={inputFields.start_date && moment(inputFields.start_date).format("YYYY-MM-DD")} onChange={handleChange} />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">End Date</label>
                            <input type="date" id="email" placeholder="eg 1 month" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="end_date" value={inputFields.end_date && moment(inputFields.end_date).format("YYYY-MM-DD")} onChange={handleChange} />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Qualification </label>
                            <input type="text" id="email" placeholder="eg My Qualification" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="qualification_title" value={inputFields.qualification_title} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                        {inputFields.document_nameA === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className=" text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Certificate</span>
                                        <input id="file-upload" 
                                        accept=".pdf,.doc" name="documentA" data-name="document_nameA" type="file" onChange={handleChange} className="sr-only" />
                                        </label>
                                        
                                    </div>
                                    
                                    </div>
                                </div>
                            : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{inputFields.document_nameA}</span> <span className="text-red-700 ml-4"
                                        onClick={handleReset}>X</span>
                                    </p>
                                </div>
                            }
                        </div>                     
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Editing...' : 'Edit'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default EditAcademics
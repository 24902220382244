import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faAdd, faSquareCaretUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import UnitDocx from './UnitDocx';
import AddDocx from './AddDocx';

const SubUnit = ({data, setShowUnit, setUnitData, setShowMedRecord}) => {
    console.log("data here", data);
    const [showDocx, setShowDocx] = useState(false)
    const [Docx, setDocx] = useState(null)
    const [addDocx, setAddDocx] = useState(false)
    const [docId, setDocId] = useState(null)

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>View Subscribers Details</p>
                    </div> 

                    <svg
                        onClick={() => setShowUnit(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                    
                    <div className='bg-white px-4 mt-4' >
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Unit Prices</h2>
                            <div className='flex my-1'>
                                

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faSquareCaretUp} onClick={() => {setShowDocx(true);setDocx(data.subscriber_unit_prices)}}  className="cursor-pointer"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {setShowMedRecord(true);}}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faAdd} onClick={() => {setAddDocx(true);setDocId(data.id)}}  className="cursor-pointer"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-2'>

                            <div className="">
                                <p className="font-semibold">Price</p>
                                <p>{data?.subscriber_unit_prices?.price}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Price Period</p>
                                <p>{data?.subscriber_unit_prices?.price_period}</p>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        
        {showDocx ? <UnitDocx data={Docx} setShowDocx={setShowDocx} />  : null}
        {addDocx ? <AddDocx id={docId} setIndividualDetails={setAddDocx} />  : null}
        
    </>
  )
}

export default SubUnit
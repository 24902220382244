import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddSubscribeDocumentMutation } from '../../reactWrapper/Redux/apiSlice';


const AddDocx = ({id, setIndividualDetails}) => {
    const [inputFields, setInputFields] = useState({document_name: '', file: null, fileName: ''})

    const [accordion, setAccordion] = useState("doc")
    const [ addSubscribeDocument, {isLoading, isSuccess, error, data: medData } ] = useAddSubscribeDocumentMutation();

    const handleChanges = (event) => {
        if (event.target.type === "file") {
            setInputFields({
              ...inputFields,
              file: event.target.files[0],
              fileName: event.target.files[0].name
            });
          } else {
            setInputFields({
              ...inputFields,
              [event.target.name]: event.target.value
            });
        }
    };

    const handleReset = () => {
        setInputFields({
            ...inputFields,
            document_name: '',
            document: null,
            fileName: ''
          });
      };

    const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`file`, inputFields.file);
        formData.append(`document_name`, inputFields.document_name);
      
        try {
            await addSubscribeDocument({ id, dataToSubmit: formData }).unwrap();
          setInputFields({document_name: '', file: null, fileName: ''});
        } catch (error) {
          toast.error(error.error);
        }
    }
    
    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)   
        }
    }, [isSuccess])
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Subscriber Details</p>
                    </div> 

                    <svg
                        onClick={() => setIndividualDetails(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "doc" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Subscriber Attachment</p>
                            {accordion === "doc" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("doc")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "doc" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-1 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {inputFields.fileName === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Subcribers Document</span>
                                        <input id="file-upload" type="file" name="file" value={inputFields.file} onChange={handleChanges} className="sr-only" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500">.doc, .pnt less than 1MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{inputFields.fileName}</span>  <span className="text-red-600 cursor-pointer" onClick={handleReset}>X</span>
                                    </p>
                                </div>
                                }
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Document Name</label>
                                    <input type="text" id="email" placeholder="name.pdf"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="document_name" value={inputFields.document_name} onChange={handleChanges}/>
                                </div>                 
                            </div>

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                                    {isLoading ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>
                </div>
            </div>
        </div>
    </>
  )
}

export default AddDocx
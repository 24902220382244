import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useAddProjectAmenitiesMutation, useAddProjectLandmarkPhotosMutation, useAddProjectPhotosMutation, useAddProjectVideosMutation, useAddProjectUnitsMutation } from '../../reactWrapper/Redux/apiSlice';

const FillProject = ({setIndividualDetails, id}) => {

    const [accordion, setAccordion] = useState("project")
    const [fileName, setFileName] = useState('')
    const [videoName, setVideoName] = useState('')
    const [videoNameB, setVideoNameB] = useState('')

    const [inputFields, setInputFields] = useState([
        {name: '', file: null}
    ])

    const [landFields, setLandFields] = useState([
        {name: '', file: null}
    ])

    const [photoFields, setPhotoFields] = useState([
        {name: '', file: null}
    ])

    const [videoFields, setVideoFields] = useState([
        {name: '', video_link: null}
    ])

    const [unitFields, setUnitFields] = useState([
        {name: '', additional_fee_component: '', price: '', initial_deposit: '', total_price: '', additional_fee: ''}
    ])

    const [ addProjectAmenities, {isLoading, isSuccess, error, data } ] = useAddProjectAmenitiesMutation();
    const [ addProjectLandmarkPhotos, {isLoading: landLoad, isSuccess: landSuccess, error: landError, data: landData } ] = useAddProjectLandmarkPhotosMutation();
    const [ addProjectPhotos, {isLoading: photosLoad, isSuccess: photosSuccess, error: photosError, data: photosData } ] = useAddProjectPhotosMutation();
    const [ addProjectVideos, {isLoading: videoLoad, isSuccess: videoSuccess, error: videoError, data: videoData } ] = useAddProjectVideosMutation();
    const [ addProjectUnits, {isLoading: unitLoad, isSuccess: unitSuccess, error: unitError, data: unitData } ] = useAddProjectUnitsMutation();

    const handleFormChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...inputFields];
        if (name === "file-upload") {
          data[index]["file"] = files[0];
          data[index]["name"] = files[0].name.split('.')[0];
        } else {
          data[index][name] = value;
        }
        setInputFields(data);
    };

    const submitAmenity = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        inputFields.forEach((inputField) => {
            formData.append(`file`, inputField.file);
            formData.append(`name`, inputField.name);
        });
      
        try {
            await addProjectAmenities({ id, dataToSubmit: formData }).unwrap();
            setInputFields([{name: '', file: null}])
        } catch (error) {
          toast.error(error.error);
        }
    };

    useEffect(() => {
        if(isSuccess) {
            toast.success(data.message)     
        }
    }, [isSuccess])

    const handleReset = (index) => {
        let data = [...inputFields];
        data[index]['name'] = '';
        data[index]['file'] = null;
        setInputFields(data);
    };

    const handleLandChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...landFields];
        if (name === "file-upload") {
          data[index]["file"] = files[0];
          data[index]["name"] = files[0].name.split('.')[0];
        } else {
          data[index][name] = value;
        }
        setLandFields(data);
    };

    const submitLand = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        landFields.forEach((inputField) => {
            formData.append(`file`, inputField.file);
            formData.append(`name`, inputField.name);
        });
      
        try {
            await addProjectLandmarkPhotos({ id, dataToSubmit: formData }).unwrap();
            setLandFields([{name: '', file: null}])
        } catch (error) {
          toast.error(error.error);
        }
    };

    useEffect(() => {
        if(landSuccess) {
            toast.success(landData.message)     
        }
    }, [landSuccess])

    const landReset = (index) => {
        let data = [...landFields];
        data[index]['name'] = '';
        data[index]['file'] = null;
        setLandFields(data);
    };

    const handlePhotoChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...photoFields];
        if (name === "file-upload") {
          data[index]["file"] = files[0];
          data[index]["name"] = files[0].name.split('.')[0];
        } else {
          data[index][name] = value;
        }
        setPhotoFields(data);
    };

    const submitPhoto = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        photoFields.forEach((inputField) => {
            formData.append(`file`, inputField.file);
            formData.append(`name`, inputField.name);
        });      
        try {
            await addProjectPhotos({ id, dataToSubmit: formData }).unwrap();
            setPhotoFields([{name: '', file: null}])
        } catch (error) {
          toast.error(error.error);
        }
    };

    useEffect(() => {
        if(photosSuccess) {
            toast.success(photosData.message)     
        }
    }, [photosSuccess])

    const photoReset = (index) => {
        let data = [...photoFields];
        data[index]['name'] = '';
        data[index]['file'] = null;
        setPhotoFields(data);
    };

    const handleVideoChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...videoFields];        
        data[index][name] = value;
        setVideoFields(data);
    };

    const submitVideo = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = Object.assign({}, ...videoFields);
            await addProjectVideos({id, dataToSubmit}).unwrap()
            setVideoFields([{name: '', video_link: ''}])
        } catch (error) {
            toast.error(error.error)
        }       
    }  

    useEffect(() => {
        if(videoSuccess) {
            toast.success(videoData.message)     
        }
    }, [videoSuccess])

    const handleUnitChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...unitFields];        
        data[index][name] = value;
        setUnitFields(data);
    };

    const submitUnit = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = Object.assign({}, ...unitFields);
            await addProjectUnits({id, dataToSubmit}).unwrap()
            setUnitFields([{name: '', additional_fee_component: '', price: '', initial_deposit: '', total_price: '', additional_fee: ''}])
        } catch (error) {
            toast.error(error.error)
        }       
    }  

    useEffect(() => {
        if(unitSuccess) {
            toast.success(unitData.message)     
        }
    }, [unitSuccess])


    const handleChanges = (event) => {
        setFileName(event.target.files[0].name)
    };

    const cancelFile = (e) => {
        e.preventDefault()
        setFileName('')
    }

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Project Details</p>
                    </div> 

                    <svg
                        onClick={() => setIndividualDetails(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                
                <form onSubmit={submitUnit}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "project" ? "" : "shadow-lg shadow-black p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Unit</p>
                            {accordion === "project" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("project")}}
                            ><FontAwesomeIcon icon={faArrowDown}  className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "project" ? "block" : "hidden"}`}>
                        {unitFields?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="nameField" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                                    <input type="text" name="name" id="nameField" placeholder="eg GCC III"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.name}
                                    onChange={event => handleUnitChange(index, event)}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Additional Fee Component</label>
                                    <input type="number" name="additional_fee_component" id="email" placeholder="eg #2,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.additional_fee_component}
                                    onChange={event => handleUnitChange(index, event)}/>
                                </div>

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price</label>
                                    <input type="number" name="price" id="email" placeholder="eg #2,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.price}
                                    onChange={event => handleUnitChange(index, event)}/>
                                </div>       

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Initial Deposit</label>
                                    <input type="number" name="initial_deposit" id="email" placeholder="eg #2,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.initial_deposit}
                                    onChange={event => handleUnitChange(index, event)}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Total Price</label>
                                    <input type="number" name="total_price" id="email" placeholder="eg #2,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.total_price}
                                    onChange={event => handleUnitChange(index, event)}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Additional Fee</label>
                                    <input type="number" name="additional_fee" id="email" placeholder="eg #2,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.additional_fee}
                                    onChange={event => handleUnitChange(index, event)}/>
                                </div>         

                            </div>
                            </React.Fragment>
                            )
                        })}


                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50"  disabled={unitLoad} onClick={submitUnit} >
                                    {unitLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>                       
                    </div>
                </form>

                {/* <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "unit" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Unit Photo</p>
                            {accordion === "unit" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("unit")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "unit" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                                    <input type="name" name="text" id="email" placeholder="eg GCC III"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {fileName === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-uploada"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Photos</span>
                                        <input id="file-uploada" name="file-upload" type="file" onChange={(e) => handleChanges(e)} className="sr-only" accept="image/*" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500">.doc, .pnt less than 10MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{fileName}</span>  <span className="text-red-600 cursor-pointer" onClick={cancelFile}>X</span>
                                    </p>
                                </div>
                                }
                                </div>                  
                            </div>

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                    Add
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "pricing" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Unit Pricing</p>
                            {accordion === "pricing" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("pricing")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "pricing" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price Period</label>
                                    <input type="text" name="text" id="email" placeholder="6 Months"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Price</label>
                                    <input type="number" name="text" id="email" placeholder="#300,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>              
                            </div>

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                    Add
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "floor" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Unit Floor Plan</p>
                            {accordion === "floor" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("floor")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "floor" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                               
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Floor Plan Name</label>
                                    <input type="name" name="text" id="email" placeholder="egfloor plan"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Detail Name</label>
                                    <input type="name" name="text" id="email" placeholder="eg Detail Name"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Detail Size</label>
                                    <input type="name" name="text" id="email" placeholder="eg Detail Size"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>                
                            </div>

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                    Create
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form> */}

                <form onSubmit={submitVideo}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "projectVideo" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Videos</p>
                            {accordion === "projectVideo" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("projectVideo")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "projectVideo" ? "block" : "hidden"}`}>
                            <div>
                            {videoFields?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                                <div className="grid grid-cols-2 gap-x-4">
                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="projectVideo" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                                        <input type="text" name="name" id="projectVideo" placeholder="eg GCC III"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.name}
                                        onChange={event => handleVideoChange(index, event)} />
                                    </div> 

                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        <label for="videoLink" className="block px-4 pt-1 text-sm text-gray-600 ">Video Link</label>
                                        <input type="text" name="video_link" id="videoLink" placeholder="eg www.youtube.com"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={input.video_link}
                                        onChange={event => handleVideoChange(index, event)} />
                                    </div>                  
                                </div>
                            </React.Fragment>
                            )
                            })}
                                
                                
                                <div className="mt-6 flex justify-between">
                                    <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                    <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                    <span>Add More</span>
                                    </div>

                                    <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={videoLoad} onClick={submitVideo} >
                                    {videoLoad ? 'Adding...' : 'Add'}
                                    </button>
                                </div>
                            </div>
                            

                            
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitAmenity}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "amenities" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Amenities</p>
                            {accordion === "amenities" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("amenities")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "amenities" ? "block" : "hidden"}`}>
                            {inputFields?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-1 gap-x-4">
                               
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {input.name === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-uploadc"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Photos</span>
                                        <input id="file-uploadc" name="file-upload" type="file" onChange={event => handleFormChange(index, event)} className="sr-only" accept="image/*" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500">.doc, .pnt less than 10MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{input.name}</span>  <span className="text-red-600 cursor-pointer" onClick={() => handleReset(index)}>X</span>
                                    </p>
                                </div>
                                }
                                </div>                 
                            </div>
                            </React.Fragment>
                            )
                            })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submitAmenity}>
                                    {isLoading ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitLand}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "landPhotos" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Landmark Photos</p>
                            {accordion === "landPhotos" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("landPhotos")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "landPhotos" ? "block" : "hidden"}`}>
                            {landFields?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-1 gap-x-4">
                               
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {input.name === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-uploadd"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Photos</span>
                                        <input id="file-uploadd" name="file-upload" type="file" onChange={event => handleLandChange(index, event)} className="sr-only" accept="image/*" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500">Not more than 1MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{input.name}</span>  <span className="text-red-600 cursor-pointer" onClick={() => landReset(index)}>X</span>
                                    </p>
                                </div>
                                }
                                </div>                 
                            </div>
                            </React.Fragment>
                            )
                            })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={landLoad}  onClick={submitLand}>
                                    {landLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form onSubmit={submitPhoto}>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "proPhotos" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Unit Photo</p>
                            {accordion === "proPhotos" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("proPhotos")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "proPhotos" ? "block" : "hidden"}`}>
                        {photoFields?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-1 gap-x-4">
                               
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {input.name === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-uploadp"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Photos</span>
                                        <input id="file-uploadp" name="file-upload" type="file" onChange={event => handlePhotoChange(index, event)} className="sr-only" accept="image/*" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500"> less than 1MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{input.name}</span>  <span className="text-red-600 cursor-pointer" onClick={() => photoReset(index)}>X</span>
                                    </p>
                                </div>
                                }
                                </div>                 
                            </div>
                            </React.Fragment>
                            )
                            })}

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={photosLoad}  onClick={submitPhoto}>
                                    {photosLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default FillProject
import React from 'react'

const imageA = 'images/background.png'

const ProfileImg = () => {
    // Get the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);

     // Get the encoded user profile data
    const encodedProfile = urlParams.get("user-profile")
    if (encodedProfile !== null) {
        // Decode the encoded user profile data
        var userProfile = JSON.parse(decodeURIComponent(encodedProfile));

        var myArrayString = JSON.stringify(userProfile);
        localStorage.setItem('name', myArrayString);
        myArrayString = localStorage.getItem('name');
        console.log("this the local storage", myArrayString)
        userProfile = JSON.parse(myArrayString);

        // Use the user profile data as needed
        console.log(userProfile);
    }

  return (
    <>
        <div className='bg-[#FFFFFF] mt-6 rounded-xl'>

            <div className='relative'>
                <div className='w-full h-48'>
                    <div className="min-h-full rounded-t-xl" style={{
                        backgroundImage: "url('images/background.png')",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        // height: '100%',
                        // filter: 'brightness(70%)',
                        backgroundRepeat: 'no-repeat'
                    }}>   
                    </div>
                </div>
                
                <div className='flex ml-6'>
                    <div className='absolute bottom-[0.2rem]'>
                        <img src={userProfile?.user[0].employee.id_card_photo ? userProfile?.user[0].employee.id_card_photo : "images/defaultImage.png"} alt='profile' className="w-[100px] h-[100px] rounded-full border-2 border-solid border-white" />
                    </div>
                    <div className="ml-28">
                        <p>{userProfile?.user[0].employee.first_name} {userProfile?.user[0].employee.last_name}</p>
                        <p>{userProfile?.user[0].employee.role}</p>
                    </div>
                </div>
            </div>

            <div className='px-4 py-8 sm:p-8 space-y-8'>

                <div className='bg-[#F4F0EB]'>
                    <p className='p-6'>
                        {userProfile?.role.job_description}
                    </p>
                </div>

                <div className=''>
                    <h3 className='font-medium'>Other Roles</h3>
                    <div className='bg-[#F4F0EB]'>
                        <p className='p-6'>
                            is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content 
                        </p>
                    </div>
                </div>

            </div>

        </div>
    </>
  )
}

export default ProfileImg
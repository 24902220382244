import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { useEmployeeOtherDetaisMutation } from '../../reactWrapper/Redux/apiSlice';
import { v4 as uuid } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeDetail = ({setEmployeeInfo, id}) => {
  const [fileName, setFileName] = useState('')
  const [myId, setMyId] = useState(null)

  const [salary, setSalary] = useState('')
  const [currentLoanRepayment, setCurrentLoanRepayment] = useState('')
  const [department, setDepartment] = useState('')
  const [resumptionDate, setResumptionDate] = useState('')
  const [gender, setGender] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [numberOfChildren, setNumberOfChildren] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [phone, setPhone] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [numberOfDependent, setNumberOfDependent] = useState('')
  const [nationality, setNationality] = useState('')
  const [stateOfOrigin, setStateOfOrigin] = useState('')
  const [lgaOfOrigin, setLgaOfOrigin] = useState('')
  const [accountName, setAccountName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [idCardPhoto, setIdCardPhoto] = useState('')

  const [ employeeOtherDetais, {isLoading, isSuccess, error, data } ] = useEmployeeOtherDetaisMutation();



  const handleChanges = (event) => {
    setFileName(event.target.files[0].name)
    setIdCardPhoto(event.target.files[0])
  };

  const cancelFile = (e) => {
      e.preventDefault()
      setFileName('')
  }
  const format1 = "YYYY-MM-DD HH:mm:ss"
  var day = new Date(resumptionDate);
  var resume_day = moment(day).format(format1)

  var newDay = new Date(dateOfBirth);
  var birth_day = moment(newDay).format(format1)

  useEffect(() => {
    console.log("here date new", resume_day)   
  }, [resume_day])

    const unique_id = uuid();
    const uid = unique_id.slice(0,4);
    const [userId, setUserId] = useState(uid);

  const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('salary', salary);
            formData.append('current_loan_repayment', currentLoanRepayment);
            formData.append('resumption_date', resume_day);
            formData.append('gender', gender);
            formData.append('marital_status', maritalStatus);
            formData.append('number_of_children', numberOfChildren);
            formData.append('street', street);
            formData.append('city', city);
            formData.append('state', state);
            formData.append('phone', phone);
            formData.append('date_of_birth', birth_day);
            formData.append('number_of_dependent',numberOfDependent);
            formData.append('nationality', nationality);
            formData.append('state_of_origin', stateOfOrigin);
            formData.append('lga_of_origin', lgaOfOrigin);
            formData.append('account_name', accountName);
            formData.append('account_number', accountNumber);
            formData.append('id_card_photo', idCardPhoto);
            
            await employeeOtherDetais({id, formData, user: userId }).unwrap()
           console.log("This is the form data", formData)
        } catch (error) {
            toast.error(error.data.message)
            console.error("error", error.data.message)
        }
    }

    useEffect(()=> {
        console.log("here date", idCardPhoto)
    }, [idCardPhoto])

    useEffect(() => {
        if(isSuccess) {
            toast.success(data.message)
            setEmployeeInfo(false)
            console.log("details data here", data)
        }
    }, [isSuccess])

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Employees' Details</p>
                    </div> 

                    <svg
                        onClick={() => setEmployeeInfo(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                
                  
                      <div>
                          <div className="flex justify-between mb-1">
                              <p className='text-lg font-medium'>Enter Employees Details</p>
                          </div>

                          <div className="">    
                            <form onSubmit={handleSubmit}>                       
                                <div className="grid grid-cols-2 gap-x-4">
                               
                                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                        {fileName === "" ? 
                                            <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                <div className=" text-center">
                                                {/* <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    viewBox="0 0 48 48"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    />
                                                </svg> */}
                                                <div className="flex text-sm text-gray-600">
                                                    <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                                    >
                                                    <span>Upload Employees Image</span>
                                                    <input id="file-upload" name="file-upload" type="file" onChange={(e) => handleChanges(e)} className="sr-only" />
                                                    </label>
                                                    
                                                </div>
                                                
                                                </div>
                                            </div>
                                        : 
                                            <div>
                                                <p>
                                                    <span>Attached file</span> <span className="font-bold">{fileName}</span>  <span className="text-red-600 cursor-pointer" onClick={cancelFile}>X</span>
                                                </p>
                                            </div>
                                        }
                                    </div>                  

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary</label>
                                        <input type="number" name="text" id="email" placeholder="0+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={salary}
                                        onChange={(e) => setSalary(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Loan Repayment</label>
                                        <input type="number" name="text" id="email" placeholder="#20,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={currentLoanRepayment}
                                        onChange={(e) => setCurrentLoanRepayment(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Resumption Date</label>
                                        <input type="date" name="text" id="email" placeholder="02-20-10"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={resumptionDate}
                                        onChange={(e) => setResumptionDate(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Sex</label>
                                        <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}>
                                            <option selected>Choose Sex</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="intersex">Intersex</option>
                                            <option value="trans">Trans</option>
                                            <option value="non-conforming">Non Conforming</option>
                                            <option value="personal">Personal</option>
                                            <option value="eunuch">Eunuch</option>
                                        </select>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Marital Status</label>
                                        <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                                        value={maritalStatus}
                                        onChange={(e) => setMaritalStatus(e.target.value)}>
                                            <option selected>Choose Status</option>
                                            <option value="married">Married</option>
                                            <option value="single">Single</option>
                                            <option value="divorced">Divorced</option>
                                            <option value="notSay">Not to Say</option>
                                        </select>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State</label>
                                        <input type="text" name="text" id="email" placeholder="Paris"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">City</label>
                                        <input type="text" name="text" id="email" placeholder="Paris"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Street</label>
                                        <input type="text" name="text" id="email" placeholder="10 Varane Nasri"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={street}
                                        onChange={(e) => setStreet(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                                        <input type="number" name="text" id="email" placeholder="0807654345"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Birth</label>
                                        <input type="date" name="text" id="email" placeholder="02-10-20"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">No of Children</label>
                                        <input type="number" name="text" id="email" placeholder="10"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={numberOfChildren}
                                        onChange={(e) => setNumberOfChildren(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">No of Dependent</label>
                                        <input type="number" name="text" id="email" placeholder="5+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={numberOfDependent}
                                        onChange={(e) => setNumberOfDependent(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Nationality</label>
                                        <input type="text" name="text" id="email" placeholder="Island Faroe"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={nationality}
                                        onChange={(e) => setNationality(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State of Origin</label>
                                        <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={stateOfOrigin}
                                        onChange={(e) => setStateOfOrigin(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">LGA of Origin</label>
                                        <input type="text" name="text" id="email" placeholder="Eti Osa 3"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={lgaOfOrigin}
                                        onChange={(e) => setLgaOfOrigin(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">A/C Name</label>
                                        <input type="text" name="text" id="email" placeholder="Ogene Rukayatu"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={accountName}
                                        onChange={(e) => setAccountName(e.target.value)}/>
                                    </div>

                                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">A/C Number</label>
                                        <input type="number" name="text" id="email" placeholder="0099887643"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={accountNumber}
                                        onChange={(e) => setAccountNumber(e.target.value)}/>
                                    </div>

                                    {/* <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">A/C Bank</label>
                                        <input type="text" name="text" id="email" placeholder="Fedelity"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                        value={accountNumber}
                                        onChange={(e) => setAccountNumber(e.target.value)}/>
                                    </div> */}
                                </div>
                                
                                <div className="mt-6 flex justify-end">
                                    {isLoading
                                    ? <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                        Adding...
                                    </button>
                                    : <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50"
                                    onClick={handleSubmit}>
                                        Add
                                    </button>
                                    }                  
                                </div>
                            </form>
                          </div>
                          
                      </div>
                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default EmployeeDetail
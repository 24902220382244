import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useAddProjectUnitPhotosMutation, useAddProjectUnitPricingMutation } from '../../reactWrapper/Redux/apiSlice';

const AddUnit = ({id, setAddUnit}) => {
    const [accordion, setAccordion] = useState("unit")
    

    const [inputPhotos, setInputPhotos] = useState([
        {name: '', file: null}
    ])

    const [inputPeriod, setInputPeriod] = useState([
        {price_period: '', price: ''}
    ])

    const [ addProjectUnitPhotos, {isLoading, isSuccess, error, data } ] = useAddProjectUnitPhotosMutation();
    const [ addProjectUnitPricing, {isLoading: periodLoad, isSuccess: periodSuccess, error: periodError, data: periodData } ] = useAddProjectUnitPricingMutation();

    const handleFormChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...inputPhotos];
        if (name === "file-upload") {
          data[index]["file"] = files[0];
          data[index]["name"] = files[0].name.split('.')[0];
        } else {
          data[index][name] = value;
        }
        setInputPhotos(data);
    };

    const submitPhotos = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();
      
        inputPhotos.forEach((inputField) => {
            formData.append(`file`, inputField.file);
            formData.append(`name`, inputField.name);
        });
      
        try {
            await addProjectUnitPhotos({ id, dataToSubmit: formData }).unwrap();
            setInputPhotos([{name: '', file: null}])
        } catch (error) {
          toast.error(error.error);
        }
    };

    useEffect(() => {
        if(isSuccess) {
            toast.success(data.message) 
            window.location.reload();    
        }
    }, [isSuccess])

    const handleReset = (index) => {
        let data = [...inputPhotos];
        data[index]['name'] = '';
        data[index]['file'] = null;
        setInputPhotos(data);
    };

    const handlePeriodChange = (index, event) => {
        const { name, value, files } = event.target;
        const data = [...inputPeriod];        
        data[index][name] = value;
        setInputPeriod(data);
    };

    const submitPeriod = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = Object.assign({}, ...inputPeriod);
            await addProjectUnitPricing({id, dataToSubmit}).unwrap()
            setInputPeriod([{price_period: '', price: ''}])
        } catch (error) {
            toast.error(error.error)
        }       
    }  

    useEffect(() => {
        if(periodSuccess) {
            toast.success(periodData.message)
            window.location.reload();     
        }
    }, [periodSuccess])

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Project Details</p>
                    </div> 

                    <svg
                        onClick={() => setAddUnit(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "unit" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Unit Photo</p>
                            {accordion === "unit" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("unit")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "unit" ? "block" : "hidden"}`}>
                        {inputPhotos?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-1 gap-x-4">
                               
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {input.name === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Photos</span>
                                        <input id="file-upload" name="file-upload" type="file" onChange={event => handleFormChange(index, event)} className="sr-only" accept="image/*" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500"> less than 1MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{input.name}</span>  <span className="text-red-600 cursor-pointer" onClick={() => handleReset(index)}>X</span>
                                    </p>
                                </div>
                                }
                                </div>                 
                            </div>
                            </React.Fragment>
                            )
                        })}

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading} onClick={submitPhotos} >
                                    {isLoading ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "pricing" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Project Unit Pricing</p>
                            {accordion === "pricing" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("pricing")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "pricing" ? "block" : "hidden"}`}>
                        {inputPeriod?.map((input, index) => {
                            return (
                            <React.Fragment key={index}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price Period</label>
                                    <input type="text" name="price_period" id="email" placeholder="6 Months"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.price_period}
                                    onChange={event => handlePeriodChange(index, event)} />
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Price</label>
                                    <input type="number" name="price" id="email" placeholder="#300,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                                    value={input.price}
                                    onChange={event => handlePeriodChange(index, event)}/>
                                </div>              
                            </div>
                            </React.Fragment>
                            )
                        })}

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={periodLoad} onClick={submitPeriod} >
                                    {periodLoad ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "floor" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Unit Floor Plan</p>
                            {accordion === "floor" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("floor")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "floor" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                               
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Floor Plan Name</label>
                                    <input type="name" name="text" id="email" placeholder="egfloor plan"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Detail Name</label>
                                    <input type="name" name="text" id="email" placeholder="eg Detail Name"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Detail Size</label>
                                    <input type="name" name="text" id="email" placeholder="eg Detail Size"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                                </div>                
                            </div>

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                    Create
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form> 
                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default AddUnit
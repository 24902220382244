import React from 'react'
import SubscriberSide from '../components/subscriber/SubscriberSide'


const SubscriberPage = () => {
  return (
    <div className='lg:ml-[12rem] pb-10'>
        <SubscriberSide />
    </div>
  )
}

export default SubscriberPage
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { useCreateProjectMutation } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateProject = ({setShowAmount}) => {

    const [ createProject, {isLoading, isSuccess, error: subError, data: medData } ] = useCreateProjectMutation();

    const [inputFields, setInputFields] = useState({name: '', location: '', project_size: '', title: '', delivery_date: '', status: '', tag_line: '', unit_number: '', commencement_date: ''})

    const handleChange = event => {
        setInputFields({
        ...inputFields,
        [event.target.name]: event.target.value
        });
    };

    const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`name`, inputFields.name);
        formData.append(`location`, inputFields.location);
        formData.append(`project_size`, inputFields.project_size);
        formData.append(`title`, inputFields.title);
        formData.append(`delivery_date`, moment(inputFields.delivery_date).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`status`, inputFields.status);
        formData.append(`tag_line`, inputFields.tag_line);
        formData.append(`unit_number`, inputFields.unit_number);
        formData.append(`commencement_date`, moment(inputFields.commencement_date).format("YYYY-MM-DD HH:mm:ss"));
      
        try {
            await createProject(formData).unwrap();
            setInputFields({name: '', location: '', project_size: '', title: '', delivery_date: '', status: '', tag_line: '', unit_number: '', commencement_date: ''});
        } catch (error) {
          toast.error(error.error);
          console.log("the error", error)
        }
    };

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setShowAmount(false)   
        }
    }, [isSuccess])
    console.log("this sis the data", medData)

  return (
    <>
        <div className="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl">
                <div
                className="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowAmount(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            
                
                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Project</p>
                </div>
                <form>
                    <div className='grid grid-cols-2 gap-x-3'>
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                            <input type="name" id="email" placeholder="Project Name" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="name" value={inputFields.name} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Location</label>
                            <input type="name" id="email" placeholder="Project Location" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="location" value={inputFields.location} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Project Size</label>
                            <input type="name" id="email" placeholder="Project Size" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="project_size" value={inputFields.project_size} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Title</label>
                            <input type="email" id="email" placeholder="project title" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="title" value={inputFields.title} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Delivery Date</label>
                            <input type="date" id="email" placeholder="Employee FirstName" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="delivery_date" value={inputFields.delivery_date} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="countries" className="block px-4 pt-1 text-sm text-gray-600 ">Status</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                            name="status" value={inputFields.status} onChange={handleChange}>
                            <option selected>Choose a Status</option>
                            <option value="available">Available</option>
                            <option value="unavailable">Unavailable</option>
                        
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Tagline</label>
                            <input type="text" id="email" placeholder="project tag" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="tag_line" value={inputFields.tag_line} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Unit Number</label>
                            <input type="number" id="email" placeholder="23" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="unit_number" value={inputFields.unit_number} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Commencement Date</label>
                            <input type="date" id="email" placeholder="project tag" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="commencement_date" value={inputFields.commencement_date} onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default CreateProject
import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { SingleGallery, AddUnits } from '../../reactWrapper/Redux/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom'
import { useGetAllProjectDetailsQuery } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// LoadingSkeleton component
const LoadingSkeleton = () => (
    <div className="max-w-sm bg-gray-500 rounded-lg border border-gray-500 shadow-md animate-pulse">
      <div className="h-40 w-72" />
      <div className="p-5">
        <div className="mb-2 h-6 bg-gray-500" />
        <div className="flex justify-between">
          <div className="flex">
            <div className="mr-2 h-4 w-4 bg-gray-200 rounded-full" />
            <div className="h-4 bg-gray-500 rounded-full w-28" />
          </div>
  
          <div className="cursor-pointer">
            <div className="h-4 bg-gray-500 rounded-full w-24" />
          </div>
        </div>
      </div>
    </div>
);

const GallarySection = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1)
    const [view, setView] =useState(false)

    const { data: houses, isLoading: empLoading, isSuccess: empSuccess, error: allEmpErr } = useGetAllProjectDetailsQuery(page)
    
    useEffect(() => {
        if(allEmpErr) {
            toast.error(allEmpErr.error)
        }
    }, [allEmpErr])

    useEffect(() => {
        if(empSuccess) {
            toast.success(houses.message)          
        }
    }, [empSuccess, houses])


  return (
    <>
        <div className='mx-4 p-2 md:p-6'>
            <div className='flex justify-between font-bold mb-2 font-lg'>
                <p className='text-[#B39768]'>Project</p>
                {houses?.data?.data.length > 3 ? <p className='text-[#0A3347]' onClick={() => setView(true)}>See all</p> : null}
            </div>

            
            <div className="flex gap-x-6 justify-center flex-wrap"> 
            {empLoading ? (
                <LoadingSkeleton />
            ) : !houses?.data?.data ? (
                <p className="text-xl text-center p-5">No Project :(</p>
            ) : (houses && houses?.data?.data.slice(0, 3).map((items) => (        
                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md" key={items.id}>
                        <Link to="/gallery/details" className="flex-grow" onClick={() => dispatch(SingleGallery(items))}>
                            <img className="rounded-t-lg h-40 w-72 object-cover" src={items?.project_photos[0]?.file} alt="the pics" />
                        </Link>
                        <div className="p-5">
                            <Link to="/gallery/details" onClick={() => dispatch(SingleGallery(items))}>
                                <h5 className="mb-2 text-lg font-bold tracking-tight text-[#0A3347]">{items.name}</h5>
                            </Link>
                            <div className="flex justify-between">
                                <div className="flex">
                                    <i className='mr-2 text-[#9D8D79]'><FontAwesomeIcon icon={faLocation} /></i>
                                    <p>{items.location}</p>
                                </div>

                                <div className='cursor-pointer' onClick={() => dispatch(AddUnits(items))}>
                                    <Link to="/gallery/view">
                                        View Units
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
            </div>

            {view &&
            <div className="grid gap-x-6 justify-center justify-items-center grid-cols-3"> 
                {empLoading && <p className="text-xl text-center p-5">Loading...</p>} 
                {!houses?.data?.data && <p className="text-xl text-center p-5">No Project :(</p>}
                {houses && houses?.data?.data.slice(3).map((items) => (        
                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md" key={items.id}>
                        <Link to="/gallery/details" className="flex-grow" onClick={() => dispatch(SingleGallery(items))}>
                            <img className="rounded-t-lg h-40 w-72 object-cover" src={items?.project_photos[0]?.file} alt="the pics" />
                        </Link>
                        <div className="p-5">
                            <Link to="/gallery/details" onClick={() => dispatch(SingleGallery(items))}>
                                <h5 className="mb-2 text-lg font-bold tracking-tight text-[#0A3347]">{items.name}</h5>
                            </Link>
                            <div className="flex justify-between">
                                <div className="flex">
                                    <i className='mr-2 text-[#9D8D79]'><FontAwesomeIcon icon={faLocation} /></i>
                                    <p>{items.location}</p>
                                </div>

                                <div className='cursor-pointer' onClick={() => dispatch(AddUnits(items))}>
                                    <Link to="/gallery/view">
                                        View Units
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            }
            
            
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default GallarySection
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddSubscriberDocumentMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddSubscribersUnitsMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddSubscribersUnitPricesMutation } from '../../reactWrapper/Redux/apiSlice';
import { useAddSubscriberProjectMutation } from '../../reactWrapper/Redux/apiSlice';
import { useGetAllProjectDetailsQuery } from '../../reactWrapper/Redux/apiSlice';
import { useGetSubscribersQuery } from '../../reactWrapper/Redux/apiSlice';

const FillDetails = ({id, setIndividualDetails}) => {
    const [inputFields, setInputFields] = useState({document_name: '', file: null, fileName: ''})
    const [inputUnit, setInputUnit] = useState({amount_paid: '', balance: '', amount: '', additional_fee: '', total_price: '', name: '', initial_deposit: '', additional_fee_component: ''})
    const [inputPeriod, setInputPeriod] = useState({price_period: '', price: ''})
    const [inputProject, setInputProject] = useState({subscriberId: '', projectId: ''})

    const [accordion, setAccordion] = useState("project")
    const [ addSubscriberDocument, {isLoading, isSuccess, error, data: medData } ] = useAddSubscriberDocumentMutation();
    const [ addSubscribersUnits, {isLoading: unitload, isSuccess: unitSuccess, error: unitError, data: unitData } ] = useAddSubscribersUnitsMutation();
    const [ addSubscribersUnitPrices, {isLoading: periodload, isSuccess: periodSuccess, error: periodError, data: periodData } ] = useAddSubscribersUnitPricesMutation();
    const [ addSubscriberProject, {isLoading: projectload, isSuccess: projectSuccess, error: projectError, data: projectData } ] = useAddSubscriberProjectMutation();

    const { data: project } = useGetAllProjectDetailsQuery(1)
    const { data: sub } = useGetSubscribersQuery(1)
    console.log("here is the project data", project);
    console.log("here is the sub data", sub);

    const handleChanges = (event) => {
        if (event.target.type === "file") {
            setInputFields({
              ...inputFields,
              file: event.target.files[0],
              fileName: event.target.files[0].name
            });
          } else {
            setInputFields({
              ...inputFields,
              [event.target.name]: event.target.value
            });
        }
    };

    const handleReset = () => {
        setInputFields({
            ...inputFields,
            document_name: '',
            document: null,
            fileName: ''
          });
      };

    const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`file`, inputFields.file);
        formData.append(`document_name`, inputFields.document_name);
      
        try {
            await addSubscriberDocument({ id, dataToSubmit: formData }).unwrap();
          setInputFields({document_name: '', file: null, fileName: ''});
        } catch (error) {
          toast.error(error.error);
        }
    }
    
    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)   
        }
    }, [isSuccess])

      const unitChanges = (event) => {
        setInputUnit({
            ...inputUnit,
            [event.target.name]: event.target.value
        });
      };
    
      const submitUnit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`amount_paid`, inputUnit.amount_paid);
        formData.append(`balance`, inputUnit.balance);
        formData.append(`amount`, inputUnit.amount);
        formData.append(`additional_fee`, inputUnit.additional_fee);
        formData.append(`total_price`, inputUnit.total_price);
        formData.append(`name`, inputUnit.name);
        formData.append(`initial_deposit`, inputUnit.initial_deposit);
        formData.append(`additional_fee_component`, inputUnit.additional_fee_component);
        try {
            await addSubscribersUnits({ id, dataToSubmit: formData }).unwrap();
          setInputUnit({amount_paid: '', balance: '', amount: '', additional_fee: '', total_price: '', name: '', initial_deposit: '', additional_fee_component: ''});
        } catch (error) {
          toast.error(error.error);
        }
      }

      useEffect(() => {
        if(unitSuccess) {
            toast.success(unitData.message)   
        }
    }, [unitSuccess])

    const periodChanges = (event) => {
        setInputPeriod({
            ...inputPeriod,
            [event.target.name]: event.target.value
        });
      };

      const submitPeriod = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`price_period`, inputPeriod.price_period);
        formData.append(`price`, inputPeriod.price);

        try {
            await addSubscribersUnitPrices({ id, dataToSubmit: formData }).unwrap();
            setInputPeriod({price_period: '', price: ''});
        } catch (error) {
          toast.error(error.error);
        }
      }

      useEffect(() => {
        if(periodSuccess) {
            toast.success(periodData.message)   
        }
    }, [periodSuccess])

    const projectChanges = (event) => {
        setInputProject({
            ...inputProject,
            [event.target.name]: event.target.value
        });
    };

    const submitProject = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        // formData.append(`subscriberId`, id);
        formData.append(`projectId`, inputProject.projectId);

        try {
            await addSubscriberProject({ id, dataToSubmit: formData }).unwrap();
            setInputProject({projectId: ''});
        } catch (error) {
          toast.error(error.error);
        }
      }

    useEffect(() => {
        if(projectSuccess) {
            toast.success(projectData.message)   
        }
    }, [projectSuccess])

    
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Subscriber Details</p>
                    </div> 

                    <svg
                        onClick={() => setIndividualDetails(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                
                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "project" ? "" : "shadow-lg shadow-black p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Subscriber Project</p>
                            {accordion === "project" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("project")}}
                            ><FontAwesomeIcon icon={faArrowDown}  className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "project" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                               
                            <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                           
                                <label for="department" className="block px-4 pt-1 text-sm text-gray-600">Project</label>
                                <select id="department" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                                 onChange={projectChanges} name="projectId"
                                >
                                <option selected="">Choose a Project</option>
                                {
                                    project?.data?.data.map((name) => (
                                        <option value={name.id} key={name.id}>{name.name}</option>
                                    ))
                                }
                                </select>
                            </div>

                            {/* <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                           
                                <label for="department" className="block px-4 pt-1 text-sm text-gray-600">Subscriber</label>
                                <select id="department" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                                 onChange={projectChanges} name="subscriberId"
                                >
                                <option selected="">Choose a Subscriber</option>
                                {
                                    sub?.data?.data.map((name) => (
                                        <option value={name.id} key={name.id}>{name.firstname} {name.surname}</option>
                                    ))
                                }
                                </select>
                            </div> */}
                                

                            </div>
                            
                            
                            

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={projectload}  onClick={submitProject}>
                                    {projectload ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>                       
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "doc" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Subscriber Attachment</p>
                            {accordion === "doc" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("doc")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "doc" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-1 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                {inputFields.fileName === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Subcribers Document</span>
                                        <input id="file-upload" type="file" name="file" value={inputFields.file} onChange={handleChanges} className="sr-only" />
                                        </label>
                                        
                                    </div>
                                    <p className="text-xs text-gray-500">.doc, .pnt less than 1MB</p>
                                    </div>
                                </div>
                                : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{inputFields.fileName}</span>  <span className="text-red-600 cursor-pointer" onClick={handleReset}>X</span>
                                    </p>
                                </div>
                                }
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Document Name</label>
                                    <input type="text" id="email" placeholder="name.pdf"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="document_name" value={inputFields.document_name} onChange={handleChanges}/>
                                </div>                 
                            </div>

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                                    {isLoading ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "unit" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Subscriber Unit</p>
                            {accordion === "unit" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("unit")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "unit" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Amount Paid</label>
                                    <input type="number" id="email" placeholder="#30,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="amount_paid" value={inputUnit.amount_paid} onChange={unitChanges}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Balance</label>
                                    <input type="number" id="email" placeholder="#300,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="balance" value={inputUnit.balance} onChange={unitChanges}/>
                                </div>    

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Amount</label>
                                    <input type="number" id="email" placeholder="#3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="amount" value={inputUnit.amount} onChange={unitChanges}/>
                                </div>  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Additional Fee</label>
                                    <input type="number" id="email" placeholder="#3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="additional_fee" value={inputUnit.additional_fee} onChange={unitChanges}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Total Price</label>
                                    <input type="number" id="email" placeholder="#3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="total_price" value={inputUnit.total_price} onChange={unitChanges}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                                    <input type="name" id="email" placeholder="eg GCC III"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="name" value={inputUnit.name} onChange={unitChanges}/>
                                </div>        

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Additional Fee Components</label>
                                    <input type="number" id="email" placeholder="#3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold"  name="additional_fee_component" value={inputUnit.additional_fee_component} onChange={unitChanges}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Initial Deposit</label>
                                    <input type="number" id="email" placeholder="#3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="initial_deposit" value={inputUnit.initial_deposit} onChange={unitChanges}/>
                                </div>               

                            </div>

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={unitload}  onClick={submitUnit}>
                                    {unitload ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "price" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Subscriber Price</p>
                            {accordion === "price" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("price")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "price" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price Period</label>
                                    
                                    <input type="text" id="email" placeholder="eg 3 Months" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="price_period" value={inputPeriod.price_period} onChange={periodChanges}/>
                                </div> 

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price</label>
                                    <input type="number" id="email" placeholder="eg #300,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="price" value={inputPeriod.price} onChange={periodChanges}/>
                                </div>                
                            </div>

                            <div className="mt-6 flex justify-end">
                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={periodload}  onClick={submitPeriod}>
                                    {periodload ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default FillDetails
import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEditEmployeeMedicalsMutation } from '../../reactWrapper/Redux/apiSlice';

const EditMedicals = ({editData, id, setShowMedModal}) => {

    const [inputFields, setInputFields] = useState({
        date_of_last_medical_checkup: '', hospital_conducted: '', genotype: '', bloodgroup: '', reason_for_checkup: '', document: null, document_name: ''
    })

    const [ editEmployeeMedicals, {isLoading, isSuccess, error, data: medData } ] = useEditEmployeeMedicalsMutation();

    useEffect(() => {
        const fileName = editData?.file.substring(editData?.file.lastIndexOf('/') + 1);
        const updatedFileName = fileName.replace('.pdf.pdf', '.pdf');

        setInputFields({
          date_of_last_medical_checkup: editData?.date_of_last_medical_checkup,
          hospital_conducted: editData?.hospital_conducted,
          genotype: editData?.genotype,
          bloodgroup: editData?.bloodgroup,
          reason_for_checkup: editData?.reason_for_checkup,
          document: editData?.file,
          document_name: updatedFileName
        });
      }, [editData]);

      const handleChange = event => {
        if (event.target.type === "file") {
          setInputFields({
            ...inputFields,
            document: event.target.files[0],
            document_name: event.target.files[0].name
          });
        } else {
          setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value
          });
        }
      };
      
      const handleReset = () => {
        setInputFields({
            ...inputFields,
            document_name: '',
            document: null
          });
      };

        // const submit = async (e) => {
        //     e.preventDefault();
        //     try {
        //         let dataToSubmit = {...inputFields};
        //         if (dataToSubmit.date_of_last_medical_checkup) {
        //         dataToSubmit = {...dataToSubmit, date_of_last_medical_checkup: moment(dataToSubmit.date_of_last_medical_checkup).format("YYYY-MM-DD HH:mm:ss")};
        //         }
        //         await editEmployeeMedicals({id, dataToSubmit}).unwrap()
        //         setInputFields({date_of_last_medical_checkup: '', hospital_conducted: '', genotype: '', bloodgroup: '', reason_for_checkup: '', document: null, document_name: ''})
        //     } catch (error) {
        //         toast.error(error.error)
        //     }       
        // }

        const submit = async (event) => {
            event.preventDefault();
          
            const formData = new FormData();          
            formData.append(`date_of_last_medical_checkup`, moment(inputFields.date_of_last_medical_checkup).format("YYYY-MM-DD HH:mm:ss"));
            formData.append(`hospital_conducted`, inputFields.hospital_conducted);
            formData.append(`genotype`, inputFields.genotype);
            formData.append(`bloodgroup`, inputFields.bloodgroup);
            formData.append(`reason_for_checkup`, inputFields.reason_for_checkup);
            formData.append(`document`, inputFields.document);
            formData.append(`document_name`, inputFields.document_name);
          
            try {
                await editEmployeeMedicals({ id, dataToSubmit: formData }).unwrap();
              setInputFields(
                {
                  date_of_last_medical_checkup: "",
                  hospital_conducted: "",
                  genotype: "",
                  bloodgroup: "",
                  reason_for_checkup: "",
                  document: null,
                  document_name: ""
                }
              );
            } catch (error) {
              toast.error(error.error);
            }
          };
    
        useEffect(() => {
            if(isSuccess) {
                toast.success(medData.message)  
                setShowMedModal(false)
                window.location.reload();   
            }
        }, [isSuccess])

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowMedModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Medical Field</p>
                </div>
                <form onSubmit={submit}>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Last Checkup</label>
                            <input type="date" name="date_of_last_medical_checkup" value={inputFields.date_of_last_medical_checkup && moment(inputFields.date_of_last_medical_checkup).format("YYYY-MM-DD")} id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" onChange={handleChange}/>
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Hospital</label>
                            <input type="name" name="hospital_conducted" id="email" placeholder="eg Maryland General Teaching"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" value={inputFields.hospital_conducted} onChange={handleChange} />
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Genotype</label>
                            <input type="email" name="genotype" id="email" placeholder="AA"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" value={inputFields.genotype} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Blood Group</label>
                            <input type="text" name="bloodgroup" id="email" placeholder="O+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" value={inputFields.bloodgroup} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            {inputFields.document_name === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className=" text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Hospital File</span>
                                        <input id="file-upload" 
                                        accept=".pdf,.doc" name="document" data-name="document_name" type="file" onChange={handleChange} className="sr-only" />
                                        </label>
                                        
                                    </div>
                                    
                                    </div>
                                </div>
                            : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{inputFields.document_name}</span> <span className="text-red-700 ml-4"
                                        onClick={handleReset}>X</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Your message</label>
                        <textarea id="message" rows="4" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="reason_for_checkup" value={inputFields.reason_for_checkup} onChange={handleChange} placeholder="Write your thoughts here..."></textarea>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Editing...' : 'Edit'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default EditMedicals
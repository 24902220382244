import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CreateSub from './CreateSub';
import SubDetails from './SubDetails';
import FullDetails from './FullDetails';
import FillDetails from './FillDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetSubscribersQuery } from '../../reactWrapper/Redux/apiSlice';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Subscribers'],
  datasets: [
    {
      label: '# of Votes',
      data: [9, 20],
      backgroundColor: [
        'rgb(128,128,128)',
        'rgba(255, 99, 132, 0.2)',
        
      ],
      borderColor: [
        'rgb(128,128,128)',
        'rgba(255, 99, 132, 1)',
        
      ],
      borderWidth: 1,
    },
  ],
};

const SubscriberSide = () => {

  const[state, setState] = useState([
    {
        id: 1,
        firstName: "Segun",
        lastName: "Warri-Boy",
        oherName: "Urugbo-Boy",
        email: "warriboy@gmail.com",
        dob: "02-20-22",
        nationality: "Nigeria",
        phone: "08076543255",
        info : ([
            {               
                id: 1,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    },
    {
        id: 2,
        firstName: "Segun",
        lastName: "Warri-Boy",
        oherName: "Urugbo-Boy",
        email: "warriboy@gmail.com",
        dob: "02-20-22",
        nationality: "Nigeria",
        phone: "08076543255",
        info : ([
            {               
                id: 1,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    },
    {
        id: 3,
        firstName: "Segun",
        lastName: "Warri-Boy",
        oherName: "Urugbo-Boy",
        email: "warriboy@gmail.com",
        dob: "02-20-22",
        nationality: "Nigeria",
        phone: "08076543255",
        info : ([
            {               
                id: 1,
                unitId: "233444",
                totalAmount: "#2,000,000",
                totalPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                unitId: "233444",
                totalAmount: "#2,000,000",
                totalPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                unitId: "233444",
                totalAmount: "#2,000,000",
                totalPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    },
    {
        id: 4,
        firstName: "Tunde",
        lastName: "Warri-Boy",
        oherName: "Urugbo-Boy",
        email: "warriboy@gmail.com",
        dob: "02-20-22",
        nationality: "Nigeria",
        phone: "08076543255",
        info : ([
            {               
                id: 1,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    }
])

  const [showModal, setShowModal] = useState(false);
  const [activeObject, setActiveObject] = useState(null);
  const [receiptObject, setReceiptObject] = useState(null);
  const [showAmount, setShowAmount] = useState(false)
  const [amount, setAmount] = useState(null)
  const [individualDetails, setIndividualDetails] = useState(false)
  const [employeeInfo, setEmployeeInfo] = useState(false)
  const [id, setId] = useState(false)

  const [page, setPage] = useState(1)
  const { data: sub, isLoading: subLoading, isSuccess: subSuccess, error: subEmpErr } = useGetSubscribersQuery(page)
  console.log("sub data", sub)

    useEffect(() => {
        if(subEmpErr) {
            toast.error(subEmpErr.error)
        }
    }, [subEmpErr])

    useEffect(() => {
        if(subSuccess) {
            toast.success(sub.message)
            setShowAmount(false)
        }
    }, [subSuccess, sub])

  return (
    <>
        <div className='mt-6 rounded-xl mx-4 bg-[#FFFFFF]'>
            <div className='flex flex-col gap-y-12 p-2 md:p-6'>
                <div className="flex flex-col gap-y-6">
                    <div>
                        <p className="text-lg font-bold">Subscribers</p>
                    </div> 
                </div>            

                <div>
                    <div className='flex justify-end ml-auto mb-3'>
                        <button className='bg-[#E2B682] p-2 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50' onClick={() => setShowAmount(true)}>
                            Create
                        </button>
                    </div>

                    <div className="overflow-x-auto" id="document">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-white uppercase bg-[#0A3347]">
                                <tr>
                                    {/* <th scope="col" className="w-4 p-4">
                                        <div class="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2" />
                                            <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                        </div>
                                    </th> */}
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        First Name
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        last Name
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Other Names
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Email
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Referral Code
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Residential Address
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Phone
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        See Details
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Add
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {subLoading && <p className="text-xl text-center p-5">Loading...</p>}
                            {!sub?.data?.data && <p className="text-xl text-center p-5">No posts :(</p>}
                            {sub && sub?.data?.data.map((items) => (
                                <tr
                                className="bg-white border-b  hover:bg-gray-50 even:bg-[#F4F0EB]" 
                                key={items.id}>
                                    {/* <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 " />
                                            <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                        </div>
                                    </td> */}
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.firstname}
                                    </th>
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.surname}
                                    </th>
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items.othernames}
                                    </th>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.user.email}
                                    </td>
                                    <td className='px-10 py-4 text-center whitespace-nowrap'>
                                    
                                        {items?.referral_code}
                                                                    
                                    </td>
                                    <td className='px-10 py-4 text-center whitespace-nowrap'>
                                    
                                        {items?.residential_address}
                                                                    
                                    </td>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.phone}
                                    </td>
                                    <td className="px-10 py-4 text-center text-[#0A3347] cursor-pointer" onClick={() => {
                                        setActiveObject(items);
                                        setShowModal(true);
                                    }}>
                                        view
                                    </td>
                                
                                    <td className="flex px-6 py-4 text-right cursor-pointer">
                                        <i 
                                        onClick={() => {
                                            setReceiptObject(items);
                                            setEmployeeInfo(true);
                                            setId(items.id)
                                        }}
                                        data-tip="Employee Record"
                                        className='p-2 bg-[#FFE8B4] rounded-lg'><FontAwesomeIcon icon={faReceipt} /></i>
                                        <i 
                                        onClick={() => {
                                            setAmount(items);
                                            setIndividualDetails(true);
                                            setId(items.id)
                                        }}
                                        data-tip="Individual Details"
                                        className='py-2 ml-4'><FontAwesomeIcon icon={faPencil} /></i>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex gap-x-2 justify-end">
                    <button className='bg-white py-2 px-8 rounded-lg text-black transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-white hover:bg-[#E2B682]  focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50 font-bold'>
                        Prev
                    </button>

                    <button className='bg-[#E2B682] py-2 px-8 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50 font-bold'>
                        Next
                    </button>
                </div>

                <div className='flex flex-wrap justify-around items-center'>
                        <div className='shadow-lg shadow-black p-4 rounded-lg h-fit'>
                            <div className="">
                                <div className='flex justify-between'>
                                    <i className='text-blue-600'><FontAwesomeIcon icon={faChartPie} /></i>

                                    <p className="py-[0.15rem] px-2 rounded-2xl bg-green-600 text-white">30%</p>
                                </div>
                                <div className='font-semibold text-xl'>4.150</div>
                                <div>No of Subscribers</div>
                            </div>
                        </div>

                        {/* <div className='shadow-lg shadow-black p-2 rounded-lg relative'>
                            <div className='flex justify-between w-fit'>
                                <Doughnut data={data} />
                            </div>
                            
                        </div> */}
                </div>
            </div>
        </div>

        {individualDetails ? <FillDetails setIndividualDetails={setIndividualDetails} id={id}/> : null}
        {showAmount ? <CreateSub setShowAmount={setShowAmount} /> : null}
        {employeeInfo ?  <SubDetails setEmployeeInfo={setEmployeeInfo} id={id}/> : null}
        {showModal ? <FullDetails showModal={setShowModal} data= {activeObject} /> : null}

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </>
  )
}

export default SubscriberSide
import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faAdd } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SubUnit from './SubUnit';
import UnitPrice from './UnitPrice';

const SubProject = ({showModal, data}) => {
    console.log("ere", data);
    const [unitData, setUnitData] = useState(null)
    const [showUnit, setShowUnit] = useState(false)
    const [unitshow, setUnitShow] = useState(false)
    const [unitId, setUnitId] = useState(null)
    const [showMedRecord, setShowMedRecord] = useState(false)
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>View Subscribers Details</p>
                    </div> 

                    <svg
                        onClick={() => showModal(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                
                    <div className='bg-white px-4 mt-4' >
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Project</h2>
                            <div className='flex my-1'>
                                

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {setShowMedRecord(true);}}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-2'>

                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{data.project?.name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{data.project?.title}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Location</p>
                                <p>{data.project?.location}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Commencement Date</p>
                                <p>{data.project?.commencement_date ? moment(data.project?.commencement_date).format("YYYY-MM-DD") : "null"}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Delivery Date</p>
                                <p>{data.project?.delivery_date ? moment(data.project?.delivery_date).format("YYYY-MM-DD") : "null"}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Location</p>
                                <p>{data.project?.project_size}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Status</p>
                                <p>{data.project?.status}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Tag Line</p>
                                <p>{data.project?.tag_line}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Unit Number</p>
                                <p>{data.project?.unit_number}</p>
                            </div>

                        </div>
                    </div>
                    

                    {data?.subscriber_unit.map(project => (
                    <div className='bg-white px-4 mt-4' key={project.id} >
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Unit</h2>
                            <div className='flex my-1'>
                                

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {
                                        setShowUnit(true);
                                        setUnitData(project);
                                    }}  className="cursor-pointer"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {setShowMedRecord(true);}}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faAdd} onClick={() => {setUnitShow(true); setUnitId(project.id)}}  className="cursor-pointer"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-2'>
                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{project?.name}</p>
                            </div>                           


                            <div className="">
                                <p className="font-semibold">Total Amount Paid</p>
                                <p>{project?.total_amount_paid}</p>
                            </div>


                        </div>
                    </div>
                    ))}

                    

                </div>
            </div>
        </div>

        {showUnit ? <SubUnit setShowUnit={setShowUnit} data={unitData} /> : null}
        {unitshow ? <UnitPrice id={unitId} setIndividualDetails={setUnitShow} /> : null}
    </>
  )
}

export default SubProject
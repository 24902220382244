import { createSlice } from "@reduxjs/toolkit";

// Get the URL query parameters
const urlParams = new URLSearchParams(window.location.search);

// Get the encoded user profile data
const encodedProfile = urlParams.get("user-profile")
if (encodedProfile !== null) {
   // Decode the encoded user profile data
   var userProfile = JSON.parse(decodeURIComponent(encodedProfile));

   var myArrayString = JSON.stringify(userProfile);
   localStorage.setItem('name', myArrayString);
}

myArrayString = localStorage.getItem('name');
const userDetails = JSON.parse(myArrayString);


const initialState = {
    users: userDetails,
    token: userDetails?.token,
    role: userDetails?.role?.name,
    galleryDetails: null,
    units: null, 
    photo: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      SingleGallery: (state, {payload}) => {
        state.galleryDetails = payload
      },
      AddUnits: (state, {payload}) => {
        state.units = payload
      },
      AddPhoto: (state, {payload}) => {
        state.photo = payload
      }
    }
});//fen

export const { SingleGallery, AddUnits, AddPhoto } = userSlice.actions; //fen
export default userSlice.reducer
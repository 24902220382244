import React from 'react'
import EmployeeSide from '../components/employee/EmployeeSide'


const EmployeePage = () => {
  return (
    <div className='lg:ml-[12rem] pb-10'>
        <EmployeeSide />
    </div>
  )
}

export default EmployeePage
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddSubscribersUnitsMutation, useGetAllProjectUnitsByProjectIdQuery } from '../../reactWrapper/Redux/apiSlice';


const AddProject = ({id, subId, setIndividualDetails}) => {
    const [inputUnit, setInputUnit] = useState({amount: '', proj_unit_id: null})
    

    const [accordion, setAccordion] = useState("unit")
    const [ addSubscribersUnits, {isLoading: unitload, isSuccess: unitSuccess, error: unitError, data: unitData } ] = useAddSubscribersUnitsMutation();
    const { data } = useGetAllProjectUnitsByProjectIdQuery(id)
     

      const unitChanges = (event) => {
        setInputUnit({
            ...inputUnit,
            [event.target.name]: event.target.value
        });
      };
    
      const submitUnit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`amount`, inputUnit.amount);
        
        try {
            await addSubscribersUnits({ id, subId, unitId: inputUnit.proj_unit_id, dataToSubmit: formData }).unwrap();
          setInputUnit({ amount: ''});
        } catch (error) {
          toast.error(error.error);
        }
      }

      useEffect(() => {
        if(unitSuccess) {
            toast.success(unitData.message)   
        }
    }, [unitSuccess])

    
    
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>Add Subscriber Details</p>
                    </div> 

                    <svg
                        onClick={() => setIndividualDetails(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50 space-y-6">
                <form>
                    <div>
                        <div className={`flex justify-between mb-1 ${accordion === "unit" ? "" : "shadow-lg shadow-black  p-4 rounded-2xl"}`}>
                            <p className='text-lg font-medium'>Subscriber Unit</p>
                            {accordion === "unit" ?
                                <i
                            onClick={() => {setAccordion("")}}
                            ><FontAwesomeIcon icon={faMinus}  className="cursor-pointer"/></i>
                             :
                             <i
                            onClick={() => {setAccordion("unit")}}
                            ><FontAwesomeIcon icon={faArrowDown} className="cursor-pointer"/></i>
                            }
                        </div>

                        <div className={`${accordion === "unit" ? "block" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-x-4">  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                                    <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Amount</label>
                                    <input type="number" id="email" placeholder="#3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" name="amount" value={inputUnit.amount} onChange={unitChanges}/>
                                </div>                                                                  

                                <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                                    <label for="countries" className="block px-4 pt-1 text-sm text-gray-600 ">Unit Project</label>                                 
                                    <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                                    value={inputUnit.proj_unit_id}
                                    name="proj_unit_id"
                                    onChange={unitChanges}>
                                    <option selected>Choose a Unit</option>
                                    {data && data?.data?.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                        )                                      
                                    )}
                                    </select>
                                </div>               

                            </div>

                            <div className="mt-6 flex justify-between">
                                <div className="w-fit px-4 py-3 tracking-wide text-black transition-colors duration-300 transform rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                                <i className='mr-2'><FontAwesomeIcon icon={faCirclePlus} /></i> 
                                <span>Add More</span>
                                </div>

                                <button className="w-fit px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={unitload}  onClick={submitUnit}>
                                    {unitload ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>

                

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default AddProject
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import DeptModal from '../modal/DeptModal';
import EditDept from '../modal/EditDept';
import { useGetDepartmentsQuery } from '../../../reactWrapper/Redux/apiSlice';
import { useDeleteDepartmentMutation } from '../../../reactWrapper/Redux/apiSlice';

    const DeptPage = () => {
    

    const [ShowModal, setShowModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [myId, setMyId] = useState(null)

    const {
        data: posts,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDepartmentsQuery()
    console.log("this is the post here", posts)

    const [ deleteDepartment, { isLoading: delLoading } ] = useDeleteDepartmentMutation()

  return (
    <>
        <div className='flex flex-col justify-center'>
            <div className=''>
                <div className='flex justify-end ml-auto mb-3'>
                    <button className='bg-[#E2B682] p-2 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50' onClick={() => setShowModal(true)}>
                        Create
                    </button>
                </div>
            </div>
            <div className='shadow-xl shadow-black divide-y'>
                {isLoading && <p className="text-xl text-center p-5">Loading...</p>}
                {posts?.length >= 1 
                    ?
                    posts?.map(items => (
                    
                    <div className='flex justify-between p-4' key={items.id}>
                        <div className='text-lg font-bold italic'>
                            {items.name}
                        </div>

                        <div className='flex justify-around'>
                            <div className='mr-2 h-8 w-9 bg-yellow-500 flex rounded-full'>
                                <p className='my-auto mx-auto' onClick={() => setMyId(items.id)}>
                                    <i
                                    onClick={() => setEdit(true)}
                                    ><FontAwesomeIcon icon={faPencil} className='text-white cursor-pointer' /></i>
                                </p>
                            </div>

                            <div className='h-8 w-9 bg-red-500 flex rounded-full'>
                                <p className='my-auto mx-auto'>
                                    <i
                                    onClick={()=> deleteDepartment(items.id )}
                                    ><FontAwesomeIcon icon={faTrash} className='cursor-pointer' /></i>
                                </p>
                            </div>
                        </div>
                    </div>
                ))
                    :
                    <p className="text-xl text-center p-5">No Department created yet</p>
                }
            </div>
        </div>

        {ShowModal ? <DeptModal setShowModal={setShowModal}/> : null }
        {edit ? <EditDept setEdit={setEdit} myId={myId}/> : null }
        
    </>
  )
}

export default DeptPage
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import MatModal from '../modal/MatModal';
import EditMat from '../modal/EditMat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetMaterialQuery } from '../../../reactWrapper/Redux/apiSlice';

const MatPage = () => {

    const [state, setState] = useState([
        {
            id: 1,
            field: 'Check Procurement'
        }
    ])

    const {
        data: posts,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMaterialQuery()

    const [ShowModal, setShowModal] = useState(false)
    const [edit, setEdit] = useState(false)

  return (
    <>
        <div className='flex flex-col justify-center'>
            <div className=''>
                <div className='flex justify-end ml-auto mb-3'>
                    <button className='bg-[#E2B682] p-2 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50' onClick={() => setShowModal(true)}>
                        Create
                    </button>
                </div>
            </div>
            <div className='shadow-xl shadow-black divide-y'>
                {isLoading && <p className="text-xl text-center p-5">Loading...</p>}
                {posts?.data?.length >= 1 
                    ?
                    posts?.data?.map(items => (
                    <div className='flex justify-between p-4' key={items.id}>
                        <div className='text-lg font-bold italic'>
                            {items.name}
                        </div>

                        {/* <div className='flex justify-around'>
                            <div className='mr-2 h-8 w-9 bg-yellow-500 flex rounded-full'>
                                <p className='my-auto mx-auto'>
                                    <i
                                    onClick={() => setEdit(true)}
                                    ><FontAwesomeIcon icon={faPencil} className='text-white cursor-pointer' /></i>
                                </p>
                            </div>

                            <div className='h-8 w-9 bg-red-500 flex rounded-full'>
                                <p className='my-auto mx-auto'>
                                    <i><FontAwesomeIcon icon={faTrash} className='cursor-pointer' /></i>
                                </p>
                            </div>
                        </div> */}
                    </div>
                ))
                :
                <p className="text-xl text-center p-5">No Material created yet</p>
            }
                
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        {ShowModal ? <MatModal setShowModal={setShowModal}/> : null }
        {edit ? <EditMat setEdit={setEdit}/> : null }
        
    </>
  )
}

export default MatPage
import React, { useState }  from 'react'
import ContractPage from './main/ContractPage'
import DeptPage from './main/DeptPage'
import MatPage from './main/MatPage'
import RolesPage from './main/RolesPage'
import SupplierPage from './main/SupplierPage' 

const CreateSide = () => {

    const [activeTab, setActiveTab] = useState("tab1");

  return (
    <div className='mt-6 rounded-xl mx-4 bg-[#FFFFFF]'>
            <div className='flex flex-col gap-y-12 p-2 md:p-6'>
                <div className="flex flex-col gap-y-6">
                    <div>
                        <p className="text-lg font-bold">Create</p>
                    </div> 
                </div>

                <div>
                <nav class="bg-grey-light rounded-md w-full">
                    <ol class="list-reset flex">
                        <li onClick={() => setActiveTab("tab1")}>
                            <p className={`${activeTab === "tab1" ? "text-blue-600 hover:text-blue-700 cursor-pointer" : "text-black hover:text-blue-700 cursor-pointer"} `}>Roles</p>
                        </li>
                        <li><span className="text-gray-500 mx-2"> &#62; </span></li>
                        <li onClick={() => setActiveTab("tab2")}>
                            <p className={`${activeTab === "tab2" ? "text-blue-600 hover:text-blue-700 cursor-pointer" : "text-black hover:text-blue-700 cursor-pointer"} `}>Department</p>
                        </li>
                        <li><span className="text-gray-500 mx-2"> &#62; </span></li>

                        <li 
                        onClick={() => setActiveTab("tab3")}
                        className={`${activeTab === "tab3" ? "text-blue-600 hover:text-blue-700 cursor-pointer" : "text-black hover:text-blue-700 cursor-pointer"} `}>Materials</li>
                        <li><span className="text-gray-500 mx-2"> &#62; </span></li>

                        <li 
                        onClick={() => setActiveTab("tab5")}
                        className={`${activeTab === "tab5" ? "text-blue-600 hover:text-blue-700 cursor-pointer" : "text-black hover:text-blue-700 cursor-pointer"} `}>Suppliers</li>
                        <li><span className="text-gray-500 mx-2"> &#62; </span></li>

                        <li 
                        onClick={() => setActiveTab("tab4")}
                        className={`${activeTab === "tab4" ? "text-blue-600 hover:text-blue-700 cursor-pointer" : "text-black hover:text-blue-700 cursor-pointer"} `}>Contractors</li>
                    </ol>
                </nav>
                </div>

                <div className="outlet">
                    {/* content will be shown here */}
                    {activeTab === "tab1" ? <RolesPage /> : (activeTab === "tab2" ? <DeptPage />  : (activeTab === "tab3" ? <MatPage /> :(activeTab === "tab5" ? <SupplierPage /> : (activeTab === "tab4" ? <ContractPage /> : null ) )))}
                </div>
                

            </div>
        </div>
  )
}

export default CreateSide
import React from 'react'

const InternalRequest = ({setShowModal} ) => {
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
        <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Send Request</p>
                </div>
                <form>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Item</label>
                        <input type="text" name="text" id="email" placeholder="eg Pizza"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Quantity</label>
                        <input type="number" name="text" id="email" placeholder="eg 23"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price</label>
                        <input type="number" name="text" id="email" placeholder="eg #200,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>                  

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date Bought</label>
                        <input type="date" name="text" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Unit Price</label>
                        <input type="number" name="text" id="email" placeholder="eg #3,000,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Purchaser Name</label>
                        <input type="name" name="text" id="email" placeholder="eg Mr Ugo chukwu Owner of we the best music"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">A/C No</label>
                        <input type="number" name="text" id="email" placeholder="eg 239874554"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Acct Name</label>
                        <input type="name" name="text" id="email" placeholder="Bridget Magg"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Suppliers Phone Number</label>
                        <input type="number" name="text" id="email" placeholder="eg 08023454323"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                        <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Bank</label>
                        <input type="text" name="text" id="email" placeholder="eg KeyStone Bank"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" onClick={() => setShowModal(false)}>
                            Send
                        </button>

                        <button className="mt-4 w-full px-4 py-3 tracking-wide text-black transition-colors duration-300 transform bg-white rounded-md hover:bg-[#E2B682] hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" onClick={() => setShowModal(false)}>
                            Cancel
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    </>
  )
}

export default InternalRequest
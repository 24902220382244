import React, {useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'; 
import Slider from 'react-slick';
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import YouTube from '../modal/YouTube';
import "swiper/swiper-bundle.css";

// install Navigation module
SwiperCore.use([Navigation]);


const GalleryDeSection = () => {

    const { galleryDetails } = useSelector((store) => store.user);
    console.log("the gallery details", galleryDetails)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [anotherIndex, setAnotherIndex] = useState(0)
    const sliderRef = useRef(null);
    const [swiper, setSwiper] = React.useState(null);
    const [video, setVideo] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const images = [
        { src: 'https://source.unsplash.com/random/1', alt: 'Image 1' },
        { src: 'https://source.unsplash.com/random/2', alt: 'Image 2' },
        { src: 'https://source.unsplash.com/random/3', alt: 'Image 3' },
        { src: 'https://source.unsplash.com/random/4', alt: 'Image 4' },
        { src: 'https://source.unsplash.com/random/5', alt: 'Image 5' },
        { src: 'https://source.unsplash.com/random/6', alt: 'Image 6' },
    ];

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        beforeChange: (current, next) => setCurrentIndex(next),
    };

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    };

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    };

    const slides = [
        {
            title: "beach",
            image: "/images/squareC.jpg"
        },
        {
            title:"beach",
            image: "/images/square.png"
        },
        {
            title:"beach",
            image: "/images/squareB.jpg"
        }
    ]

    const slideB = [
        {
            title: "beach",
            image: "/images/squares.jpg"
        },
        {
            title:"beach",
            image: "/images/squareB.png"
        },
        {
            title:"beach",
            image: "/images/squareC.jpg"
        }
    ]

    const goToNext = () => {
        if (swiper !== null) {
          swiper.slideNext();
        }
      };
    
      const goToPrev = () => {
        if (swiper !== null) {
          swiper.slidePrev();
        }
      };

    const goToBack = () => {
        const isFirstSlide = anotherIndex === 0
        const newIndex = isFirstSlide  ? slideB.length - 1 : anotherIndex - 1
        setAnotherIndex(newIndex)
    }

    const goToFront = () => {
        const isLastSlide = anotherIndex === slideB.length - 1
        const newIndex = isLastSlide ? 0 : anotherIndex + 1
        setAnotherIndex(newIndex)
    }

    useEffect(() =>{
        const timer = setTimeout(() => {
            const isLastSlide = currentIndex === slides.length - 1
            const newIndex = isLastSlide ? 0 && currentIndex + 1 : currentIndex + 1 
            setCurrentIndex(newIndex)
        }, 3000);
        return () => clearTimeout(timer);
    }, [currentIndex, slides.length])

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

  return (
    <>
        <div className='mx-4 p-2 md:p-6'>

            <div>
                <div className='flex mb-1' onClick={handleGoBack}>
                    <div className='mr-2'>
                        <i className=''><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <div>Back</div>
                </div>

                <div className='h-[200px] w-full'>
                    <div style={{
                        backgroundImage: ` url('/images/backgroundDetails.png')`, 
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '100%',
                    }}></div>
                    {/* <img src='images/Rectangle1.png' alt='GMH image' className='h-full'/> */}
                </div>
            </div>

            <div className='mt-2 p-2 md:flex gap-x-20 h-full'>
                <div className='basis-4/6'>
                    <div className='flex flex-col space-y-6'>
                        <div>
                            <h3 className='text-2xl font-bold text-black'>{galleryDetails?.name}, {galleryDetails?.title}</h3>
                        </div>
                        <div>
                            <p>
                            Introducing the "{galleryDetails?.name}" at {galleryDetails?.location}, a new upscale housing property located in the heart of downtown. With an estimated delivery date of {galleryDetails?.delivery_date ? moment(galleryDetails?.delivery_date).format("YYYY-MM-DD") : "null"}, this {galleryDetails?.project_size} development offers a total of {galleryDetails?.unit_number} units ranging from cozy one-bedroom apartments to spacious three-bedroom penthouses. The property is currently {galleryDetails?.status} and boasts stunning city views, modern finishes, and top-tier amenities including a fitness center, rooftop pool, and 24-hour concierge service. Contact us today to schedule a tour and learn more about available units.
                            </p>
                        </div>
                    </div>

                    <hr className="border-2 border-solid border-[#CCCCCC] mt-16 mb-6" /> 

                    <div>
                        <div className='font-bold text-xl'>
                            <p>Feature</p>
                        </div>

                        <div className="grid grid-cols-2 gap-6 mt-4">
                            {galleryDetails && galleryDetails?.project_amenities.map((photo) => (
                                <div className='flex space-x-2'>
                                    <img src={photo.file} alt="CCTV System" style={{ width: '1.25em', height: '1.25em' }} />
                                    <div>{photo.name}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                    
                    <div className='mt-6'>
                        <div className='font-bold text-xl'>
                            <p>Project Video</p>
                        </div>

                        <div className="grid grid-cols-3 gap-4 mt-1">
                        {galleryDetails && galleryDetails?.project_videos?.map((image, index) => (
                            <div
                            key={index}
                            className="relative rounded-md overflow-hidden"
                            >
                            <img
                                src='https://source.unsplash.com/random/1'
                                alt='image'
                                className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity" onClick={() => setVideo(image.video_link)}>
                                <button className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setShowModal(true)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3l14 9-14 9V3z" />
                                </svg>
                                </button>
                            </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    
                </div>

                <div className='flex flex-col space-y-6 basis-2/6'>
                    <div className='font-bold text-xl'>
                        <p>Feature</p>
                    </div>

                    <div>
                        <table className='border-collapse'>
                            <thead className='border-collapse whitespace-nowrap border-b-2 border-b-solid border-b-black'>
                                <tr>
                                    <th className="">
                                        Status
                                    </th>
                                    <th className="px-14">
                                        Tag Line
                                    </th>
                                </tr>
                            </thead>

                            <tbody className='border-collapse whitespace-nowrap'>
                                <tr>
                                    <td>
                                    {galleryDetails?.status}
                                    </td>
                                    <td className="px-14">
                                        {galleryDetails?.tag_line}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className=''>
                    
                        <div className=''>                           
                            <div className='h-[180px] w-full relative'>
                                
                                {galleryDetails?.project_photos.length === 2  && 
                                    <Carousel>
                                
                                    {galleryDetails?.project_photos.map((photo) => (                                       
                                        
                                        <div key={photo.id}>
                                            <img src={photo.file} alt={`Image ${photo.id}`} />
                                        </div>
                                    ))}    
                                    </Carousel>                            
                                }

                                {galleryDetails?.project_photos.length === 1  && 
                                 <>                       
                                    {galleryDetails?.project_photos.map((photo) => (
                                        <div style={{
                                            backgroundImage: ` url(${photo.file})`, 
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            height: '100%',
                                        }}
                                        >                               
                                        </div>
                                    ))}                                
                                 </>                                                                                 }              
                            </div>
                        </div>

                        <p className="font-medium">Project Photos</p>
                    </div>

                    <div>
                    <div className='h-[180px] w-full relative mt-8'>
                        

                        {galleryDetails?.project_landmark_photos.length === 2  && 
                            <Carousel>
                            {galleryDetails?.project_landmark_photos.map((slide) => (                                                                  
                                <div key={slide.id}>
                                    <img src={slide.file} alt={`Image ${slide.id}`} />
                                </div>                         
                           ))}
                            </Carousel> 
                        }

                        {galleryDetails?.project_landmark_photos.length === 1  &&                           
                            <>
                            {galleryDetails?.project_landmark_photos.map((slide) => (
                            
                                <div
                                style={{
                                    backgroundImage: `url(${slide.file})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    height: "100%",
                                }}
                                
                                ></div>
                                
                            
                            ))}   
                            </>                        
                        }
                             
                        </div>
                        <p className="font-medium">Project Landmark Photo</p>
                    </div>

                </div>
            </div>

        </div>

        {showModal ? < YouTube video={video} setShowModal={setShowModal} /> : null}
    </>
  )
}

export default GalleryDeSection
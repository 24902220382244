import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const UnitDocx = ({data, setShowDocx, setShowMedRecord}) => {
    console.log("this is the unitdocx", data)
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>View Subscribers Details</p>
                    </div> 

                    <svg
                        onClick={() => setShowDocx(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                    <div className='bg-white px-4 mt-4' >
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Unit Prices</h2>
                            <div className='flex my-1'>
                                

                                

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {setShowMedRecord(true);}}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-2'>

                            <div className="">
                                <p className="font-semibold">Additional Fee</p>
                                <p>{data?.subscriberUnit?.additional_fee}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Additional Fee Component</p>
                                <p>{data?.subscriberUnit?.additional_fee_component}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Amount</p>
                                <p>{data?.subscriberUnit?.amount}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Balance</p>
                                <p>{data?.subscriberUnit?.balance}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Latest Deposit</p>
                                <p>{data?.subscriberUnit?.latest_deposit}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{data?.subscriberUnit?.name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Total Amount Paid</p>
                                <p>{data?.subscriberUnit?.total_amount_paid}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Total Price</p>
                                <p>{data?.subscriberUnit?.total_price}</p>
                            </div>

                        </div>
                    </div>

                    {data?.subscriber_unit_price_docx.map(project => (
                    <div className='bg-white px-4 mt-4' key={project.id} >
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Unit</h2>
                            <div className='flex my-1'>
                                

                                {/* <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {
                                        setShowUnit(true);
                                        setUnitData(project);
                                    }}  className="cursor-pointer"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {setShowMedRecord(true);}}  className="cursor-pointer"/></i>
                                </div> */}

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-2'>
                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>{project?.document_name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Additional Fee</p>
                                <p><a href={project?.file ? project?.file : "null"} target="_blank" download>{project?.file ? project?.file.split('/').pop() : "null"}</a></p>
                            </div>

                            
                            

                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default UnitDocx
import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faReceipt } from '@fortawesome/free-solid-svg-icons'

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import CreateProject from './CreateProject';
import FillProject from './FillProject';
import FullDetails from './FullDetails';
import { useGetAllProjectDetailsQuery } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const ProjectSide = () => {

  const[state, setState] = useState([
    {
        id: 1,
        name: "Segun Neyo",
        location: "Abuja",
        projectSize: "20",
        title: "GCC III",
        deliveryDate: "02-20-22",
        status: "Completed",
        tagline: "68Tar",
        unitNumber: "18",
        startDate: "08-10-22",
        info : ([
            {               
                id: 1,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    },
    {
        id: 2,
        name: "Segun Neyo",
        location: "Abuja",
        projectSize: "20",
        title: "GCC III",
        deliveryDate: "02-20-22",
        status: "Completed",
        tagline: "68Tar",
        unitNumber: "18",
        startDate: "08-10-22",
        info : ([
            {               
                id: 1,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    },
    {
        id: 3,
        name: "Segun Neyo",
        location: "Abuja",
        projectSize: "20",
        title: "GCC III",
        deliveryDate: "02-20-22",
        status: "Completed",
        tagline: "68Tar",
        unitNumber: "18",
        startDate: "08-10-22",
        info : ([
            {               
                id: 1,
                unitId: "233444",
                totalAmount: "#2,000,000",
                totalPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                unitId: "233444",
                totalAmount: "#2,000,000",
                totalPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                unitId: "233444",
                totalAmount: "#2,000,000",
                totalPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    },
    {
        id: 4,
        name: "Segun Neyo",
        location: "Abuja",
        projectSize: "20",
        title: "GCC III",
        deliveryDate: "02-20-22",
        status: "Completed",
        tagline: "68Tar",
        unitNumber: "18",
        startDate: "08-10-22",
        info : ([
            {               
                id: 1,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 2,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            },
            {               
                id: 3,
                contractId: "233444",
                totalAmount: "#2,000,000",
                amountPaid: "#2,000,000",
                bal: "#500,000",
                datePay: "02-05-21",
            }
        ])
    }
  ])

  const [page, setPage] = useState(1) 

    
    const { data:mainData, isLoading:approveLoad, isSuccess: appSucess, error: approveErr } = useGetAllProjectDetailsQuery(page)
    console.log("project 2 data", mainData)

    useEffect(() => {
        if(appSucess) {
            toast.success(mainData.message) 
        }
    }, [appSucess])


  const [showModal, setShowModal] = useState(false);
  const [activeObject, setActiveObject] = useState(null);
  const [receiptObject, setReceiptObject] = useState(null);
  const [showAmount, setShowAmount] = useState(false)
  const [amount, setAmount] = useState(null)
  const [individualDetails, setIndividualDetails] = useState(false)
  const [employeeInfo, setEmployeeInfo] = useState(false)
  const [id, setId] = useState(null);

  return (
    <>
        <div className='mt-6 rounded-xl mx-4 bg-[#FFFFFF]'>
            <div className='flex flex-col gap-y-12 p-2 md:p-6'>
                <div className="flex flex-col gap-y-6">
                    <div>
                        <p className="text-lg font-bold">Project</p>
                    </div> 
                </div>

                <div>
                    <div className='flex justify-end ml-auto mb-3'>
                        <button className='bg-[#E2B682] p-2 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50' onClick={() => setShowAmount(true)}>
                            Create
                        </button>
                    </div>

                    <div className="overflow-x-auto" id="document">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-white uppercase bg-[#0A3347]">
                                <tr>
                                    <th scope="col" className="w-4 p-4">
                                        <div class="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2" />
                                            <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Name
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        location
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Project Size
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Title
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Delivery Date
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Status
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        TagLine
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Unit Number
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        start Date
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        See Details
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Add
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {approveLoad && <p className="text-xl text-center p-5">Loading...</p>}
                            {!mainData?.data?.data && <p className="text-xl text-center p-5">Data Does Not Exist :(</p>}
                            {mainData?.data?.data === [] && <p className="text-xl text-center p-5">No posts :(</p>}
                            {mainData && mainData?.data?.data.map((items) => (
                                <tr
                                className="bg-white border-b  hover:bg-gray-50 even:bg-[#F4F0EB]" 
                                key={items.id}>
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 " />
                                            <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.name}
                                    </th>
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.location}
                                    </th>
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.project_size}
                                    </th>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.title}
                                    </td>
                                    <td className='px-10 py-4 text-center whitespace-nowrap'>
                                        {items?.delivery_date ? moment(items?.delivery_date).format("YYYY-MM-DD") : "null"}                                                                   
                                    </td>
                                    <td className='px-10 py-4 text-center whitespace-nowrap'>
                                    
                                        {items?.status}
                                                                    
                                    </td>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.tag_line}
                                    </td>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.unit_number}
                                    </td>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.commencement_date ? moment(items?.commencement_date).format("YYYY-MM-DD") : "null"} 
                                    </td>
                                    <td className="px-10 py-4 text-center text-[#0A3347] cursor-pointer" onClick={() => {
                                        setActiveObject(items);
                                        setShowModal(true);
                                    }}>
                                        view
                                    </td>
                                
                                    <td className="flex px-6 py-4 text-right cursor-pointer">
                                        {/* <i 
                                        onClick={() => {
                                            setReceiptObject(items);
                                            setEmployeeInfo(true);
                                        }}
                                        data-tip="Employee Record"
                                        className='p-2 bg-[#FFE8B4] rounded-lg'><FontAwesomeIcon icon={faReceipt} /></i> */}
                                        <i 
                                        onClick={() => {
                                            setAmount(items);
                                            setIndividualDetails(true);
                                            setId(items.id)
                                        }}
                                        data-tip="Individual Details"
                                        className='py-2 ml-4'><FontAwesomeIcon icon={faPencil} /></i>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex gap-x-2 justify-end">
                    <button className='bg-white py-2 px-8 rounded-lg text-black transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-white hover:bg-[#E2B682]  focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50 font-bold' onClick={() => setPage((prev) => prev - 1)}
                    disabled={page === 1}>
                        {approveLoad ? 'Loading...' : 'Prev'}
                    </button>

                    <button className='bg-[#E2B682] py-2 px-8 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50 font-bold' onClick={() => setPage((prev) => prev + 1)}
                    disabled={page === mainData?.data?.meta.total}>
                        {approveLoad ? 'Loading...' : 'Next'}
                    </button>
                </div>


                <div className='shadow-lg shadow-black p-2 rounded-lg'>
                    <Bar options={options} data={data} />
                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />

        {showAmount ? <CreateProject setShowAmount={setShowAmount} /> : null}
        {individualDetails ? <FillProject setIndividualDetails={setIndividualDetails}  id={id} /> : null}
        {showModal ? <FullDetails showModal={setShowModal} data={activeObject} /> : null}
    </>
  )
}

export default ProjectSide
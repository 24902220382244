import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faFill, faAdd } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SubProject from './SubProject';
import AddProject from './AddProject';
import ModalPage from './AddModal';

const FullDetails = ({showModal, data}) => {
    const [showFModal, setShowFModal] = useState(false);
    const [editData, setEditData] = useState(null)
    const [id, setId] = useState(null)
    const [showMedModal, setShowMedModal] = useState(false);
    const [showKin, setShowKin] = useState(false);
    const [showSanction, setShowSanction] = useState(false);
    const [showMedRecord, setShowMedRecord] = useState(false);
    const [projectDetails, setProjectDetails] = useState(false);
    const [detailId, setDetailId] = useState(null)
    const [project, setProject] = useState(null);
    const [showProject, setShowProject] = useState(false)
    const [subId, setSubId] = useState(null)

    console.log("here is the daata", data)


    const Modal = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowFModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Contract</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">First Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Oladapo"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Lat Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Mayowa"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                            <input type="email" name="text" id="email" placeholder="mayowa@gmail.com"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Referral Code</label>
                            <input type="text" name="text" id="email" placeholder="123OlA234"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary</label>
                            <input type="number" name="text" id="email" placeholder="#300,000"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a Role</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a role</option>
                                <option value="US">Head of Sales</option>
                                <option value="CA">Head of  Marketing</option>
                                <option value="FR">Head of Project</option>
                                <option value="DE">Head of Media</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a Department</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a deparment</option>
                                <option value="US">Sales</option>
                                <option value="CA">Marketing</option>
                                <option value="FR">Project</option>
                                <option value="DE">Media</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Resumption Date</label>
                            <input type="date" name="text" id="email" placeholder="0+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Sex</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a deparment</option>
                                <option value="US">Female</option>
                                <option value="CA">Male</option>                        
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Marital Sttus</label>
                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose a status</option>
                                <option value="US">Married</option>
                                <option value="CA">Single</option>                        
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Street</label>
                            <input type="text" name="text" id="email" placeholder="50 Sangotedo"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">City</label>
                            <input type="text" name="text" id="email" placeholder="Eti-Osa"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="text" className="block px-4 pt-1 text-sm text-gray-600 ">City</label>
                            <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State</label>
                            <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                            <input type="number" name="text" id="email" placeholder="0809765467" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Birth</label>
                            <input type="date" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Number of Children</label>
                            <input type="number" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Number of Dependent</label>
                            <input type="number" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Nationality</label>
                            <input type="text" name="text" id="email" placeholder="Nigeria"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State of Origin</label>
                            <input type="text" name="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">LGA of Origin</label>
                            <input type="text" name="text" id="email" placeholder="Eti-Osa 3"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Account Name</label>
                            <input type="text" name="text" id="email" placeholder="Mayowa Musa"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Account Number</label>
                            <input type="number" name="text" id="email" placeholder="987654588"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Account Bank</label>
                            <input type="text" name="text" id="email" placeholder="Keystone"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Create
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalMedical = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowMedModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Basic Field</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">First Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Christian..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Surname</label>
                            <input type="name" name="text" id="email" placeholder="eg Eze..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Other Names</label>
                            <input type="name" name="text" id="email" placeholder="eg Name"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Email </label>
                            <input type="text" name="text" id="email" placeholder="O+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date </label>
                            <input type="date" name="text" id="email" placeholder="O+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Nationality </label>
                            <input type="text" name="text" id="email" placeholder="eg Nigeria"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                            <input type="number" name="text" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Title</label>
                            <input type="text" name="text" id="email" placeholder="eg My Title"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Residential Address</label>
                            <input type="text" name="text" id="email" placeholder="O+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Marital Status</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold">
                            <option selected>Choose a Status</option>
                            <option value="US">Single</option>
                            <option value="CA">Married</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Occupation</label>
                            <input type="text" name="text" id="email" placeholder="eg banker"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Place of Work</label>
                            <input type="text" name="text" id="email" placeholder="eg banker"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Place of Work</label>
                            <input type="text" name="text" id="email" placeholder="eg banker"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Office Address</label>
                            <input type="text" name="text" id="email" placeholder="eg 29 A.."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Number of Unit Address</label>
                            <input type="number" name="text" id="email" placeholder="eg 29"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">How you Heard</label>
                            <input type="text" name="text" id="email" placeholder="eg Newspaper"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Next of Kin</label>
                            <input type="text" name="text" id="email" placeholder="eg Ugochukwu Dre"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Relationship</label>
                            <input type="text" name="text" id="email" placeholder="eg Sister"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Status</label>
                            <input type="text" name="text" id="email" placeholder="eg Prospect"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Preferred Payment</label>
                            <input type="text" name="text" id="email" placeholder="eg Full Payment"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Create
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalKin = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowKin(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Subscriber Unit</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                            <input type="text" name="text" id="email" placeholder="eg Julian Silva" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>               

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Amount Paid</label>
                            <input type="number" name="text" id="email" placeholder="eg 50"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Balance</label>
                            <input type="number" name="text" id="email" placeholder="500"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>
                    

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Amount</label>
                        <input type="number" name="text" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Additional Fee</label>
                        <input type="number" name="text" id="email" placeholder="200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Total Price</label>
                        <input type="number" name="text" id="email" placeholder="200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Additional Fee Component</label>
                        <input type="number" name="text" id="email" placeholder="200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Initial Deposit</label>
                        <input type="number" name="text" id="email" placeholder="200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalSanction = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowSanction(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Subscriber Unit Pricing</p>
                </div>
                <form>
                    <div className="grid grid-cols-1 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price Period</label>
                            <input type="text" name="text" id="email" placeholder="eg 0002EDU432" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Price</label>
                        <input type="number" name="text" id="email" placeholder="eg 30" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalMedRecord = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowMedRecord(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Subscribers Project</p>
                </div>
                <form>
                    <div className="grid grid-cols-1 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Project Name</label>
                            <input type="text" name="text" id="email" placeholder="eg GCC III" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Unit</label>
                            <input type="number" name="text" id="email" placeholder="eg 200" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    

  return (
    <>
    <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>View Subscribers Details</p>
                    </div> 

                    <svg
                        onClick={() => showModal(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>            

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                    <div className='bg-white px-4 '>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} 
                                    onClick={() => {
                                        setShowMedModal(true)
                                        setEditData(data);
                                        setId(data.id)
                                    }}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 gap-y-3 justify-center py-2'>
                            <div className="">
                                <p className="font-semibold">First Name</p>
                                <p>{data?.firstname}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Last Name</p>
                                <p>{data?.surname}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Other Names</p>
                                <p>{data?.othernames}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Email</p>
                                <p>{data?.user.email}</p>
                            </div>

                            <div>
                                <p className="font-semibold">DOB</p>
                                <p>{data?.date_of_birth ? moment(data?.date_of_birth).format("YYYY-MM-DD") : "null"}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Nationality</p>
                                <p>{data?.nationality}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Phone</p>
                                <p>{data?.phone}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Title</p>
                                <p>{data?.title}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Residential Address</p>
                                <p>{data?.residential_address}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Marital Status</p>
                                <p>{data?.marital_status}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Occupation</p>
                                <p>{data?.occupation}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Place of Work</p>
                                <p>{data?.place_of_work}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Office Address</p>
                                <p>{data?.office_address}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Number of Unit Applied</p>
                                <p>{data?.number_of_unit_applied}</p>
                            </div>

                            <div>
                                <p className="font-semibold">How you Heard</p>
                                <p>{data?.how_you_heard}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Next of Kin</p>
                                <p>{data?.next_of_kin}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Relationship</p>
                                <p>{data?.relationship}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Status</p>
                                <p>{data?.status}</p>
                            </div>

                            <div>
                                <p className="font-semibold">Preferred Payment</p>
                                <p>{data?.preferred_payment_option}</p>
                            </div>
                        </div>                       
                    </div>

                    {/* <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Unit</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit}
                                    onClick={() => setShowKin(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3'>
                            <div className="">
                                <p className="font-semibold">Name</p>
                                <p>Mary Joseph</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Amount Paid</p>
                                <p>#200,000</p>
                            </div>

                            <div>
                                <p className="font-semibold">Balance</p>
                                <p>#600,000</p>
                            </div>

                            <div>
                                <p className="font-semibold">Amount</p>
                                <p>#300,000</p>
                            </div>

                            <div>
                                <p className="font-semibold">Additional Fee</p>
                                <p>#500,000</p>
                            </div>

                            <div>
                                <p className="font-semibold">Total Price</p>
                                <p>#20,000,000</p>
                            </div>

                            <div>
                                <p className="font-semibold">Additional Fee Component</p>
                                <p>#400,000</p>
                            </div>

                            <div>
                                <p className="font-semibold">Initial Deposit</p>
                                <p>#400,000</p>
                            </div>
                        </div>
                    </div> */}

                    {data?.subscriberAttachment.map(attach => (
                    <div className='bg-white px-4 mt-4' key={attach.id}>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Attachment</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} 
                                    onClick={() => setShowSanction(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-2'>
                            <div className="">
                                <p className="font-semibold">Document Name</p>
                                <p>{attach?.document_name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">File</p>
                                <p><a href={attach?.file ? attach?.file : "null"} target="_blank" download>{attach?.file ? attach?.file.split('/').pop() : "null"}</a></p>
                            </div>

                            
                        </div>
                    </div>
                    ))}
                    {/* <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Unit Pricing</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} 
                                    onClick={() => setShowSanction(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-2'>
                            <div className="">
                                <p className="font-semibold">Price Period</p>
                                <p>6 months</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Price</p>
                                <p>#30,000,000</p>
                            </div>

                            
                        </div>
                    </div> */}

                    {data?.subscriberProject.map(project => (
                    <div className='bg-white px-4 mt-4' key={project.id} >
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Subscriber Project</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faFill} onClick={() => {
                                        setShowProject(true);
                                        setProject(project)}}  className="cursor-pointer"/></i>
                                </div>

                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => {setShowMedRecord(true);}}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faAdd}
                                    onClick={() => {setDetailId(project.id); setSubId(project.subscriber_id); setProjectDetails(true)}}
                                     className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-3'>
                            <div className="col-span-1">
                                <p className="font-semibold">Project</p>
                                <p className='break-words'>{project?.project?.name}</p>
                            </div>

                            <div className="col-span-1">
                                <p className="font-semibold">Project Title</p>
                                <p className='break-words'>{project?.project?.title}</p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>

    {showFModal ? <Modal showModal={showModal} editData={editData} id={id} /> : null}
    {showMedModal ? <ModalPage setShowFModal={setShowMedModal} editData={editData} id={id}/> : null}
    {showKin ? <ModalKin showModal={showModal}/> : null}
    {showSanction ? <ModalSanction showModal={showModal}/> : null}
    {showMedRecord ? <ModalMedRecord showModal={showModal}/> : null}
    {showProject ? <SubProject data={project} showModal={setShowProject}/> : null}
    {projectDetails ? <AddProject id={detailId} subId={subId} setIndividualDetails={setProjectDetails} /> : null}
    </>
  )
}

export default FullDetails
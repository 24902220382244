import React from 'react';
import NotificationBar from '../components/profile/NotificationBar';
import ProfileImg from '../components/profile/ProfileImg';

const ProfilePage = () => {
  return (
    <>
        <div className='lg:ml-[12rem] pb-10'>
            <div className='flex flex-col lg:flex-row gap-x-4 mx-4'>
                <div className='basis-[70%] order-last' >
                    <ProfileImg />
                </div>
                
                <div className='basis-[30%] lg:order-last' >
                    <NotificationBar />
                </div>
            </div>
        </div>
    </>
  )
}

export default ProfilePage
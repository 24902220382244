import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { AddPhoto } from '../../reactWrapper/Redux/userSlice';


const GallerySide = () => {
    const dispatch = useDispatch()
    const { units } = useSelector((store) => store.user);
    const [v, setV] = useState(null)
    console.log("this is unit", units)
  return (
    <div className="bg-gray-100">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
    <div className="flex items-center justify-center">
      <h2 className="text-3xl font-bold text-gray-800 uppercase tracking-wide">
        {units?.name}
      </h2>
    </div>
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-8">
    {units &&
      units.project_units.map((unit, index) => (
        <Link
          key={index}
          to="/gallery/view/detail"
          onClick={() => dispatch(AddPhoto(unit))}
        >
          <div className="relative rounded-lg overflow-hidden aspect-w-3 aspect-h-4 cursor-pointer">
            <div className='h-[234px] w-full'>
              <div
                className="absolute inset-0 bg-center bg-cover w-full h-full"
                style={{
                  backgroundImage: `url('/images/Rectangle1.png')`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent" />
            <div className="absolute bottom-0 left-0 p-4 text-white">
              <h3 className="text-lg font-bold">{unit.name}</h3>
              {/* <p className="text-sm">{unit.additional_fee_component}</p> */}
              <p className="text-sm">See Unit Photos</p>
            </div>
            <div className='absolute top-0 right-0 p-2 bg-white rounded-bl-lg'>
              <p className='text-sm font-bold text-gray-800'>{unit.total_price}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  </div>
</div>
  )
}

export default GallerySide
import React from 'react'
import GalleryDeSection from '../../components/gallery/GalleryDeSection'

const GalleryDetail = () => {
  return (
    <>
        <div className='lg:ml-[12.5rem] pb-10 overflow-x-hidden'>
            <div className='mt-6 mx-2 flex flex-col gap-y-12'>
                <GalleryDeSection />
            </div>     
        </div>
    </>
  )
}

export default GalleryDetail
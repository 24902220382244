import React,{useEffect, useState} from 'react';
import { useGetRecentPhotosQuery } from '../../reactWrapper/Redux/apiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const LoadingSkeleton = () => {
    return (
      <div className="grid gap-2 lg:grid-cols-4 xl:grid-cols-4 items-center">
        {[1, 2, 3].map((_, index) => (
          <div
            key={index}
            className={`h-${index % 2 === 0 ? '272' : '315'} bg-gray-500 rounded animate-pulse`}
            style={{ gridColumn: index % 2 === 0 ? null : '2 / span 2' }}
          ></div>
        ))}
      </div>
    );
  };


const RecentSection = () => {

    const { data: slide, isLoading: empLoading, isSuccess: empSuccess, error: allEmpErr } = useGetRecentPhotosQuery()
    const [slideIndex, setSlideIndex] = useState(0);
    console.log("this is the slide", slide)
    // const [slide, setSlide] = useState([
    //     {
    //         id: 1,        
    //         imageRectangle: 'images/Rectangle1.png',              
    //         imageSquare: 'images/square.png',
    //         imageRectangleB: 'images/Rectangle2.png',
    //     },
    //     {
    //         id: 2,
    //         imageRectangle: 'images/RectangleB.jpg',              
    //         imageSquare: 'images/squares.jpg',
    //         imageRectangleB: 'images/RectangleB2.jpg',
    //     },
    //     {
    //         id: 3,
    //         imageRectangle: 'images/RectangleC.png',              
    //         imageSquare: 'images/squareC.jpg',
    //         imageRectangleB: 'images/Rectangle2.png',
    //     }
    // ])

    useEffect(() => {
        if(allEmpErr) {
            toast.error(allEmpErr.error)
        }
    }, [allEmpErr])

    useEffect(() => {
        if(empSuccess) {
            toast.success(slide.message)
           
        }
    }, [empSuccess, slide])

    console.log("This is the data", slide)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (slide && slide?.data.length > 3) {
              const nextIndex = slideIndex + 3 >= slide?.data.length ? 0 : slideIndex + 3;
              setSlideIndex(nextIndex);
            }
          }, 2000);
    
        return () => {
          clearTimeout(timer);
        };
    }, [slide, slideIndex]);

  return (
    <>
        <div className='mx-4 p-2 md:p-6'>
            <div className='text-[#B39768] font-medium'>
                <p>Recent Uploads</p>
            </div>

            {empLoading ? (
                <LoadingSkeleton />
                ) : (
                <div className="grid gap-2 lg:grid-cols-4 xl:grid-cols-4 items-center">
                    {slide && slide?.data.slice(slideIndex, slideIndex + 3).map((item, index) => (                                   
                        <div  
                        key={item.id}
                        style={{
                            height: index % 2 === 0 ? '272px' : '315px',
                            gridColumn: index % 2 === 0 ? null : '2 / span 2'
                        }}>
                            <div style={{
                                backgroundImage: ` url(${item.file})`, 
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                height: '100%',
                            }}></div>
                            {/* <img src='images/Rectangle1.png' alt='GMH image' className='h-full'/> */}
                        </div>
                                
                                
                    ))}
                </div>
            )}                    
        </div>
    </>
  )
}

export default RecentSection
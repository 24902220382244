import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetDepartmentsQuery } from '../../reactWrapper/Redux/apiSlice';
import { useEditEmployeeMutation } from '../../reactWrapper/Redux/apiSlice';


const EditEmployees = ({editData, id, setShowFModal}) => {
    const [inputFields, setInputFields] = useState({salary: '', current_loan_repayment: '',  department: '',  resumption_date: '', gender: '', marital_status: '',  street: '', city: '',  state: '',  phone: '', date_of_birth: '', number_of_children: '', number_of_dependent: '', nationality: '',  state_of_origin: '', lga_of_origin: '',  account_name: '',  account_number: '', document_nameA: '', documentA: null})

    const [ editEmployee, {isLoading, isSuccess, error, data: medData } ] = useEditEmployeeMutation();
    const { data: departments } = useGetDepartmentsQuery()

    useEffect(() => {
        setInputFields({
            salary: editData?.salary,
            current_loan_repayment: editData?.current_loan_repayment,
            department: editData?.department,
            resumption_date: editData?.resumption_date,
            gender: editData?.gender,
            marital_status: editData?.marital_status,
            street: editData?.street,
            city: editData?.city,
            state: editData?.state,
            phone: editData?.phone,
            date_of_birth: editData?.date_of_birth,
            number_of_children: editData?.number_of_children,
            number_of_dependent: editData?.number_of_dependent,
            nationality: editData?.nationality,
            state_of_origin: editData?.state_of_origin,
            lga_of_origin: editData?.lga_of_origin,
            account_name: editData?.account_name,
            account_number: editData?.account_number,
            // document_nameA: editData?.id_card_photo.name,
            documentA: editData?.id_card_photo
        });
      }, [editData]);

      const handleChange = event => {
        if (event.target.type === "file") {
          setInputFields({
            ...inputFields,
            documentA: event.target.files[0],
            document_nameA: event.target.files[0].name
          });
        } else {
          setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value
          });
        }
      };

      const handleReset = () => {
        setInputFields({
            ...inputFields,
            document_nameA: '',
            documentA: null
          });
      };

      const submit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData();          
        formData.append(`resumption_date`, moment(inputFields.resumption_date).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`date_of_birth`, moment(inputFields.date_of_birth).format("YYYY-MM-DD HH:mm:ss"));
        formData.append(`salary`, inputFields.current_loan_repayment);
        formData.append(`current_loan_repayment`, inputFields.current_loan_repayment);
        formData.append(`id_card_photo`, inputFields.documentA);
        formData.append(`department`, inputFields.department);
       
        formData.append(`gender`, inputFields.gender);
        formData.append(`marital_status`, inputFields.marital_status);
        formData.append(`street`, inputFields.street);
        formData.append(`city`, inputFields.city);
        formData.append(`state`, inputFields.state);
        formData.append(`phone`, inputFields.phone);
        formData.append(`number_of_children`, inputFields.number_of_children);
        formData.append(`number_of_dependent`, inputFields.number_of_dependent);

        formData.append(`nationality`, inputFields.nationality);
        formData.append(`state_of_origin`, inputFields.state_of_origin);
        formData.append(`lga_of_origin`, inputFields.lga_of_origin);
        formData.append(`account_name`, inputFields.account_name);
        formData.append(`account_number`, inputFields.account_number);
      
        try {
            await editEmployee({ id, dataToSubmit: formData }).unwrap();
            setInputFields({salary: '', current_loan_repayment: '',  department: '',  resumption_date: '', gender: '', marital_status: '',  street: '', city: '',  state: '',  phone: '', date_of_birth: '', number_of_children: '', number_of_dependent: '', nationality: '',  state_of_origin: '', lga_of_origin: '',  account_name: '',  account_number: '', document_nameA: '', documentA: null});
        } catch (error) {
          toast.error(error.error);
        }
      };

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setShowFModal(false)
            window.location.reload();
        }
    }, [isSuccess])
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowFModal(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Employee Basic Details</p>
                </div>
                <form>
                    <div className="grid grid-cols-3 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Salary</label>
                            <input type="number" id="email" placeholder="eg 20,0000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="salary" value={inputFields.salary} onChange={handleChange} />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Current Loan Repayment</label>
                            <input type="number" id="email" placeholder="eg 2000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="current_loan_repayment" value={inputFields?.current_loan_repayment} onChange={handleChange} />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="countries" className="block px-4 pt-1 text-sm text-gray-600 ">Department</label>
                            
                                <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="department"
                                value={inputFields.department}
                                onChange={handleChange}>
                                <option selected>Choose a Department</option>
                                {departments && departments.map((item) => (
                                    <option key={item.id}>{item.name}</option>
                                    )                                      
                                )}
                                </select>
                            
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Resumption Date</label>
                            <input type="number" id="email" placeholder="eg 2000" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" name="resumption_date" value={inputFields.resumption_date && moment(inputFields.resumption_date).format("YYYY-MM-DD")} onChange={handleChange} />
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            
                            <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Sex</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                            value={inputFields.gender}
                            name="gender"
                            onChange={handleChange}>
                                <option selected>Choose Sex</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="intersex">Intersex</option>
                                <option value="trans">Trans</option>
                                <option value="non-conforming">Non Conforming</option>
                                <option value="personal">Personal</option>
                                <option value="eunuch">Eunuch</option>
                            </select>
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            
                            <label for="countries" className="block px-4 pt-1 text-sm text-gray-600">Marital Status</label>
                            <select id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold"
                            name="marital_status" value={inputFields.marital_status} onChange={handleChange}>
                                <option selected>Choose Status</option>
                                <option value="married">Married</option>
                                <option value="single">Single</option>
                                <option value="divorced">Divorced</option>
                                <option value="notSay">Not to Say</option>
                            </select>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State</label>
                            <input type="text"  id="email" placeholder="Paris"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="state" value={inputFields.state} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">City</label>
                            <input type="text" id="email" placeholder="Paris"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="city" value={inputFields.city} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Street</label>
                            <input type="text" id="email" placeholder="10 Varane Nasri"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="street" value={inputFields.street} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone</label>
                            <input type="number" id="email" placeholder="0807654345"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="phone" value={inputFields.phone} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Date of Birth</label>
                            <input type="date" id="email" placeholder="02-10-20"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="date_of_birth" value={inputFields.date_of_birth && moment(inputFields.date_of_birth).format("YYYY-MM-DD")} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">No of Children</label>
                            <input type="number" id="email" placeholder="10"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="number_of_children" value={inputFields.number_of_children} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">No of Dependent</label>
                            <input type="number" id="email" placeholder="5+"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="number_of_dependent" value={inputFields.number_of_dependent} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Nationality</label>
                            <input type="text" id="email" placeholder="Island Faroe"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="nationality" value={inputFields.nationality} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">State of Origin</label>
                            <input type="text" id="email" placeholder="Lagos"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="state_of_origin" value={inputFields.state_of_origin} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">LGA of Origin</label>
                            <input type="text" id="email" placeholder="Eti Osa 3"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="lga_of_origin" value={inputFields.lga_of_origin} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">A/C Name</label>
                            <input type="text" id="email" placeholder="Ogene Rukayatu"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            vname="account_name" value={inputFields.account_name} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">A/C Number</label>
                            <input type="number" id="email" placeholder="0099887643"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            name="account_number" value={inputFields.account_number} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                        {inputFields.document_nameA === "" ? 
                                <div className=" flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className=" text-center">
                                    
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                                        >
                                        <span>Upload Image</span>
                                        <input id="file-upload" 
                                        accept="image/*" name="documentA" data-name="document_nameA" type="file" onChange={handleChange} className="sr-only" />
                                        </label>
                                        
                                    </div>
                                    
                                    </div>
                                </div>
                            : 
                                <div>
                                    <p>
                                        <span>Attached file</span> <span className="font-bold">{inputFields.document_nameA}</span> <span className="text-red-700 ml-4"
                                        onClick={handleReset}>X</span>
                                    </p>
                                </div>
                            }
                        </div>                     
                    </div>
                    

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Editing...' : 'Edit'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default EditEmployees
import React from 'react'
import ProjectSide from '../components/project/ProjectSide'

const ProjectPage = () => {
  return (
    <div className='lg:ml-[12rem] pb-10'>
        <ProjectSide />
    </div>
  )
}

export default ProjectPage
import React, {useState, useEffect} from 'react';
import { v4 as uuid } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEditRolesMutation } from '../../../reactWrapper/Redux/apiSlice';
import { useGetDepartmentsQuery } from '../../../reactWrapper/Redux/apiSlice';


const EditRole = ({editData, setEdit, myId}) => {
    const [name, setName] = useState("")
    const [job, setJob] = useState("")
    const [code, setCode] = useState("")
    const [dept, setDept] = useState(null)
    const [ editRoles, {isLoading, isSuccess, error, data } ] = useEditRolesMutation();
    const {
        data: departments,
        isLoading: nowLoading,
    } = useGetDepartmentsQuery()

    useEffect(() => {
        setName(editData?.name);
        setJob(editData?.job_description);
        setCode(editData?.role_code);
      }, [editData]);

    const unique_id = uuid();
    const uid = unique_id.slice(0,4);
    const [userId, setUserId] = useState(uid);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await editRoles({ id: myId, name, department_id: dept, role_code: code, job_description: job, user: userId }).unwrap()
            setName('')
            setJob('')
            setCode('')
        } catch (error) {
            toast.error(error.data.message)
            console.error("error", error.data.message)
        }
    }

    useEffect(() => {
        if(isSuccess) {
            toast.success(data.message)
            toast.success("update success")
            setEdit(false)
        }
    }, [isSuccess])

    useEffect(() => {
        if(error) {
            toast.error(error.data.message)
            console.log("This is the error", error.data.message)
        }
    }, [error])
  return (
    <>
    <div className="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
        <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-3/6 mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div
            className="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
            >                 
                <svg
                    onClick={() => setEdit(false)}
                    className="w-6 h-6 cursor-pointer"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </div>            
            
            <div className="flex flex-col px-6 py-5 bg-gray-50">
            <div className='text-center text-2xl font-medium mb-4'>
                <p>Edit Role</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 gap-x-3'>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="countries" className="block px-4 pt-1 text-sm text-gray-600 ">Department</label>
                        <select 
                        value={dept} 
                        onChange={(e) => setDept(e.target.value)}
                        id="countries" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold">
                            <option selected>Choose a Department</option>
                            {departments && departments.map((item) => (
                                <option key={item.id}  value={item.id}>{item.name}</option>
                                )                                      
                            )}
                        </select>
                    </div>

                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                        <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Name</label>
                        <input type="name" name="text" id="email" placeholder="Role" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-3">                          
                            <label for="message" className="block px-4 pt-1 text-sm text-gray-600 ">Job Describtion</label>
                            <textarea id="message" rows="6" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" placeholder="Write your thoughts here..."
                            value={job}
                            onChange={(e) => setJob(e.target.value)}
                            ></textarea>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-3">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Role Code</label>
                            <input type="text" name="text" id="email" placeholder="Role" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            />
                        </div>
                </div>

                <div className="mt-6">
                    <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}>
                        {isLoading ? 'Editing...' : 'Edit'}
                    </button>
                </div>
            </form>

            </div>
        </div>
    </div>

    <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </>
  )
}

export default EditRole
import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faInbox } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const UnitDetails = ({setUnitShow, unitData}) => {
    console.log(unitData.project_unit_photos, "ïnner data");

    const [showKin, setShowKin] = useState(false);
    const [showSanction, setShowSanction] = useState(false);
    const [floorPlan, setFloorPlan] = useState(false);
    console.log(unitData);

    const ModalKin = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowKin(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Project Photo</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="">
                            <img src="/images/images.jpg" alt="projects" className='rounded'/>
                            <p>Image Name here</p>
                        </div>

                        <div className="">
                            <img src="/images/images.jpg" alt="projects" className='rounded'/>
                            <p>Image Name here</p>
                        </div>

                        <div className="">
                            <img src="/images/images.jpg" alt="projects" className='rounded'/>
                            <p>Image Name here</p>
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const ModalSanction = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowSanction(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Subscriber Unit Pricing</p>
                </div>
                <form>
                    <div className="grid grid-cols-1 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Price Period</label>
                            <input type="text" name="text" id="email" placeholder="eg 0002EDU432" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                    
                    </div>
                    <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">                       
                        <label for="message" className="block px-4 pt-1 text-sm text-gray-600">Price</label>
                        <input type="number" name="text" id="email" placeholder="eg 30" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

    const FloorPlan = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setFloorPlan(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Edit Project Unit Floor Plan</p>
                </div>
                <form>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="name" className="block px-4 pt-1 text-sm text-gray-600 ">Floor Plan Name</label>
                            <input type="name" name="text" id="email" placeholder="eg Name..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div> 

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Detail Name</label>
                            <input type="text" name="text" id="email" placeholder="eg Detail..."  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Detail Size</label>
                            <input type="number" name="text" id="email" placeholder="eg 23"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" />
                        </div>

                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50">
                            Edit
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    )

  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-between px-6 py-3 bg-[#f1e1ce] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >           

                    <div className='text-center text-2xl font-medium'>
                        <p>View Project Details</p>
                    </div> 

                    <svg
                        onClick={() => setUnitShow(false)}
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>

                </div>

                <div className="flex flex-col px-6 py-5 bg-[#f1e1ce]">
                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Unit Photos</h2>
                            <div className='flex my-1'>
                                <div className="hidden mr-2">
                                    <i><FontAwesomeIcon icon={faEdit}
                                    onClick={() => setShowKin(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3 gap-x-3'>
                        {unitData?.project_unit_photos.map(photo => (
                            <div className="">
                                <img src={photo?.file} alt="projects" className='rounded'/>
                                <p>{photo?.name}</p>
                            </div>
                        ))}
                            
                             
                        </div>
                        
                    </div>

                    {unitData?.project_unit_pricings.map(price => (
                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Unit Pricing</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} 
                                    onClick={() => setShowSanction(true)}
                                    className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 justify-center py-2 gap-y-2'>
                            <div className="">
                                <p className="font-semibold">Price Period</p>
                                <p>{price?.price_period}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Price</p>
                                <p>{price?.price}</p>
                            </div>

                            
                        </div>
                    </div>
                    ))}

                    {unitData?.project_unit_floor_plan_details.map(floor => (
                    <div className='bg-white px-4 mt-4'>
                        <div className="flex justify-between">
                            <h2 className="text-gray-800 font-bold my-1 text-lg">Project Unit Floor Plan</h2>
                            <div className='flex my-1'>
                                <div className="mr-2">
                                    <i><FontAwesomeIcon icon={faEdit} onClick={() => setFloorPlan(true)}  className="cursor-pointer"/></i>
                                </div>

                                <div>
                                    <i><FontAwesomeIcon icon={faTrash}  className="cursor-pointer text-red-800"/></i>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 justify-center py-2 gap-y-3'>
                            <div className="">
                                <p className="font-semibold">Floor Plan Name</p>
                                <p>{floor?.floor_plan_name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Detail Name</p>
                                <p>{floor?.detail_name}</p>
                            </div>

                            <div className="">
                                <p className="font-semibold">Detail Size</p>
                                <p>{floor?.detail_size}</p>
                            </div>

                            
                        </div>
                    </div>
                    ))}
                </div> 
            </div>
        </div>

        {showKin ? <ModalKin/> : null}
        {showSanction ? <ModalSanction/> : null}
        {floorPlan ? <FloorPlan/> : null}
    </>
  )
}

export default UnitDetails
import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faNairaSign, faChartBar, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import EmployeeDetail from './EmployeeDetail';
import FullDetails from './FullDetails';
import CreateDetails from './CreateDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetEmployeesQuery } from '../../reactWrapper/Redux/apiSlice';
import CreateEmployee from './CreateEmployee';


const EmployeeSide = () => {

    const [showModal, setShowModal] = useState(false);
    const [activeObject, setActiveObject] = useState(null);
    const [receiptObject, setReceiptObject] = useState(null);
    const [showReceipt, setShowReceipt] = useState(false)
    const [showAmount, setShowAmount] = useState(false)
    const [amount, setAmount] = useState(null)
    const [individualDetails, setIndividualDetails] = useState(false)
    const [employeeInfo, setEmployeeInfo] = useState(false)
    console.log("this is the dta",activeObject)

    const [page, setPage] = useState(1)

    const[employeeId, setEmployeeId] = useState(null)


    // useEffect(() => {
    //     const newState = state.filter((items) => 
    //     items.projectAddress.toLowerCase().includes(filterProperty))
    //     setState(newState)
    // }, [filterProperty])

    const { data: employee, isLoading: empLoading, isSuccess: empSuccess, error: allEmpErr } = useGetEmployeesQuery(page)
    console.log("employee data", employee)

    useEffect(() => {
        if(allEmpErr) {
            toast.error(allEmpErr.error)
        }
    }, [allEmpErr])

    useEffect(() => {
        if(empSuccess) {
            toast.success(employee.message)
            setShowAmount(false)
        }
    }, [empSuccess, employee])

    const ModalReceipt = ({object}) =>(      
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.4)] antialiased">
            {console.log("this is d object details", object[0].name)}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-5/12 mx-auto rounded-lg border border-gray-300 shadow-xl bg-[#F4F0EB]">
                <div
                class="flex flex-row justify-end px-6 py-3 bg-[#F4F0EB] border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >
                    
                    {/* <div>
                    <span className="font-bold text-lg">
                        {object[0]?.firstName} {object[0]?.lastName}
                    </span>
                    <span className="ml-2">
                        ({object[0]?.role})
                    </span>
                    </div> */}
                    

                    <svg
                        onClick={() => setShowReceipt(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>         

                <div className="flex justify-center text-center bg-[#F4F0EB] mt-6">
                    <div className="mx-auto"><img src="images/Final-logo.png" alt="logo" /></div>
                </div>   

                <div class="flex flex-col px-6 py-5">

                    {/* <div className="flex border-2 border-solid border-[#9D8D79] text-[#9D8D79] py-1 px-2 rounded w-fit">
                        <i className='mr-2 text-[#9D8D79]'><FontAwesomeIcon icon={faFilter} /></i>
                        <p className='text-[#9D8D79]'>Filter</p>
                    </div> */}

                    <div className="">
                        <div className="w-full text-sm text-left text-gray-500">
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC] mb-5">
                            <div className="py-1">
                                <p>Name</p>
                            </div>

                            <div className="py-1">
                                <p className="text-[#0A3347] font-semibold">{object[0]?.name}</p>
                            </div>
                           </div>
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC] mb-5">
                            <div>
                                <p>Contract Task</p>
                            </div>

                            <div>
                                <p className="text-[#0A3347] font-semibold">{object[0]?.contractTask}</p>
                            </div>
                           </div>
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC] mb-5">
                            <div>
                                <p>Total Amount</p>
                            </div>

                            <div>
                                <p className="text-[#0A3347] font-semibold">{object[0]?.amount}</p>
                            </div>
                           </div>
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC] mb-5">
                            <div>
                                <p>Amount Paid</p>
                            </div>

                            <div>
                                <p className="text-[#0A3347] font-semibold">{object[0]?.amountPaid}</p>
                            </div>
                           </div>
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC] mb-5">
                            <div>
                                <p>Project</p>
                            </div>

                            <div>
                                <p className="text-[#0A3347] font-semibold">{object[0]?.contract}</p>
                            </div>
                           </div>
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC] mb-5">
                            <div>
                                <p>Balance</p>
                            </div>

                            <div>
                                <p className="text-[#0A3347] font-semibold">{object[0]?.balance}</p>
                            </div>
                           </div>
                           <div className="flex justify-between border-b border-solid border-[#CCCCCC]">
                            <div>
                                <p>Date</p>
                            </div>

                            <div>
                                <p className="text-[#0A3347] font-semibold">{object[0]?.paymentDate}</p>
                            </div>
                           </div>
                        </div>
                    </div>

                    <div className="mt-6">
                    <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50"
                    onClick={()=>{
                        // e.preventDefault();
                        setShowReceipt(false)
                    }}
                    >
                        Send
                    </button>
                </div>

                </div>
            </div>
        </div>
    )

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
    );

    const options = {
        plugins: {
          title: {
            display: true,
            text: 'GMH total Employee record',
          },
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
    };

    const labels = ['Sales', 'Subscribers', 'Account', 'Media', 'Procurement', 'Admin', 'COO/MD'];

    const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            backgroundColor: 'rgb(255, 99, 132)',
            stack: 'Stack 0',
          },
          {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            backgroundColor: 'rgb(75, 192, 192)',
            stack: 'Stack 0',
          },
          {
            label: 'Dataset 3',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            backgroundColor: 'rgb(53, 162, 235)',
            stack: 'Stack 1',
          },
        ],
      };

    

  return (
    <>
        <div className='mt-6 rounded-xl mx-4 bg-[#FFFFFF]'>
            <div className='flex flex-col gap-y-12 p-2 md:p-6'>              
                <div className="flex flex-col gap-y-6">
                    <div>
                        <p className="text-lg font-bold">Employees</p>
                    </div> 
                </div>

                <div>
                    <div className='flex justify-end ml-auto mb-3'>
                        <button className='bg-[#E2B682] p-2 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50' onClick={() => setShowAmount(true)}>
                            Create
                        </button>
                    </div>

                    <div className="overflow-x-auto" id="document">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-white uppercase bg-[#0A3347]">
                                <tr>
                                    {/* <th scope="col" className="w-4 p-4">
                                        <div class="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2" />
                                            <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                        </div>
                                    </th> */}
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        First Name
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        lastName
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Email
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Department
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Role
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        See Details
                                    </th>
                                    <th scope="col" className="px-10 py-3 text-center whitespace-nowrap">
                                        Add
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {empLoading && <p className="text-xl text-center p-5">Loading...</p>}
                            {!employee?.data?.data && <p className="text-xl text-center p-5">No posts :(</p>}
                            {employee && employee?.data?.data.map((items) => (
                                <tr
                                className="bg-white border-b  hover:bg-gray-50 even:bg-[#F4F0EB]" 
                                key={items?.id}>
                                    {/* <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 " />
                                            <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                        </div>
                                    </td> */}
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.first_name}
                                    </th>
                                    <th scope="row" className="px-10 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                        {items?.last_name}
                                    </th>
                                    <td className="px-10 py-4 text-center whitespace-nowrap">
                                        {items?.user.email} 
                                    </td>                                  
                                    <td className='px-10 py-4 text-center whitespace-nowrap'>
                                    
                                        {items?.department}
                                                                    
                                    </td>
                                    <td className='px-10 py-4 text-center whitespace-nowrap'>
                                    
                                        {items?.role}
                                                                    
                                    </td>
                                    <td className="px-10 py-4 text-center text-[#0A3347] cursor-pointer" onClick={() => {
                                        setActiveObject(items);
                                        setShowModal(true);
                                        setEmployeeId(items.id)
                                    }}>
                                        view
                                    </td>
                                
                                    <td className="flex px-6 py-4 text-right cursor-pointer">
                                        <i 
                                        onClick={() => {
                                            setReceiptObject(items);
                                            setEmployeeInfo(true);
                                            setEmployeeId(items.id)
                                        }}
                                        data-tip="Employee Record"
                                        className='p-2 bg-[#FFE8B4] rounded-lg'><FontAwesomeIcon icon={faReceipt} /></i>
                                        <i 
                                        onClick={() => {
                                            setAmount(items);
                                            setIndividualDetails(true);
                                            setEmployeeId(items.id)
                                        }}
                                        data-tip="Individual Details"
                                        className='py-2 ml-4'><FontAwesomeIcon icon={faPencil} /></i>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex gap-x-2 justify-end">
                    <button className='bg-white py-2 px-8 rounded-lg text-black transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-white hover:bg-[#E2B682]  focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50 font-bold'
                    onClick={() => setPage((prev) => prev - 1)}
                    disabled={page === 1}>
                        {empLoading ? 'Loading...' : 'Prev'}
                    </button>

                    <button className='bg-[#E2B682] py-2 px-8 rounded-lg text-white transition-colors duration-300 transform hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] hover:bg-white focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50 font-bold'
                    onClick={() => setPage((prev) => prev + 1)}
                    disabled={page === employee?.data?.meta.total}>
                        {empLoading ? 'Loading...' : 'Next'}
                    </button>
                </div>

                <div className="flex flex-col gap-y-6">
                                      
                    <div className='flex flex-wrap justify-around'>
                        <div className='shadow-lg shadow-black p-4 rounded-lg'>
                            <div className='flex justify-between'>
                                <i className='text-blue-600'><FontAwesomeIcon icon={faChartPie} /></i>

                                <p className="py-[0.15rem] px-2 rounded-2xl bg-green-600 text-white">30%</p>
                            </div>
                            <div className='font-semibold text-xl'>4.150</div>
                            <div>No of Employees</div>
                        </div>

                        <div className='shadow-lg shadow-black p-4 rounded-lg'>
                            <div className='flex justify-between'>
                                <i className='text-yellow-400'><FontAwesomeIcon icon={faNairaSign} /></i>

                                <p className="py-[0.15rem] px-2 rounded-2xl bg-red-600 text-white">30%</p>
                            </div>
                            <div className='font-semibold text-xl'>4.150</div>
                            <div>No of Employees</div>
                        </div>

                        <div className='shadow-lg shadow-black p-4 rounded-lg'>
                            <div className='flex justify-between'>
                                <i className='text-orange-600'><FontAwesomeIcon icon={faMap} /></i>

                                <p className="py-[0.15rem] px-2 rounded-2xl bg-amber-500 text-white">30%</p>
                            </div>
                            <div className='font-semibold text-xl'>4.150</div>
                            <div>No of Employees</div>
                        </div>

                        <div className='shadow-lg shadow-black p-4 rounded-lg'>
                            <div className='flex justify-between'>
                                <i className='text-green-600'><FontAwesomeIcon icon={faChartBar} /></i>

                                <p className="py-[0.15rem] px-2 rounded-2xl bg-blue-600 text-white">30%</p>
                            </div>
                            <div className='font-semibold text-xl'>4.150</div>
                            <div>No of Employees</div>
                        </div>
                    </div>

                    {/* <div className=''>
                        <Bar options={options} data={data} className="grow" />
                        
                    </div> */}
                </div>
            </div>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />

        {/* {showModal ? <Modal object={[activeObject]} /> : null} */}
        {individualDetails ? <CreateDetails setIndividualDetails={setIndividualDetails} id={employeeId}/> : null}
        {showReceipt ? <ModalReceipt object={[receiptObject]} /> : null}
        {employeeInfo ? <EmployeeDetail setEmployeeInfo={setEmployeeInfo} id={employeeId}/> : null}
        {showModal ? <FullDetails showModal={setShowModal} id={employeeId} data={activeObject}/> : null}
        {showAmount ? <CreateEmployee setShowAmount={setShowAmount}/> : null}
        

    </>
  )
}

export default EmployeeSide
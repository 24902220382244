import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEditEmployeeKinsMutation } from '../../reactWrapper/Redux/apiSlice';

const EditKins = ({editData, id, setShowKin}) => {

    const [inputKin, setInputKin] = useState(
        {next_of_kin_fullname: '', relationship: '',  kin_address: '',  phone: '', email: '', occupation: '',  office_address: ''}
    )

    const [ editEmployeeKins, {isLoading, isSuccess, error, data: medData } ] = useEditEmployeeKinsMutation();

    useEffect(() => {
        setInputKin({
          next_of_kin_fullname: editData?.next_of_kin_fullname,
          relationship: editData?.relationship,
          kin_address: editData?.kin_address,
          phone: editData?.phone ,
          occupation: editData?.occupation,
          office_address: editData?.office_address,
          email: editData?.email
        });
      }, [editData]);

      const handleChange = event => {
        setInputKin({
            ...inputKin,
            [event.target.name]: event.target.value
          });
      };

      const submit = async (e) => {
        e.preventDefault();
        try {
            let dataToSubmit = {...inputKin};
            
            await editEmployeeKins({id, dataToSubmit}).unwrap()
            setInputKin({next_of_kin_fullname: '', relationship: '',  kin_address: '',  phone: '', occupation: '',  office_address: ''})
        } catch (error) {
            toast.error(error.error)
        }       
    }

    useEffect(() => {
        if(isSuccess) {
            toast.success(medData.message)  
            setShowKin(false) 
            window.location.reload();  
        }
    }, [isSuccess])
  return (
    <>
        <div class="flex fixed z-50 left-[0] top-[0] justify-center h-full w-full items-center bg-[rgba(0,0,0,0.9)] antialiased">
            {/* {console.log("this is d object details", object[0].name)} */}
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 mx-auto rounded-lg border border-gray-300 shadow-xl rounded-tr-none overflow-y-auto my-2 max-h-[calc(100vh - 210px)]" style={{maxHeight: 'calc(100vh - 50px)'}}>
                <div
                class="flex flex-row justify-end px-6 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
                >                 
                    <svg
                        onClick={() => setShowKin(false)}
                        class="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>            

                <div className="flex flex-col px-6 py-5 bg-gray-50">
                <div className='text-center text-2xl font-medium mb-4'>
                    <p>Create Next of Kin Field</p>
                </div>
                <form onSubmit={submit}>
                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Kin Full Name</label>
                            <input type="name" name="next_of_kin_fullname" id="email" placeholder="eg Awolowo Obafemi"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.next_of_kin_fullname} onChange={handleChange}/>
                        </div>                  

                        <div className="block w-full bg-[#F4F0EB] rounded-md mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Relationship</label>
                            <input type="text" name="relationship" id="email" placeholder="Siblings"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none focus:border-none focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.relationship} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Next of Kin Address</label>
                            <input type="text" name="kin_address" id="email" placeholder="20 Awolowo Way"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.kin_address} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Phone Number</label>
                            <input type="number" name="phone" id="email" className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.phone} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Email</label>
                            <input type="email" name="email" id="email" placeholder="eg mymail@gmail.com"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.email} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Occupation</label>
                            <input type="text" name="occupation" id="email" placeholder="Banking"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.occupation} onChange={handleChange}/>
                        </div>

                        <div className="block w-full bg-[#F4F0EB] rounded-md  mt-2">
                            <label for="email" className="block px-4 pt-1 text-sm text-gray-600 ">Office Address</label>
                            <input type="text" name="office_address" id="email" placeholder="49 Army Awolowo"  className="block w-full px-4 pt-0 pb-1 text-gray-700 placeholder-gray-400 bg-[#F4F0EB] border-none  focus:border-none  focus:ring-none focus:outline-none rounded-b-md font-bold" 
                            value={inputKin.office_address} onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="mt-6">
                        <button className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#E2B682] rounded-md hover:bg-white hover:border-2 hover:border-solid hover:border-[#E2B682] hover:text-[#E2B682] focus:outline-none focus:bg-[#E2B682] focus:ring focus:ring-[#E2B682] focus:ring-opacity-50" disabled={isLoading}  onClick={submit}>
                            {isLoading ? 'Editing...' : 'Edit'}
                        </button>
                    </div>
                </form>

                </div>
            </div>
        </div>

        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default EditKins